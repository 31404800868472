import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import Cookies from "universal-cookie";
import Select from 'react-select'
import { isGcabMember, submitRegistration } from "./RegistrationUtility"
import {activitiesOptionsV2, checkinDates,employeeCheckinDates,  checkoutDates, approvedAirports, seatPreferences, activitiesOptions, travelMethods, sessionFormatOptions, sessionTopicsOptions, arrivalDates, departureDates,theaterOptions, activities, preferredRoomType, travelInformation, getApparelSizeOptions,specialAccomodations, dietaryRestrictions, getCountrySelectOptions, getStateSelectOptions, getTimezoneOptions, getTimezoneInfo, getJobTypes}  from "./CountryCodes"
import RegistrationInvitationModal from "./RegistrationInvitationModal"
import Creatable, { makeCreatableSelect } from 'react-select/creatable';
import DropzoneComponent from "../layout/forms/DropzoneComponent"
import DefaultTooltip from  "../layout/tooltips/DefaultTooltip"

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      checkError: false,
      emailTaken: false,
      dietaryRestrictions: [],
      additionalDates: [],
      saving: false,
      gcabMember: props.participant ? props.participant['registration_answers']['gcabmember'] == "True" : false,
      selectedActivities: [],
      features: props.features,
      participant: props.participant,
      protocolIsOpen: false,
      collectedCreditInfo: props.participant ? !!props.participant.vault_saved_at : false
    }
    this.setHeadshot = this.setHeadshot.bind(this);
    this.toggleProtocol = this.toggleProtocol.bind(this);
    this.collectedCreditInfoCallback = this.collectedCreditInfoCallback.bind(this);
    this.formikRef = React.createRef()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.page != this.state.page){
      this.generateTracking()
    }
  }

  componentDidMount() {
    this.generateTracking()
  }

  generateTracking() {
    const { participant } = this.props;
    const objectToSend = {
      gid: participant.gid,
      email: participant.email,
      data: {
        values: this.values,
        page: this.state.page,
        status: participant.status
      }
    }
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch('/track/registration_tracking', {
      method: 'POST',
      redirect: "manual",
      body: JSON.stringify(
        objectToSend
      ),
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token,
      }
    })
  }

  collectedCreditInfoCallback() {
    this.setState({
      collectedCreditInfo: true
    })
  }

  toggleProtocol() {
    const { protocolIsOpen } = this.state;
    this.setState({ protocolIsOpen: !protocolIsOpen });
  }

  renderTester() {
    const { participant } = this.props;
    if(participant.test_flag && (participant.email||"").indexOf("@jaguardesignstudio") != -1) {
      const pages = [
        {id: 1},
        {id: 2},
        {id: 3},
        {id: 4},
      ];

      const gcabTypes = [
        {id: "True"},
        {id: "False"}
      ];
      return (
        <div className="sg-reg-page-swapper">
          {pages.map(x=>(
            <div onClick={
              ()=>{
                this.setState({
                page: x.id
              })
              window.location.href = "#page"+x.id;
            }}
            className="sg-reg-page-swapper-item">
              Go To Page {x.id}
            </div>
          ))}

          {gcabTypes.map(x=>(
            <div onClick={()=>this.setState({
              gcabMember: x.id == "True"
            })}className="sg-reg-page-swapper-item">
              Change to GCAB {x.id}
            </div>
          ))}
        </div>
      )
    }
  }


  pageHiddenClass(renderedPage) {
    const { page } = this.state;
    if(renderedPage == page) {
      return "visible";
    }
    return "hidden";
  }

  isPageOneValid(formikProps) {
    //TODO
    const { fields } = this.props;
    const { values } = formikProps;
    let fieldList = [
      'email',
      'name_first',
      'name_last',
      'job_title',
      'company',
      'work_phone',
      'ecname',
      'ecnumber',
      'work_address_street_1',
      'work_address_country',
      'work_address_state',
      'work_address_city',
      'work_address_postcode',
    ];
    if(values.user.selectedactivity=='Golf at Mission Hills Country Club'){
      fieldList = fieldList.concat(['requiregolfclubs'])
      if(values.user.requiregolfclubs=="True"){
        fieldList = fieldList.concat(["golfclubtypes"])
      }
    }
    if(values.user.specialaccomodations == "Other") {
      fieldList = fieldList.concat(['specialaccomodationsother'])
    }
    if(values.user.dietarypreference == "Other") {
      fieldList = fieldList.concat(['dietarypreferenceother'])
    }

    const validFields = fieldList.filter(field => values["user"][field] && values["user"][field].length > 0);
    if (!(validFields.length == fieldList.length)) {
      return false;
    }

    return true;
  }

  isPageThreeValid(formikProps) {
    const { fields, participant } = this.props;
    const { values } = formikProps;
    let trueFieldList = [
      'waiverandrelease'
    ];

    if(!this.isAttendeeEmployee()){
      trueFieldList = trueFieldList.concat([
        'packageconsent'
      ])
    }

    if(this.requiresDataProtectionAcknowledgement(formikProps)){
      trueFieldList = trueFieldList.concat([
        'privacystatement'
      ])
    }

    // 'offersandpromotions'
    let fieldList=[
      'webexoptin',
    ]

    const validTrueFields = trueFieldList.filter(field => values["user"][field] && values["user"][field].length > 0 && values['user'][field]=="True");
    if (!(validTrueFields.length == trueFieldList.length)) {
      return false;
    }

    const validFields = fieldList.filter(field => values["user"][field] && values["user"][field].length > 0);
    if (!(validFields.length == fieldList.length)) {
      return false;
    }
    //TODO
    return true;
  }

  isPageTwoValid(formikProps) {
    const { features, fields, participant } = this.props;
    const { values } = formikProps;
    const { collectedCreditInfo } = this.state;
    let fieldList = [
      'requiretransportfromairport',
      'requiretransportfromhotel',
      'ciscosecuresreservation'
    ];

    if(this.isGcabMember() && values.user.ciscosecuresreservation == "False"){
      fieldList = fieldList.concat(['gcaphotelarrangesdoesnotpay'])
    }

    if(values.user.ciscosecuresreservation == "True" || (this.isGcabMember() && values.user.gcaphotelarrangesdoesnotpay == "True")) {
      fieldList=fieldList.concat([
        'hotelcheckindate',
        'hotelcheckoutdate',
      ])

      if(!(this.isGcabMember() && values.user.ciscosecuresreservation == "True")){
        if(!collectedCreditInfo) {
          return false
        }
      }

    }

    if(features.shut_down_profile && values.user.ciscosecuresreservation=="Not at this time") {
      return false;
    }

    if (values.user.work_address_country !== "United States") {
      fieldList = fieldList.concat(['embassyrequireinvitation'])
    }
    if(values.user.embassyrequireinvitation =="True") {
      fieldList = fieldList.concat([
        'passportfirstname',
        'passportlastname',
        'embassyname',
        'embassyaddress',
        'embassyfaxnumber'
      ])
    }

    const validFields = fieldList.filter(field => values["user"][field] && values["user"][field].length > 0);
    if (!(validFields.length == fieldList.length)) {
      return false;
    }
    //TODO
    return true;
  }

  renderBioInformation(formikProps) {
    return (
      <>
        <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
        </div>
        <div className="col-xs-12 sg-registration-h3-copy  sg-registration-section-header-spacing">
          Name/Photo/Bio Opt in:
        </div>
        <div className="col-xs-12">
          As a Cisco CIO Exchange attendee, you have the option for your biography and photograh to be listed on the event website that will be viewable by all attendees.
          <br/>
          <br/>
          By providing this information, you consent to Cisco sharing your information for event purposes only.
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('namephotobiooptin',formikProps)}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>I agree.</label>
          <br/>
        </div>
        <div className="col-xs-12">
          <label className=""> Bio </label>
          {this.renderField('bio',formikProps, false, {not_required: true, component:"textarea"})}
        </div>
        <div className="col-xs-12">
          <label className=""> Headshot </label>
          <br/>
          <div className="sg-photo-dropzone">
            <DropzoneComponent name="drawing" setFiles={this.setHeadshot} showPreviews={true} editCrop={true}/>
          </div>
        </div>
      </>
    )
  }

  setHeadshot(name, file, preview, tagList) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    var form_data = new FormData();
    form_data.append(`photo`, file);
    fetch('/registration/update_photo', {
      method: 'POST',
      redirect: "manual",
      body: form_data,
      headers: {
        "X-CSRF-Token": token,
      }
    })
    this.setState({
      headshotImage: file,
      headshotPreviewImage: preview
    });
  }

  renderPageOne(formikProps) {
    const { fields, participant } = this.props;
    const { values } = formikProps;
    const { user } = values;
    const { pageOneError, emailTaken, checkError, fullPageError } = this.state;
    return (
      <>
      <div className={`registration-page-one ${this.pageHiddenClass(1)}`}>
        <div className="row">
          <div className="col-xs-12 sg-registration-h3-copy  sg-registration-section-header-spacing">
            Your Information
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="">First Name </label>
            {this.renderField('name_first',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className=""> Last Name </label>
            {this.renderField('name_last',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className=""> Preferred First Name on Badge (Optional) </label>
            {this.renderField('preferredname',formikProps, false, {not_required: true})}
          </div>
          <div className="col-xs-12">
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className=""> Email Address </label>
            {this.renderField('email',formikProps,false,{disabled: true})}
            {emailTaken ? (
              <div className="registration-field-error">
                 This email has already been taken.
              </div>
            ): ""}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className=""> Company </label>
            {this.renderField('company',formikProps)}
          </div>
        </div>
        <div className="row">
          <div className="hidden-xs col-sm-6">
            <label className=""> Job Title </label>
          </div>
          <div className="hidden-xs col-sm-6">
            <label className=""> Mobile Telephone<br/><span style={{fontSize: "12px"}}>(Including area and country code)</span></label>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <label className="hidden-sm hidden-md hidden-lg hidden-xl"> Job Title </label>
            {this.renderField('job_title',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="hidden-sm hidden-md hidden-lg hidden-xl"> Mobile Telephone (Including area and country code)</label>
            {this.renderField('work_phone',formikProps)}
          </div>
        </div>
        <div className="row">

          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
          <div className="col-xs-12 sg-registration-h3-copy  sg-registration-section-header-spacing">
            Address
          </div>
          {this.renderAddressForm(formikProps)}
          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
          <div className="col-xs-12 sg-registration-h3-copy  sg-registration-section-header-spacing">
            Emergency Contact
          </div>
        </div>
        <div className="row">
          <div className="hidden-xs col-sm-6">
            <label className=""> Emergency Contact Name </label>
          </div>
          <div className="hidden-xs col-sm-6">
            <label className=""> Emergency Contact Number<br/><span style={{fontSize: "12px"}}>(Including area and country code)</span></label>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <label className="hidden-sm hidden-md hidden-lg hidden-xl"> Emergency Contact Name </label>
            {this.renderField('ecname',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="hidden-sm hidden-md hidden-lg hidden-xl"> Emergency Contact Number{" "}<span style={{fontSize: "12px"}}>(Including area and country code)</span></label>
            {this.renderField('ecnumber',formikProps)}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
        </div>
        {this.renderAccomodationsAndActivities(formikProps)}
        <div className="row">
          <div className="col-xs-12 text-center">
            <div className="registration-field-error text-center">
              {checkError && !this.isPageOneValid(formikProps) ? pageOneError : ""}
              {fullPageError}
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }

  renderAccomodations(formikProps) {
    const { fields }= this.props;
      const { values } = formikProps;
    return (
      <>
        <div className="col-xs-12 col-sm-6">
          <label className=""> Do you require additional assistance? </label>
          {this.renderSelectField('specialaccomodations',formikProps, specialAccomodations(fields),false,  {placeholder: "None", not_required: true})}
        </div>
        {values.user.specialaccomodations == "Other" ? (
          <div className="col-xs-12 col-sm-6">
            <label className=""> Please specify: </label>
            {this.renderField('specialaccomodationsother',formikProps, false, {not_required: true})}
          </div>
        ): ""}
        <div className="col-xs-12"/>
        <div className="col-xs-12 col-sm-6">
          <label className=""> Do you have any dietary preferences? </label>
          {this.renderSelectField('dietarypreference',formikProps, dietaryRestrictions(fields),false,  {placeholder: "None", not_required: true})}
        </div>
        {values.user.dietarypreference == "Other" ? (
          <div className="col-xs-12 col-sm-6">
            <label className=""> Please specify: </label>
            {this.renderField('dietarypreferenceother',formikProps, false, {not_required: true})}
          </div>
        ): ""}
      </>
    )
  }

  renderAccomodationsAndActivities(formikProps) {
    const { features, fields } = this.props;
    const { values } = formikProps;
    if(features.activity_info_feature) {
      return (
        <>
          <div className="row">
            <div className="col-xs-12 sg-registration-h3-copy  sg-registration-section-header-spacing">
              Accomodations
            </div>
            {this.renderAccomodations(formikProps)}
            <div className="col-xs-12"/>
            <div className="col-xs-12 sg-registration-h3-copy  sg-registration-section-header-spacing">
              Networking Experiences (Optional)
            </div>
            <div className="col-xs-12">
              <label className="" style={{fontSize: "15px"}}>
                Select from two exceptional experiences, where you’ll have the opportunity to create fun and meaningful memories alongside your peers and Cisco leaders. Note: these activities are planned for the morning of Tuesday, April 25, 2023.
                <br/>
                <br/>
                {features.shut_down_profile ? (
                  <>
                    Please <a href="mailto:cxoevents@cisco.com">contact us</a> if you require modifications.
                  </>
                ) : "Your selection is not final and can be updated later. Closer to the event, we will confirm your selection."}
               
               </label>
              <br />
              <br/>
            </div>
            </div>
          <div className="row" style={{display: "flex", flexWrap: "wrap"}}>
            {activitiesOptionsV2(fields).map(x=> (
              <div className="col-xs-6">
                {this.renderCheckbox('selectedactivity',formikProps, false, {
                  forceValue: x.value,
                  forceToggleable: true,
                })}
                <div className="" style={{display:"inline-block", marginLeft:"10px"}}>{x.title}</div>
                <br/>
                <div class="sg-registration-small-copy">
                  {x.desc}
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            {this.renderGolfQuestions(formikProps)}
          </div>

        </>
      )
    }
    return (
      <>
        <div className="row">
          <div className="col-xs-12 sg-registration-h3-copy  sg-registration-section-header-spacing">
            Accommodations and Interests
          </div>
          {this.renderAccomodations(formikProps)}
          <div className="col-xs-12"/>
          <div className="col-xs-12">
            <label className="" style={{fontSize: "15px"}}> Which of the following activities are of interest to you? (Select up to 2 options) </label>
            <br />
            <div className="sg-registration-small-copy">
              Ahead of the official program beginning Tuesday, April 25 5pm PT, we welcome you to join us for networking and fun. We’d love your input to help select our final activity options. Your selections are not final and can be updated later. Closer to the event, we will confirm your selection.
            </div>
            <br/>
          </div>
      </div>
      <div className="row" style={{display: "flex", flexWrap: "wrap"}}>
        {this.renderTopics("activitiesofinterest", formikProps, activitiesOptions())}
      </div>
    </>
    )
  }

  renderGolfQuestions(formikProps) {
    const { checkError } = this.state;
    const { values } = formikProps;
    const { user } = values;
    const golfClubTypes = [
      "Right-Men's",
      "Left-Men's",
      "Right-Women's",
      "Left-Women's",
    ]
    if(user.selectedactivity=='Golf at Mission Hills Country Club'){
      return (
        <>
          <div className="col-xs-12">
            <label className="" style={{fontSize: "15px"}}> Do you require rental clubs? </label>
            <br />
            <br/>
            {this.renderCheckbox('requiregolfclubs',formikProps, false, {
              forceValue:"True",
            })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
            {this.renderCheckbox('requiregolfclubs',formikProps, false, {
              forceValue:"False",
            })}<label className="checkbox-label"> Rental clubs not required </label>
          </div>
          <div className="col-xs-12 registration-field-error">
            {checkError && !this.isPageOneValid(formikProps) && values.user.requiregolfclubs == null ? "Please select an option to continue" : ""}
          </div>
          {user.requiregolfclubs == "True" ? (
            <div className="col-xs-12">
              <label className="" style={{fontSize: "15px"}}> Select the type of golf clubs required: </label>
              <br />
              <br/>
              {golfClubTypes.map(x=>(
                <>
                {this.renderCheckbox('golfclubtypes',formikProps, false, {
                  forceValue:x,
                })}<label className="checkbox-label" style={{marginRight:"20px"}}> {x} </label>
                </>
              ))}
              <div className="col-xs-12 registration-field-error">
                {checkError && !this.isPageOneValid(formikProps) && values.user.golfclubtypes == null ? "Please select an option to continue" : ""}
              </div>
            </div>
          ):""}
        </>
      )
    }

  }

  renderPageThree(formikProps) {
    const { participant, fields } = this.props;
    const { checkError, fullPageError } = this.state;
    const { values } = formikProps;

    //{this.renderFlightAccomodations(formikProps)}
    // {this.renderPromotionsAndSponsors(formikProps)}

    return (
      <div className={`registration-page-one ${this.pageHiddenClass(3)}`}>
        <div className="row">
          <div className="col-xs-12 sg-registration-h3-copy  sg-registration-section-header-spacing">
            Consent and Opt In
            <br/>
            <br/>
          </div>
        </div>
        {this.renderWaiver(formikProps)}
        {this.renderPackageConsent(formikProps)}
        {this.renderDataProtection(formikProps)}
        <div className="row">
          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
          <div className="col-xs-12 sg-registration-h3-copy  sg-registration-section-header-spacing">
            Webex Opt In:
          </div>
          <div className="col-xs-12 sg-registration-copy">
            <br/>
            Would you like to be added to the Cisco CIO Exchange Webex space? The Webex space will only be used for program information, announcements, and peer to peer engagement pre-event and while at Cisco CIO Exchange.
            <br/>
            <br/>
            By selecting "Yes," your name and email will be visible to other attendees and Cisco staff within the Webex space. If your company does not allow Webex use with your work email, please message <a className="sg-registration-link" href="mailto:cxoevents@cisco.com">cxoevents@cisco.com</a> to provide an alternate email address. Cisco will not sell or share your information with any 3rd parties.
            <br/>
            <br/>
            Make sure to download the free Webex app and create an account. For more information see the <a className="sg-registration-link" className="sg-registration-link" href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html" target="_blank">Cisco Privacy Statement</a>.
          </div>
          <div className="col-xs-12">
            <br/>
            {this.renderCheckbox('webexoptin',formikProps, false, {
              forceValue:"True",
            })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
            {this.renderCheckbox('webexoptin',formikProps, false, {
              forceValue:"False",
            })}<label className="checkbox-label"> No </label>
          </div>
          <div className="col-xs-12 registration-field-error">
            {checkError && !this.isPageThreeValid(formikProps) && values.user.webexoptin == null ? "This field is required to proceed." : ""}
          </div>

          <div className="col-xs-12 sg-registration-h3-copy  sg-registration-section-header-spacing">
            Health & Safety
          </div>
          <div className="col-xs-12 sg-registration-copy">
            <br/>
            The health and safety of event attendees is our top priority. If you are feeling unwell, please refrain from attending the event. At this time, Cisco does not have any health related requirements in place for event attendance. Should this change, we will follow up with updated guidance.
            <br/>
            <br/>
          </div>
        </div>


        <div className="row">
          <div className="col-xs-12 text-center">
            <div className="registration-field-error text-center">
              {fullPageError}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderPageTwo(formikProps) {
    const { participant, fields } = this.props;
    const { checkError, fullPageError } = this.state;
    const { values } = formikProps;

    return (
      <div className={`registration-page-one ${this.pageHiddenClass(2)}`}>
        <div className="row">
          {this.renderRequiredFlightAccomodations(formikProps)}
          {this.renderVisaInformation(formikProps)}
          <div className="col-xs-12 text-center">
            <div className="registration-field-error text-center">
              {fullPageError}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderPageFour(formikProps) {
    const { participant } = this.props;
    return (
      <div className={`registration-page-one ${this.pageHiddenClass(4)}`}>
        <div className="row">
          <div className="col-xs-12 sg-registration-copy">
            We are delighted that you will join us.
            <br/>
            <br/>
            Visit the <a className="sg-registration-link" href="/pages/landing_page">Cisco CIO Exchange website</a> to view the agenda and learn more about the content and planned activities.
            <br/>
            <br/>
            In the meantime, please contact us with questions: <a className="sg-registration-link" href="mailto:cxoevents@cisco.com">cxoevents@cisco.com</a>.
            <br/>
            <br/>
            <a className="sg-registration-link" target="_blank" href="mailto:cxoevents@cisco.com">
              <button type="button" style={{paddingTop: "10px", marginBottom: "30px"}} className="att-proam-button att-proam-submit-button">
                Contact
              </button>
            </a>
          </div>
        </div>
      </div>
    )
  }

  renderTopics(name, formikProps, options) {
    const { checkError } = this.state;
    const { values } = formikProps;
    const { user } = values;
    const optionsRendered = options.map(x=>
      <div className="col-xs-12 col-sm-6">
        {this.renderChooseThreeCheckbox(name,x.id, x.label, formikProps)}
        {
          x.desc === undefined ?
          <></>
          :
          <div className="topic-description">{x.desc}</div>
        }
      </div>
    )
    return (
      <>
        {optionsRendered}
        {(user[name]||[]).indexOf("Other") != -1 ? (
          <div className="col-xs-12">
            {this.renderField(`${name}other`,formikProps, false, {
              component: "textarea",
              placeholder: "Please Specify"
            })}
          </div>
        ):""}
      </>
    )
  }

  renderAdditiveCheckbox(name,value, label, formikProps) {
    const { values } = formikProps;
    const { user } = values;
    let checked = false;
    const renderOptions = {}
    let nameValues = []
    try {
      nameValues = user[name]
      checked = nameValues.indexOf(value) != -1
    }catch(e){

    }
    return(
      <>
      <br/>
      <img
        src={ checked  ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
        style={{cursor:"pointer", marginBottom: "7px"}}
        onClick={(e) => {
          const { user } = formikProps.values;
          if(checked) {
            user[name] = (nameValues||[]).filter(x=>x != value)
          }else {
            user[name] = (nameValues||[]).concat([value])
          }
          formikProps.setFieldValue('user', user)
        }}
      />
        <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>{label}</label>
      </>
    )
  }

  renderChooseThreeCheckbox(name,id,label, formikProps) {
    const { values } = formikProps;
    const { user } = values;
    let checked = false;
    const renderOptions = {}
    try {
      checked = user[name].indexOf(id) != -1
    }catch(e){

    }
    return(
      <>
      <img
        src={ checked  ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
        style={{cursor:"pointer", marginBottom: "7px"}}
        onClick={(e) => {
          const { user } = formikProps.values;
          if(!checked && id=="undecided"){
            user[name]=['undecided']
          }else if(checked) {
            user[name] = (user[name]||[]).filter(x=>x != id);
          }else {
            user[name]=(user[name]||[]).filter(x=>x!='undecided')
            if((user[name]||[]).length < 2) {
              user[name] = (user[name]||[]).concat([id])
            }
          }
          formikProps.setFieldValue('user', user)
        }}
      />
        <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>{label}</label>
      </>
    )
  }

  renderGuestFields(formikProps) {
    const { values } = formikProps;
    if(values.user.bringingguest == "True") {
      return (
        <>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Guest First Name </label>
            {this.renderField('guestnamefirst',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Guest Last Name</label>
            {this.renderField('guestnamelast',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Guest Email</label>
            {this.renderField('guestemail',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Guest Apparel Size </label>
            {this.renderSelectField('guestapparelsize',formikProps, getApparelSizeOptions())}
          </div>
        </>
      )
    }
  }

  renderAddressForm(formikProps) {
    return(
      <>
      <div className="col-xs-12">
        <label className=""> Address 1 </label>
        {this.renderField('work_address_street_1',formikProps)}
      </div>
      <div className="col-xs-12">
        <label className=""> Address 2 (Optional) </label>
        {this.renderField('work_address_street_2',formikProps, false, {not_required: true})}
      </div>
      <div className="col-xs-12 col-sm-6">
        <label className=""> Country </label>
        {this.renderSelectField('work_address_country',formikProps, getCountrySelectOptions())}
      </div>
      <div className="col-xs-12 col-sm-6">
        <label className=""> State/Province </label>
        {this.renderField('work_address_state',formikProps)}
      </div>
      <div className="col-xs-12 col-sm-6">
        <label className=""> City </label>
        {this.renderField('work_address_city',formikProps)}
      </div>

      <div className="col-xs-12 col-sm-6">
        <label className=""> Postal Code </label>
        {this.renderField('work_address_postcode',formikProps)}
      </div>
      </>
    )
  }


  renderField(name,formikProps, customValidation = false, properties = {}) {
    const { checkError } = this.state;
    let erroring = checkError && (formikProps.values.user[name] == undefined || formikProps.values.user[name].length <= 0 || customValidation);
    if(properties['not_required']) {
      erroring = false || customValidation;
    }
    return (
      <Field
        {...(properties['inputProps']||{})}
        disabled={properties['disabled']}
        onKeyUp={() => properties['onKeyUp'](formikProps)}
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`}
        type={properties['type']}
        name={`user[${name}]`}
        placeholder={properties['placeholder']||""}
      />
    )
  }

  renderSelectField(name, formikProps, options, customValidation = false, renderOptions = {}) {
    const { checkError } = this.state;
    const erroring = !renderOptions['not_required'] && checkError && ((formikProps.values.user[name] == null || formikProps.values.user[name].length <= 0) || customValidation);
    let value = null;
    try {
       value = options.filter(option => option.value === formikProps.values['user'][name])[0]
    }catch (e) {

    }

    return (
      <Select
        className={erroring ? "registration-select-erroring" : ""}
        options={options}
        classNamePrefix='registration-select'
        defaultValue={{value:"Unassigned",label:renderOptions['placeholder']||""}}
        value={options ? value : ""}
        onChange={(option) => {
          const { user } = formikProps.values;
          user[name] = option.value
          formikProps.setFieldValue('user', user)
        }}
        filterOption={this.customFilter}
      />
    )
  }

  renderCheckbox(name, formikProps, customValidation=false, renderOptions={}) {
    let value = false;
    try {
      value = formikProps.values['user'][name] == "True";
    }catch(e){

    }
    const forceToggleable = renderOptions['forceToggleable']
    if(renderOptions['forceValue'] != undefined ) {
      value = formikProps.values['user'][name] == renderOptions['forceValue']
    }
    let images = {
      unchecked: "/images/checkbox-unchecked-1.svg",
      checked: "/images/checkbox-checked-1.svg"
    }
    if(renderOptions.checkedImage) {
      images.checked = renderOptions.checkedImage
    }
    if(renderOptions.uncheckedImage) {
      images.unchecked = renderOptions.uncheckedImage
    }
    return(
      <img
        src={ value  ? images.checked : images.unchecked }
        style={{cursor:"pointer", marginBottom: "7px", height: "21px"}}
        onClick={(e) => {
          const { user } = formikProps.values;
          if(renderOptions['forceValue'] != undefined && !forceToggleable) {
            user[name] = renderOptions['forceValue']
          } else if(renderOptions['forceValue'] != undefined && forceToggleable) {
            user[name] = user[name] == renderOptions['forceValue'] ? "" : renderOptions['forceValue']
          } else {
            user[name] = value ? "False" : "True"
          }

          formikProps.setFieldValue('user', user)
        }}
      />
    )
  }

  customFilter(option, searchText) {
    const ones =  option.label.toLowerCase().split(" ").filter(x=> x.startsWith(searchText.toLowerCase()));
    return ones.length > 0;
  }

  isAttendeeEmployee() {
    const { participant } = this.props;
    const participantRole = participant['registration_answers']['attendeetype']
    return participantRole && participantRole != 'Customer' && participantRole != 'Unassigned'
  }
  renderPackageConsent(formikProps) {
    const { checkError } = this.state;
    const { values } = formikProps;
    // <div className="col-xs-12 sg-registration-h3-copy  sg-registration-section-header-spacing">
    // Waiver and Release
    // </div>
    if(this.isAttendeeEmployee()){
      return;
    }
    return (
      <div className="row">
        <div className="col-xs-12 att-proam-registration-copy">
          Cisco’s Global Compliance Team asks that you confirm that you are permitted by your company to accept this package (outlined below) in accordance with your company’s internal expectations and policies. Cisco will be paying for your meals, ground transportation to/from Palm Springs International Airport, and entertainment during Cisco CIO Exchange, in the United States.
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('packageconsent',formikProps)}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>Yes, I confirm.</label>
          <div className="registration-field-error">
            {checkError && !this.isPageThreeValid(formikProps) && values.user.packageconsent != "True" ? "Please click to agree." : ""}
          </div>
          <br/>
        </div>
      </div>
    )
  }

  renderWaiver(formikProps) {
    const { checkError } = this.state;
    const { values } = formikProps;
    // <div className="col-xs-12 sg-registration-h3-copy  sg-registration-section-header-spacing">
    // Waiver and Release
    // </div>
    return (
      <div className="row">
        <div className="col-xs-12 att-proam-registration-copy">
          Do you agree to the <a className="sg-registration-link" target="_blank" href="/docs/CIOX23_Attendee-Release-and-Code-of-Conduct_fnl_2.pdf">Waiver and Release</a>?
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('waiverandrelease',formikProps)}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>Yes, I agree.</label>
          <div className="registration-field-error">
            {checkError && !this.isPageThreeValid(formikProps) && values.user.waiverandrelease != "True" ? "Please click to agree." : ""}
          </div>
          <br/>
        </div>
      </div>
    )
  }

  renderPromotionsAndSponsors(formikProps) {
    const { checkError } = this.state;
    const { values } = formikProps;

    // <div className="col-xs-12 att-proam-registration-copy">
    //   Do you consent to the use of your registration information by event sponsors to contact you with offers, promotions, and news about their products and services?*
    // </div>
    // <div className="col-xs-12">
    //   <br/>
    //   {this.renderCheckbox('eventsponsorcontactagreement',formikProps, false, {
    //     forceValue:"True",
    //   })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
    //   {this.renderCheckbox('eventsponsorcontactagreement',formikProps, false, {
    //     forceValue:"False",
    //   })}<label className="checkbox-label"> No </label>
    //   <div className="registration-field-error">
    //     {checkError && !this.isPageTwoValid(formikProps) && values.user.eventsponsorcontactagreement == null ? "Please choose and option to continue." : ""}
    //   </div>
    //   <br/>
    // </div>

    return (
      <div className="row">
        <div className="col-xs-12 att-proam-registration-copy">
          Cisco would like to use your information to provide you with the latest offers, promotions, and news regarding Cisco products and services. You can unsubscribe at any time.
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('offersandpromotions',formikProps, false, {
            forceValue:"True",
          })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
          {this.renderCheckbox('offersandpromotions',formikProps, false, {
            forceValue:"False",
          })}<label className="checkbox-label"> No </label>
          <div className="registration-field-error">
            {checkError && !this.isPageThreeValid(formikProps) && values.user.offersandpromotions == null ? "This field is required to proceed." : ""}
          </div>
          <br/>
        </div>
      </div>
    )
  }

  requiresDataProtectionAcknowledgement(formikProps) {
    const { values } = formikProps;
    const { user } = values;
    const { work_address_country } = user;
    if(work_address_country == "China" || work_address_country == "South Korea") {
      return true;
    }
    return false;
  }

  renderDataProtection(formikProps) {
    const { values } = formikProps;
    const { checkError } = this.state;
    if(this.requiresDataProtectionAcknowledgement(formikProps)){
      return (
        <div className="row">
          <div className="col-xs-12">
            <div className="sg-registration-copy">
            Do you agree that Cisco may process your personal information in accordance with its
            <a className="sg-registration-link" href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html#privacy-manager" target="_blank"> Online Privacy Statement</a>?
            <br/>
            <br/>
            </div>
            {this.renderCheckbox('privacystatement',formikProps)}
            <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>
              Yes, I agree.
            </label>
            <div className="registration-field-error">
              {checkError && !this.isPageThreeValid(formikProps) && values.user.privacystatement != "True" ? "Please click to agree." : ""}
            </div>
          </div>
          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
        </div>
      )
    }
  }

  renderCovidProtocol(formikProps) {
    const { checkError, protocolIsOpen } = this.state;
    const { values } = formikProps;
    return (
      <div className="row">
        <div className="col-xs-12 att-proam-registration-copy">
          Do you agree to Cisco's <a className="sg-registration-link" target="_blank" onClick={this.toggleProtocol} style={{cursor: "pointer"}}>COVID-19 Attendee Protocol</a>?
        </div>
        {
          protocolIsOpen ?
          <div className="col-xs-12 att-proam-registration-copy" style={{paddingTop: "15px"}}>
            By registering to attend this event in-person, I am agreeing to abide by the Health and Safety Policies required by Cisco and/or the event venue, including where permitted by law answering screening questions regarding COVID-19 symptoms and exposure, verifying vaccination status, and testing negative for COVID-19. I understand I may be denied access to, or removed from the event, if I do not comply.
          </div>
          :
          <></>
        }
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('covidattendeeprotocol',formikProps)}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>Yes, I agree.</label>
          <div className="registration-field-error">
            {checkError && !this.isPageThreeValid(formikProps) && values.user.covidattendeeprotocol != "True" ? "Please click to agree." : ""}
          </div>
          <br/>
        </div>
      </div>
    )
  }



  renderPageButtons(formikProps) {
    const { page } = this.state;
    const isProfile = this.isProfileMode();
    const pages = isProfile ? [1,2,3] : [1,2,3,4] //Hide submitted page on profile
    return (
      <>
      {this.renderPageButtonTooltips()}
      <div className="att-proam-registration-header-page-signifier-container">
        {pages.map((x,i,t)=> (
          <>
            <div
              data-for={`sg-page-buttons-header-page-${x}`}
              data-tip
              className={`att-proam-registration-header-page-signifier ${page == x ? "active" : ""}`}
            >
              <img
                src={`/images/pages/page-${x}${page == x ? '-active' : ""}.svg`}
                onClick={
                  isProfile ? (
                    ()=>this.nextPage(formikProps, x)
                  ):()=>{}
                }
                style={
                  isProfile ? {cursor:"pointer"} : {}
                }
              />
            </div>
            {i < t.length-1 ? (
              <>
                <div class="sg-page-buttons-divider"/>
                <div class={`sg-page-buttons-divider-ball sg-page-buttons-divider-ball-${i}`}/>
                <div class="sg-page-buttons-divider"/>
              </>
            ):""}
          </>
        ))}
      </div>
      </>
    )
  }

  renderPageButtonTooltips() {
    return (
      [1,2,3,4].map(x=> (
        <>
          <DefaultTooltip
            tooltipId={`sg-page-buttons-header-page-${x}`}
          >
            {x==1 ? "Review and update your personal information and selected options":""}
            {x==2 ? "Review and update your hotel accommodations and transportation needs":""}
            {x==3 ? "Review and agree to the event protocols and consents":""}
            {x==4 ? "Registration complete":""}
          </DefaultTooltip>
        </>
      ))
    );
  }

  getPages(){
    return [
      {id: 1, header:(<>Welcome! We are excited<br/>that you will join us.</>), subheader:(
        <>
          Please complete the fields below to confirm your registration.
          <br/>
          If you have questions regarding the registration process, please contact <a className="sg-registration-link" href="mailto:cxoevents@cisco.com" target="_blank"> cxoevents@cisco.com</a>.
          <br/>
          <br/>
          <div className="sg-registration-small-copy">
            All fields are mandatory unless marked as optional.
          </div>
        </>
      ),placement:"top"},
      {id: 2, header:"Now, provide your travel details.", placement:"bottom",
        subheader: (
          <>
            If you have questions regarding the registration process, please contact <a className="sg-registration-link" href="mailto:cxoevents@cisco.com" target="_blank"> cxoevents@cisco.com</a>.
            <br/>
            <br/>
            <div className="sg-registration-small-copy">
              All fields are mandatory unless marked as optional.
            </div>
          </>
        )
      },
      {id: 3, header:"Next, please agree to the consents below.", placement:"bottom",
        subheader: (
          <>
            If you have questions regarding the registration process, please contact <a className="sg-registration-link" href="mailto:cxoevents@cisco.com" target="_blank"> cxoevents@cisco.com</a>.
            <br/>
            <br/>
            <div className="sg-registration-small-copy">
              All fields are mandatory unless marked as optional.
            </div>
          </>
        )
      },
      {id: 4, header:"Thank you for registering for Cisco CIO Exchange.", placement:"bottom"},
    ]
  }

  renderPageHeaderCopy(page) {
    return (
      <div className="sg-registration-subheader-copy">
        <div className="sg-registration-h2-copy">
          {page.header}
        </div>
        {page.subheader ? (
          <div className="sg-registration-copy" style={{padding:"20px 0"}}>
            {page.subheader}
          </div>
        ):""}
      </div>
    )
  }

  renderHeader(formikProps) {
    const { page } = this.state;
    const currentPage = this.getPages().find(x=>x.id == page);
    return (
      <>
      <div className="sg-registration-subheader">
        <div className="container registration-full-container">
          {currentPage.placement == "top" ? this.renderPageHeaderCopy(currentPage) : ""}
          {this.renderPageButtons(formikProps)}
          {currentPage.placement == "bottom" ? this.renderPageHeaderCopy(currentPage) : ""}
        </div>
      </div>
      </>
    )
  }

  renderSuccessfullySavedMessage() {
    const { savedSuccessfully } = this.state;
    if(savedSuccessfully) {
      return (
        <div className="col-xs-12">
          Your changes have been saved.
          <br/>
          <br/>
        </div>
      )
    }
  }

  renderButtons(formikProps) {
    const { page, loading } = this.state;
    if(page < 4) {
      return (
        <div className="registration-page-one visible">
          <div className="row">
            <div className="col-xs-12">
              <br/>
            </div>
            {this.renderSuccessfullySavedMessage()}
            <div  className="col-xs-12">
              {this.isProfileMode() ? (
                <button onClick={()=>this.saveChanges(formikProps)} className="att-registration-profile-button att-proam-button att-proam-submit-button sg-button-margin">
                  Save Changes
                </button>
              ):""}
              {!this.isProfileMode() || page != 3 ? (
                <>
                  {
                    page == 3 ?
                    <>
                      {
                        loading ?
                        <button className="att-registration-profile-button att-proam-button att-proam-submit-button sg-button-margin">
                          Register
                        </button>
                        :
                        <button onClick={()=>this.nextPage(formikProps)} className="att-registration-profile-button att-proam-button att-proam-submit-button sg-button-margin">
                          Register
                        </button>
                      }
                    </>
                    :
                    <button onClick={()=>this.nextPage(formikProps)} className="att-registration-profile-button att-proam-button att-proam-submit-button sg-button-margin">
                      Continue
                    </button>
                  }
                </>
              ):""}
              {page > 1 ? (
                <button onClick={()=>this.prevPage(formikProps)} className="att-registration-profile-button att-proam-button att-proam-previous-button">
                  Previous Step
                </button>
              ):""}
              {this.isProfileMode() && page != 4 ? (
                <a style={{textDecoration:"none !important"}} className="att-registration-profile-button pull-right att-proam-button att-proam-previous-button" href="/index">
                    Back to Event Page
                </a>
              ):""}
            </div>


            <div className="col-xs-12">
              <br/>
              <br/>
            </div>
          </div>
        </div>
      )
    }
  }

  scrollTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  nextPage(formikProps = {}, pageOverride = null){
    const { page }  = this.state;
    if(this.isCurrentPageValid(formikProps)){
      if(pageOverride == null && page==3) {
        formikProps.submitForm();
        return;
      }else {
        this.setState({
          page: pageOverride != null ? pageOverride : page + 1,
          fullPageError: null,
          checkError: false
        })
        this.scrollTop();
      }
    } else {
      this.setState({
        fullPageError: "Please ensure all fields are complete.",
        checkError: true
      })
    }
  }

  saveChanges(formikProps) {
    if(this.isPageOneValid(formikProps) && this.isPageThreeValid(formikProps) && this.isPageTwoValid(formikProps)){
      this.setState({
        fullPageError: null,
        checkError: false
      })
      formikProps.submitForm();
    }else {
      this.setState({
        fullPageError: "Please ensure all fields are complete.",
        checkError: true
      })
    }
  }

  isCurrentPageValid(formikProps={}) {
    const { page }  = this.state;
    switch(page) {
      case 1:
        return this.isPageOneValid(formikProps)
        break;
      case 3:
        return this.isPageThreeValid(formikProps)
        break;
      case 2:
        return this.isPageTwoValid(formikProps);
        break;
      case 4:
        return true
      default:
        break;
    }
  }

  prevPage(formikProps){
    const { page }  = this.state;
    window.scrollTo(0,0);
    this.setState({
      page: page - 1,
      fullPageError: null,
      checkError: false
    })
  }

  renderVisaInformation(formikProps) {
    const { checkError } = this.state;
    const { values } = formikProps;
    if (values.user.work_address_country === "United States") return <></>;
    return (
      <>
        <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
        </div>
        <div className="col-xs-12 sg-registration-h3-copy  sg-registration-section-header-spacing">
        Visa Information
        </div>
        <div className="col-xs-12 gc-registration-copy">
          <br/>
          Does your Embassy require a letter of invitation to obtain a United States Visa?
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('embassyrequireinvitation',formikProps, false, {
            forceValue:"True",
          })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
          {this.renderCheckbox('embassyrequireinvitation',formikProps, false, {
            forceValue:"False",
          })}<label className="checkbox-label"> No </label>
          <div className="registration-field-error">
            {checkError && !this.isPageThreeValid(formikProps) && values.user.embassyrequireinvitation == null ? "This field is required to proceed." : ""}
          </div>
        </div>
        {values.user.embassyrequireinvitation == "True" ? (
          <>
            <div className="col-xs-12 col-sm-4">
              <label className="">Your First Name <span className="sg-registration-small-copy">as it Appears on Your Passport</span><br/><br/></label>
              {this.renderField('passportfirstname',formikProps)}
            </div>
            <div className="col-xs-12 col-sm-4">
              <label className=""> Your Middle Name <span className="sg-registration-small-copy">as it Appears on Your Passport</span><br/>(optional)</label>
              {this.renderField('passportmiddlename',formikProps, false, {not_required: true})}
            </div>
            <div className="col-xs-12 col-sm-4">
              <label className=""> Your Last Name <span className="sg-registration-small-copy">as it Appears on Your Passport</span><br/><br/></label>
              {this.renderField('passportlastname',formikProps)}
            </div>
            <div className="col-xs-12">
              <label className=""> Embassy Name (where application will be submitted)</label>
              {this.renderField('embassyname',formikProps)}
            </div>
            <div className="col-xs-12">
              <label className=""> Embassy Address</label>
              {this.renderField('embassyaddress',formikProps)}
            </div>
            <div className="col-xs-12">
              <label className=""> Embassy Fax Number</label>
              {this.renderField('embassyfaxnumber',formikProps)}
            </div>
            <div className="col-xs-12 gc-registration-copy">
              <br/>
              A representative from Cisco will contact you within 2 business days.
            </div>
          </>
        ):""}
      </>
    )
  }

  renderRequiredFlightAccomodations(formikProps) {
    const { checkError } = this.state;
    const { features } = this.props;
    const { values } = formikProps;
    return (
      <>
      <div className="col-xs-12 sg-registration-h3-copy  sg-registration-section-header-spacing">
        Ground Transportation
      </div>
      <div className="col-xs-12">
        <br/>
        Cisco will provide complimentary ground transportation upon arrival to and departure from Palm Springs International Airport (PSP).
        {features.shut_down_profile ? "" : "You will have the opportunity to return to add flight information once your flight is booked."}
      </div>
      <div className="col-xs-12 ">
        <br />
        Will you require transportation from the airport to the hotel?
      </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('requiretransportfromairport',formikProps, false, {
            forceValue:"True",
          })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
          {this.renderCheckbox('requiretransportfromairport',formikProps, false, {
            forceValue:"False",
          })}<label className="checkbox-label"> No </label>
          <div className="registration-field-error">
            {checkError && !this.isPageTwoValid(formikProps) && values.user.requiretransportfromairport == null ? "Please select an option" : ""}
          </div>
        </div>
        {this.renderTransportationFromAirport(formikProps)}
        <div className="col-xs-12 ">
          <br/>
          Will you require transportation from the hotel to the airport?
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('requiretransportfromhotel',formikProps, false, {
            forceValue:"True",
          })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
          {this.renderCheckbox('requiretransportfromhotel',formikProps, false, {
            forceValue:"False",
          })}<label className="checkbox-label"> No </label>
          <div className="registration-field-error">
            {checkError && !this.isPageTwoValid(formikProps) && values.user.requiretransportfromhotel == null ? "Please select an option" : ""}
          </div>
        </div>
        {this.renderTransportationFromHotel(formikProps)}
        {this.renderSpecialTransportationNeeds(formikProps)}
        <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
        </div>
        {this.renderHotelInformation(formikProps)}
      </>
    )
  }

  isGcabMember() {
    const { gcabMember } = this.state;
    return gcabMember;
  }

  renderHotelInformation(formikProps) {
    const { checkError } = this.state;
    const { features } = this.props;
    const { values } = formikProps;
    const gcabMember = this.isGcabMember()
    return (
      <>
        <div className="col-xs-12 sg-registration-h3-copy  sg-registration-section-header-spacing">
          Hotel Accommodations {gcabMember ? "- Complimentary" : ""}
        </div>
        {}
        <div className="col-xs-12 ">
          <br/>
          In the heart of the Palm Springs Valley, the Ritz-Carlton, Rancho Mirage offers nature, history, and luxury. This 4-star, mountain-top resort boasts sweeping views and an elevated experience, providing the perfect backdrop for this exclusive event.
        </div>
        {gcabMember ? (
          <div className="col-xs-12 sg-registration-copy">
            <br/>
            Cisco will complimentary provide up to 3 nights hotel and applicable taxes and fees.
            <br/>
            <br/>
            To assist with your travel plans and hotel accommodations, Cisco CIO Exchange programming will begin Tuesday, April 25 at 5 pm PT, and will conclude Wednesday, April 26 at 8 pm PT. The optional networking activities will begin Tuesday morning.
            <br/>
            <br/>
            Would you like Cisco to book and pay for your hotel reservation at the Ritz-Carlton, Rancho Mirage?
          </div>
        ) : (
          <div className="col-xs-12 sg-registration-copy">
            <br/>
            To assist with your travel plans and hotel accommodations, Cisco CIO Exchange programming will begin Tuesday, April 25 at 5 pm PT, and will conclude Wednesday, April 26 at 8 pm PT. The optional networking activities will begin Tuesday morning.
            {features.shut_down_profile ? "" : (<>
              You can access this section later to amend your hotel accommodations as you finalize your travel plans."
              </>
            )}
            <br/>
            <br/>
            Would you like Cisco to secure your hotel reservation at the Ritz-Carlton, Rancho Mirage?
          </div>
        )}

        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('ciscosecuresreservation',formikProps, false, {
            forceValue:"True",
          })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
          {this.renderCheckbox('ciscosecuresreservation',formikProps, false, {
            forceValue:"False",
          })}<label className="checkbox-label" style={{marginRight:"20px"}}> No </label>
          {features.shut_down_profile ? "" : (
            <>
              {this.renderCheckbox('ciscosecuresreservation',formikProps, false, {
                forceValue:"Not at this time",
              })}<label className="checkbox-label"> Not at this time </label>
            </>
          )}

          <div className="registration-field-error">
            {checkError && !this.isPageTwoValid(formikProps) && values.user.ciscosecuresreservation == null ? "Please select an option" : ""}
          </div>
        </div>

        {values.user.ciscosecuresreservation == "False" && gcabMember ? (
          <>
          <div className="col-xs-12 sg-registration-copy">
            <br/>
            If your company does not allow you to accept the complimentary hotel accommodations, please see the Room Rate below and provide your credit card information to secure your reservation.
            <br/>
            <br/>
            Would you like Cisco to arrange your hotel reservation at the Ritz-Carlton, Rancho Mirage?
          </div>
          <div className="col-xs-12">
            <br/>
            {this.renderCheckbox('gcaphotelarrangesdoesnotpay',formikProps, false, {
              forceValue:"True",
            })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
            {this.renderCheckbox('gcaphotelarrangesdoesnotpay',formikProps, false, {
              forceValue:"False",
            })}<label className="checkbox-label" style={{marginRight:"20px"}}> No </label>
            <div className="registration-field-error">
              {checkError && !this.isPageTwoValid(formikProps) && gcabMember && values.user.gcaphotelarrangesdoesnotpay == null ? "Please select an option" : ""}
            </div>
          </div>   
          </>
        ):""}

          {values.user.ciscosecuresreservation == "True" || (gcabMember && values.user.gcaphotelarrangesdoesnotpay == "True")? (
            <>
              <div className="col-xs-12 col-sm-6">
                <label>Hotel Check In Date</label>
                {this.renderSelectField('hotelcheckindate',formikProps, this.isAttendeeEmployee() ? employeeCheckinDates(formikProps) : checkinDates(formikProps))}
              </div>
              <div className="col-xs-12 col-sm-6">
                <label>Hotel Check Out Date</label>
                {this.renderSelectField('hotelcheckoutdate',formikProps, checkoutDates(formikProps))}
              </div>
              <div className="col-xs-12">
                <label>Do you have special hotel requests?</label>
                <br/>
                {this.renderCheckbox('needhotelspecialrequests',formikProps, false, {
                  forceValue:"True",
                })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
                {this.renderCheckbox('needhotelspecialrequests',formikProps, false, {
                  forceValue:"False",
                })}<label className="checkbox-label"> No </label>
                <div className="registration-field-error">
                  {checkError && !this.isPageTwoValid(formikProps) && values.user.needhotelspecialrequests == null ? "Please select an option" : ""}
                </div>
              </div>
              {values.user.needhotelspecialrequests == "True" ? (
                <div className="col-xs-12">
                  <label className="">Hotel Special Requests</label>
                  {this.renderField('hotelspecialrequests',formikProps, false, {component: "textarea"})}
                        <br/>
                        <br/>
                </div>
              ):""}

              {gcabMember && values.user.ciscosecuresreservation == "True" ? (
                  ""
              ): (
                <>
                  <div className="col-xs-12" style={{paddingTop: "15px"}}>
                    <b>Rate:</b> $389.00 + applicable taxes and fees. An additional 13.38% tax charged per night will be levied on all bookings as well as a $20 taxed resort fee per night. Taxes and fees are subject to change. All room charges, including incidentals are the individual’s responsibility.
                    <br/>
                    <br/>
                    <b>Room Description:</b> Standard Deluxe Room
                    <br/>
                    <br/>
                    <b>Cancellation Policy:</b> To avoid cancellation fees, please cancel your reservation 72 hours prior to arrival. For uncancelled reservations, the hotel will charge the credit card on file for the room rate and tax for the entire length of stay reserved.
                    <br/>
                    <br/>
                    <b>Deposit Policy:</b> The hotel reserves the right to charge the card on file for one night’s deposit one week before arrival. The deposit amount will be applied to the final total upon checkout.
                    <br/>
                    <br/>

                  </div>
                  {this.renderCreditCardForm(formikProps)}
                </>
              )}
 

            </>
          ):""}
      </>
    )
  }

  renderSpecialTransportationNeeds(formikProps) {
    const { features } = this.props;
    const { values } = formikProps;
    if(features.travel_info && (values.user.requiretransportfromhotel == "True" || values.user.requiretransportfromairport == "True")) {
      return (
        <>
           <div className="col-xs-12">
             <label className="">If you have any special transportation needs, please note them here:</label>
            {this.renderField('specialtransportationneeds',formikProps, false, {not_required: true, component:"textarea"})}
           </div>
        </>
      )
    }
  }

  renderTransportationFromHotel(formikProps) {
    const { features } = this.props;
    const { values } = formikProps;
    if(features.travel_info && values.user.requiretransportfromhotel == "True") {
      return (
        <>
          <div className="col-xs-12">
            <label className="">Please provide your flight information. (Optional)</label>
          </div>
          <div className="col-xs-12">
            <label className="">Departure Date</label>
            {this.renderSelectField('departuredate',formikProps, departureDates(formikProps), false, {not_required: true})}
          </div>
          <div className="col-xs-12">
            <label className="">
              Flight Departure Time
              <span className="sg-registration-small-copy"><br/>
              *Exact time to depart hotel will be provided onsite if Cisco ground transportation has been requested.</span>
            </label>
            {this.renderField('departuretime',formikProps, false, {not_required: true, type: "time"})}
          </div>
          <div className="col-xs-12">
             <label className="">Airline Carrier or FBO Name (if flying private)</label>
             {this.renderField('departureairline',formikProps, false, {not_required: true})}
           </div>
           <div className="col-xs-12">
             <label className="">Flight Number or Tail Number (if flying private)  </label>
             {this.renderField('departureflightnumber',formikProps, false, {not_required: true})}
           </div>
        </>
      )
    }
  }

  renderTransportationFromAirport(formikProps) {
    const { features } = this.props;
      const { values } = formikProps;
    if(features.travel_info && values.user.requiretransportfromairport == "True") {
      return (
        <>
          <div className="col-xs-12">
            <label className="">Please provide your flight information. (Optional)</label>
          </div>
          <div className="col-xs-12">
            <label className="">Arrival Date</label>
            {this.renderSelectField('arrivaldate',formikProps, arrivalDates(formikProps), false, {not_required: true})}
          </div>
          <div className="col-xs-12">
            <label className="">Arrival Time</label>
            {this.renderField('arrivaltime',formikProps, false, {type: "time", not_required: true})}
          </div>
          <div className="col-xs-12">
            <label className="">Flight Origin (departure city)</label>
            {this.renderField('arrivalorigin',formikProps, false, { not_required: true})}
          </div>
          <div className="col-xs-12">
            <label className="">Airline Carrier or FBO Name (if flying private)</label>
            {this.renderField('arrivalairline',formikProps, false, { not_required: true})}
          </div>
          <div className="col-xs-12">
            <label className="">Flight Number or Tail Number (if flying private) </label>
            {this.renderField('arrivalflightnumber',formikProps, false, {not_required: true})}
          </div>
        </>
      )
    }
  }

  hasAlreadyEnteredCCInfo() {
    const { attendee } = this
  }

  renderCreditCardForm(formikProps) {
    const { collectedCreditInfo, checkError } = this.state;
    const { participant} = this.props;
    return (
      <>


      <div className="col-xs-12 registration-field-error">
        {checkError && !this.isPageTwoValid(formikProps) && !collectedCreditInfo ? "Please select enter your credit card information to continue" : ""}
      </div>
      </>
    )
  }

  isProfileMode(){
    const { features } = this.props;
    return !!features['profile'];
  }

  getInitialValues() {
    const { participant, fields } = this.props;
    if(!this.isProfileMode()) {
      return {
        user: {
          email: participant ? participant.email : "",
          name_first: participant ? participant.name_first : "",
          name_last: participant ? participant.name_last : "",
          job_title: participant ? participant.job_title : "",
        }
      }
    }else {
      let initialValues =  {
        user: {
          email: participant['email'],
          name_first: participant ? participant.name_first : "",
          name_last: participant ? participant.name_last : "",
          job_title: participant.job_title,
          work_phone: participant.override_work_phone,
          work_address_street_1: participant['work_address_street_1'],
          work_address_street_2: participant['work_address_street_2'],
          work_address_country: participant['work_address_country'],
          work_address_state: participant['work_address_state'],
          work_address_city: participant['work_address_city'],
          work_address_postcode: participant['work_address_postcode'],
          override_bio: participant['bio'],
          name_first: participant['name_first'],
          name_last: participant['name_last'],
          job_title: participant['job_title'],
          company: participant['company'],
          update_profile: true,
        }
      }
      Object.keys(fields).map(fieldkey=> {
        initialValues['user'][fieldkey] = participant.registration_answers[fieldkey]
      })
      return initialValues
    }
  }

  storeValues(formikProps) {
    this.values = formikProps.values
  }

  render() {
    const { user, participant} = this.props;
    return (
      <div className="registration-form">
        {this.renderTester()}
        <Formik
          initialValues={this.getInitialValues()}
          onSubmit={(values, actions) => {
            submitRegistration(values, this.state, this)
          }}
          render={(formikProps) => (
            <Form ref={this.formikRef} onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="user-registration-form" style={{border: "0"}}>
              {this.renderHeader(formikProps)}
              {this.storeValues(formikProps)}
              <div className="container registration-full-container sg-registration-copy">
                {this.renderPageOne(formikProps)}
                {this.renderPageTwo(formikProps)}
                {this.renderPageThree(formikProps)}
                {this.renderPageFour(formikProps)}
                {this.renderButtons(formikProps)}
              </div>

            </Form>
        )}/>
      </div>
    );
  }
}

export default RegistrationForm;
