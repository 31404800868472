import React, { useState } from "react";
import { format, formatDistance, formatRelative, addDays, isSameDay, addMinutes} from 'date-fns'
import { formatToTimeZone } from 'date-fns-timezone'
const LandingAgendaV2 = (props) => {
  const { sessions, attendee, ticket } = props;

  const getFilteredSessionsByDate = () => {
    const startDate = new Date(ticket.start_date+"T00:00:00.000Z")
    const endDate = addDays(new Date(ticket.end_date+"T00:00:00.000Z"), 1)
    return sessions.map(session => {
      session.amPm = (session.time_formatted||"").slice(-2);
      return session
    }).filter(session => {
      const date = new Date(session.date_and_time_local.split("T")[0]+"T00:00:00.000Z")
      return date < endDate && date >= startDate
    })
  }


  const getFilteredSessionsByTag = () => {
    return sessions.map(session => {
      session.amPm = (session.time_formatted||"").slice(-2);
      return session
    }).filter(session=>
      (session.session_tags||[]).indexOf(ticket.id) != -1
    ).filter(session =>
      (session.session_tags||[]).indexOf("kbyg") != -1
    )
  }
  
  const renderDates = () => {
    const filteredSessions = getFilteredSessionsByTag();
    const dates = filteredSessions.filter(x=>x.date_and_time_local).map(x=>x.date_and_time_local.split("T")[0]).filter((x,i,t)=>t.indexOf(x) == i).sort((a,b) => new Date(a) - new Date(b))
    return dates.map(date=>
      renderDate(date, filteredSessions.filter(x=>x.date_and_time_local.split("T")[0] == date))
    )
  }

  const renderDate = (date, filteredSessions) => {
    const dateFilteredSessions = filteredSessions.sort((a,b) => new Date(a.date_and_time_local) - new Date(b.date_and_time_local))
    return (
      <div className="sg-logged-in-landing-page-agenda-date">
        <div className="sg-logged-in-landing-page-agenda-date-header">
          {format(addMinutes(new Date(date), new Date(date).getTimezoneOffset()), "EEEE, MMMM do")}
        </div> 
        <div className="sg-logged-in-landing-page-agenda-date-table">
          {filteredSessions.map((session, index) => {
            return renderDateTableSession(session, index, dateFilteredSessions)
          })}
        </div> 
      </div>
    )
  }

  const renderDateTableSession = (session, index, filteredSessions) => {

    const startTime = new Date(session.date_and_time_local)
    return (
      <div className="sg-logged-in-landing-page-agenda-date-table-session">
        <div className={`sg-logged-in-landing-page-agenda-date-table-session-time-marker sg-logged-in-landing-page-agenda-date-table-session-time-marker-${session.amPm}`}>
          {formatToTimeZone(startTime, "hh:mm aa", { timeZone: 'Europe/Paris' })}
          {session.length_minutes && session.length_minutes > 0 ? (
            <>
            {" - "}<br/>{formatToTimeZone(addMinutes(startTime, session.length_minutes), "hh:mm aa", { timeZone: 'Europe/Paris' })}
            </>
          ):""}
        </div>
        <div className="sg-logged-in-landing-page-agenda-date-table-session-title">
          {session.title}
          <div className="sg-logged-in-landing-page-agenda-date-table-session-description">
            <div dangerouslySetInnerHTML={{ __html: session.description }} />
          </div>
        </div>

      </div>
    )
  }

  return (
    <div className="sg-logged-in-landing-page-interactive-agenda sg-logged-in-landing-page-interactive-agenda-v2">
      <div className="row">
        <div className="row sg-logged-in-landing-page-interactive-agenda-body-container ">

          <div style={{paddingLeft: "32px"}} className="col-xs-12 col-md-12">
            <div className="sg-logged-in-landing-page-interactive-agenda-header">
              {ticket.label}
            </div>
            {renderDates()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingAgendaV2;
