import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
import { Formik, Field, Form } from "formik";

class EmailInterestPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      emailValue: "",
      page:0,//0 Start Page, 1 Email Captured, 2 address Captured
      record_id: null,
    }
    this.checkEmail = this.checkEmail.bind(this);
  }


  checkEmail(e) {
    const { interested } = this.props;
    e.preventDefault()
    const { emailValue } = this.state;
    fetchAPI(`/email_response/check_email`,(json)=>{
      if(json && json.error == null){
        this.setState({
          success: true,
          record_id: json.record_id,
          page: 1
        });
      }else if(json && json.error) {
        this.setState({
          error: json.error
        })
      }else {
        this.setState({
          error: "An error occurred. Please try again in a moment."
        })
      }
    },{
      method: 'POST',
      body: {
        email: emailValue||"",
        interested: interested
      }
    })
  }

  renderOpeningPage() {
    const { emailValue, error, success} = this.state;
        const { interested } = this.props;
    return (
      <form src="#" onSubmit={this.checkEmail} className="att-proam-registration-email-validation registration-form ">
        <div className={`registration-page-one`} style={{maxWidth: "630px"}}>
          {interested ? (
            <div className="row" >
              <div className="col-xs-12 att-proam-registration-section-header text-left">
                Yes! I can’t wait to attend
              </div>
            </div>
          ):(
            <div className="row" >
              <div className="col-xs-12 att-proam-registration-section-header text-left">
                I respectfully decline
              </div>
            </div>
          )}
        <div className="row" >
          <div style={{fontSize: "15px"}} className="col-xs-12 att-proam-registration-section-header text-left">
            Enter your email address to continue.
          </div>
        </div>
        </div>
        <div className="">
          <br/>
          <input
            value={emailValue}
            onChange={(e) => this.setState({
              emailValue: e.target.value
            })}
            className="form-control"
          />
        </div>
        {error ? (
          <div className="att-proam-registration-email-validation-error">
            {error}
          </div>
        ):""}
        <div className="att-proam-registration-email-validation-submit">
          <br/>
          <br/>
          <button
            type="button"
            onClick={this.checkEmail}
            className="att-proam-button att-proam-submit-button"
          >
            Continue
          </button>
        </div>
        <div className={`registration-page-one`} style={{maxWidth: "630px"}}>
          <div className="row" >
            <div className="col-xs-12 att-proam-registration-section-copy text-left">
            </div>
          </div>
        </div>
      </form>
    );
  }


  renderField(name,formikProps, customValidation = false, properties = {}) {
    const { checkError } = this.state;
    let erroring = checkError && (formikProps.values.user[name] == undefined || formikProps.values.user[name].length <= 0 || customValidation);
    if(properties['not_required']) {
      erroring = false || customValidation;
    }
    return (
      <Field
        disabled={properties['disabled']}
        onKeyUp={() => properties['onKeyUp'](formikProps)}
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`}
        type={properties['type']}
        name={`user[${name}]`}
      />
    )
  }

  renderThankYou() {
    const { interested } = this.props;
    return (
      <div className="att-proam-registration-email-validation-copy">
        <div class="att-proam-registration-section-header">
          {
            interested ?
            "Thank you for your response."
            :
            ("Thank you for your response. We’re sorry to miss you.")
          }
        </div>
        {
          interested ?
          (
            <>
              You will receive an invitation to register in the next couple of weeks.
              <br/>
              <br/>
            </>
          ):""
        }
        If you have any questions, please contact <a href="mailto:cxoevents@cisco.com">cxoevents@cisco.com</a>.
        <br/>
        <br/>
        <div className="text-center">
          <a target="_blank" href="mailto:cxoevents@cisco.com">
            <button type="button" style={{paddingTop: "10px"}} className="att-proam-button att-proam-submit-button">
              Contact Us
            </button>
          </a>
        </div>
      </div>
    )
  }

  render() {
    const { page } = this.state;
    switch(page) {
      case 0:
        return this.renderOpeningPage();
      case 1:
        return this.renderThankYou();
    }
  }
}

export default EmailInterestPage;
