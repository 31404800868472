import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Carousel, { ButtonGroup } from "react-multi-carousel";
import AgendaSlideButtons from "./agenda/AgendaSlideButtons"
import { subHours, format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';

class LandingAgendaSlides extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  legacyGetSessions() {
    return [
      {
        section_id: 1,
        title: "Geopolitics and Global Risk",
        description: "Chuck Robbins, Cisco Chair and CEO, welcomes Ian Bremmer, President of Eurasia Group and GZERO Media, for a fireside chat about geopolitics, including what it means for global business.",
        icon: "7"
      },
      {
        section_id: 1,
        icon: "6",
        title: "Cisco’s Vision and Strategy + Q&A",
        description: "Join an interactive exchange with Chuck Robbins on his top of mind and the future of Cisco; plus dive into lively Q&A about what matters to you."
      },
      {
        section_id: 1,
        icon: "8",
        title: "Global Economy",
        description: "Diane Swonk, KPMG US Chief Economist will provide a compelling look at the global economy, including some predictions about what the indicators mean for global business.",
      },
      {
        section_id: 2,
        icon: "optimization",
        title: "Digital Acceleration",
        description: "COVID recalibrated our expectations for how quickly we can – and must – adapt. Now we need to focus on how we manage and prioritize digital acceleration going forward.",
      },
      {
        section_id: 2,
        icon: "5",
        title: "Cybersecurity",
        description: "Cybersecurity is consistently among the top concerns for CIOs across multiple studies. What’s coming next? Alex Stamos, Professor and former Facebook CSO, will share the new and emerging threats and what you can do to prepare.",
      },
      {
        section_id: 2,
        icon: "4",
        title: "Evolution of IT",
        description: "The role of IT is evolving and so are your stakeholders. Fletcher Previn, Cisco CIO, welcomes CIOs with varying perspectives to examine who you need to have in your camp and why.",
      },
      {
        section_id: 2,
        icon: "8",
        title: "Turbulence as a Creator of Opportunities ",
        description: "Turbulence. It’s here to stay. And while it feels disruptive and uncomfortable, it’s almost always a catalyst for new opportunities. ",
        //Bozoma Saint John, former Global Chief Marketing Officer at Netflix, will examine some incredible innovations and outcomes that were born out of turbulence.
      },
      {
        section_id: 3,
        icon: "people-and-purpose",
        title: "People and Purpose",
        description: "In today’s war for talent, culture matters. Francine Katsoudas, EVP and Chief People, Policy & Purpose Officer, Cisco, will explore the role of culture in creating an environment where employees thrive and companies can use their influence to impact the world for the better.",
      },
      {
        section_id: 3,
        icon: "2",
        title: "Future of Work",
        description: "Zak Brown, CEO, and Ed Green, Head of Commercial Technology, from McLaren Racing, tell their unique story of how they’re tackling the Future of Work with a global network of racing engineers and experts collaborating in real time, where every small decision – or any delay – could be the difference.",
      },
      {
        section_id: 3,
        icon: "diversity",
        title: "Diversity, Equity, and Inclusion",
        description: "#1 NYT best-selling author, two-time Emmy award winner, and Fox Sports analyst, Emmanuel Acho, joins Chuck Robbins for an intimate conversation about inclusivity in America and how to create a truly inclusive organization ready to ignite the potential of all employees.",
      },
    ]
  }

  getSessions() {
    const { sessions } = this.props;
    return sessions.filter(x=>x.session_type_name!="Activity").sort((a,b)=>{
      const dateA = new Date(a.date_and_time)
      const dateB = new Date(b.date_and_time)
      return dateB < dateA ? 1 : -1
    })
  }

  getSections() {

    return [
      {name:'Tuesday, April 25', tag: 'day1-session-landing-page',id: 1},
      {name:'Wednesday, April 26', tag: 'day2-session-landing-page',id: 2},
    ].map(x=>{
      x.sessions = this.getSessions().filter(y=>y.session_tags.indexOf(x.tag) != -1)
      return x
    })
  }

  getArrows() {
    return (
      <div className="carousel-button-group"> // remember to give it position:absolute

      </div>
    );
  }

  renderSections() {
    return this.getSections().map(section=>

      <div className="sg-landing-page-agenda-section-topic">
        <div className="sg-landing-page-agenda-section-topic-header">
          {section.name}
        </div>
        <div className="sg-landing-page-carousel sg-landing-page-session-carousel">
          <Carousel
            autoPlaySpeed={7000+(Math.floor(Math.random() * 3)*1000)}
            autoPlay={section.sessions.length > 2}
            infinite={section.sessions.length > 2}
            responsive={section.sessions.length > 1 ? this.getResponsiveOptions() : {normal: { breakpoint:{max: 100000, min:0}, items: 1}}}
            itemClass="sg-landing-page-session-carousel-item"
            arrows={false}
            renderButtonGroupOutside={true}
            customButtonGroup={ section.sessions.length > 2 ? (<AgendaSlideButtons/>) :""}
          >
            {this.renderSessions(section.sessions)}
          </Carousel>
        </div>
      </div>
    )
  }

  getResponsiveOptions(){
    return {
      normal: {
        breakpoint: { max: 10000, min: 768},
        items: 2
      },
      mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1
      }
    }
  }

  renderDateAndTime(session) {
    if(session.date_and_time) {
      const start_time = new Date(session.date_and_time_utc)
      const end_time = addMinutes(start_time, session.length_minutes||0)
      if(start_time != end_time && session.length_minutes && session.length_minutes > 0 ) {
        return (
          `${formatToTimeZone(new Date(start_time), "hh:mm", { timeZone: 'America/Los_Angeles' })} - ${formatToTimeZone(new Date(end_time), "hh:mm aa", { timeZone: 'America/Los_Angeles' })}`
        )
      }else {
        return `${formatToTimeZone(new Date(start_time), "hh:mm aa", { timeZone: 'America/Los_Angeles' })}`
      }
    }
  }

  getSessionIcon(session) {
    const legacySession = this.legacyGetSessions().find(x=>x.title == session.title);
    if(session && session.thumbnail_url){
      return session.thumbnail_url
    }
    if(legacySession) {
      return `/images/landing/agenda/${legacySession.icon}.svg`
    }
    return "/images/landing/agenda/1.svg"
  }

  renderSessions(sessions) {
    return sessions.map(session=>(
      <div className="sg-landing-page-agenda-session-container">
        <div className="sg-landing-page-agenda-session-icon">
          <img src={this.getSessionIcon(session)}/>
        </div>
        <div className="sg-landing-page-agenda-session-content">
          <div className="sg-landing-page-agenda-session-content-date">
            {this.renderDateAndTime(session)}
          </div>
          <div className="sg-landing-page-agenda-session-content-name">
            {session.title}
          </div>
          <div className="sg-landing-page-agenda-session-content-speakers">
            {session.speakers.map(x=>(
              <div className="sg-landing-page-agenda-session-content-speaker-name">
                <b>{`${x.name_first} ${x.name_last}`}</b>, {`${x.job_title}`}
              </div>
            ))}
          </div>
          <div className="sg-landing-page-agenda-session-content-description" dangerouslySetInnerHTML={{__html: session.description||""}}/>
        </div>
      </div>
    ))
  }

  render() {
    return (
      <div className="sg-landing-page-agenda">
        {this.renderSections()}
      </div>
    )
  }
}

export default LandingAgendaSlides;
