import React from "react";

class KbygGeneralInformationSection extends React.Component {
  render() {
    return (
      <>

        <div className="sg-kbyg-overview-section-block sg-kbyg-general-information-block">
          <div className="sg-kbyg-large-copy font-bold">
          Attire and Weather
          </div>
          <div className="sg-kbyg-copy font-light">
            Business casual is appropriate for sessions and receptions.
            <br/>
            <br/>
            Average April temperatures in Palm Springs range from highs around 87 degrees to lows around 57 degrees.
            <br/>
            <br/>
            We recommend dressing in layers as all meal functions will be held outdoors. Keep an eye on local forecasts and come prepared with rain gear and sweater/jacket options in the event of inclement weather during outdoor activities.
          </div>
        </div>

        <div className="sg-kbyg-overview-section-block sg-kbyg-general-information-block">
          <div className="sg-kbyg-large-copy font-bold">
          Health & Safety
          </div>
          <div className="sg-kbyg-copy font-light">
            The health and safety of all attendees is our top priority. If you are feeling unwell, please refrain from attending the event.
            <br/>
            <br/>
            At this time, Cisco does not have any health-related requirements in place for event attendance. Should this change, we will follow up with updated guidance.
          </div>
        </div>

        <div className="sg-kbyg-overview-section-block sg-kbyg-general-information-block">
          <div className="sg-kbyg-large-copy font-bold">
            Webex Space for Attendees
          </div>
          <div className="sg-kbyg-copy font-light">
            You will be added to a dedicated Webex space for Cisco CIO Exchange attendees, only if you agreed when you registered. In this space we’ll provide important information, reminders, and up-to-the-minute updates.
            <br/>
            <br/>
            To join the Webex space, you’ll need a Webex account. If you don’t yet have an account <a href="https://www.webex.com/downloads.html" target="_blank">download the free app</a> to set up your profile. 
            <br/>
            <br/>
            Please be sure to use the email address you used to register for Cisco CIO Exchange. Please be aware that when joining the Webex space, your Webex user ID includes your email address that will be visible to other attendees in the space. For more information on how Cisco collects, protects, and retains your personal information, please visit <a href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html" target="_blank">Cisco Online Privacy Statement</a>.
          </div>
        </div>

        <div className="sg-kbyg-overview-section-block sg-kbyg-general-information-block">
          <div className="sg-kbyg-large-copy font-bold">
          Wireless Access
          </div>
          <div className="sg-kbyg-copy font-light">
            Cisco provides wireless internet access in the Ritz-Carlton, Rancho Mirage.
            <br/>
            <br/>
            Wireless SSID: Ritz_Conf
            <br/>
            Passcode: cioexchange
            <br/>
            <br/>
            This is a Public Internet Connection and the use of a personal firewall is suggested.
            <br/>
            <br/>
            <br/>
          </div>
        </div>
      </>

    )
  }
}

export default KbygGeneralInformationSection;
