import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
import Select from 'react-select'
class RegistrationEmailValidation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      error: null,
      emailValue: "",
      urlError: props.error,
    }
  }

  renderPickADate() {
    const { features } = this.props;
    const { selectedDate } = this.state;
    const options = [
      {value: 'day-5', label: 'Sunday, February 6, 2022'}
    ]
    if(features && features.pick_a_date) {
      return (
        <>
          <label>Please select the day you would like to join us from the dropdown below.</label>
          <div style={{maxWidth:"600px",margin: "auto"}}>
            <Select
              options={options}
              classNamePrefix='registration-select'
              value={selectedDate}
              onChange={(option) => {
                this.setState({
                  selectedDate: option
                })
              }}
            />
          </div>
          <br/>
        </>
      )
    }
  }

  render() {
    const { urlError, emailValue, error } = this.state;
    return (
      <form onSubmit={this.verifyEmail} className="att-proam-registration-email-validation">
        <div className="sg-registration-h2-copy">
          Welcome to the registration for Cisco CIO Exchange 2023.
        </div>
        <div className="sg-reg-validation-form-bubbles-image-container">
          <img src="/images/registration/sg-reg-validation-form-bubbles.svg"/>
        </div>
        <div className="sg-registration-copy">
        <br/>
          Attendance at Cisco CIO Exchange 2023 is by invitation-only.
          <br/>
          Please ensure that the email address provided is that of the registrant.
        </div>
        <div className="att-proam-registration-email-validation-submit">
          <br/>
          <a href="/users/auth/okta">
            <button
              type="button"
              className="att-proam-button att-proam-submit-button"
            >
              Register
            </button>
          </a>
          <br/>
          <br/>
        </div>
        {urlError=="attendee-not-found"? (
          <div className="att-proam-registration-email-validation-error">
            Registration for this event is by invitation only.
            <br/>
            If you have reached this page in error or have questions,
            <br/>
            please contact <a className="sg-registration-link" href="mailto:cxoevents@cisco.com">cxoevents@cisco.com</a>.
          </div>
        ):""}
        {urlError=="already-registered"? (
          <div className="att-proam-registration-email-validation-error">
            You’re already registered! If you wish to make changes  to your
            <br/>registration, please contact
            <a className="sg-registration-link" href="mailto:cxoevents@cisco.com">cxoevents@cisco.com</a>.
          </div>
        ):""}
        <div>

          <br/>
          <br/>

          <a style={{visibility: 'hidden'}} href="/users/auth/google_oauth2">
            <button
              type="button"
              className="att-proam-button att-proam-submit-button"
            >
              Sign in with Google Oauth (Jaguars Only)
            </button>
          </a>
          <br/>
          <br/>
        </div>
      </form>
    );
  }
}

export default RegistrationEmailValidation;
