import React from "react";
import FormBuilder from "../forms/FormBuilder";
import SurveyRadioField from './custom/SurveyRadioField'

class SurveyFormWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  callback(json) {
    console.log('reg submit complete');
    console.log(json);
  }

  render() {
    const { form } = this.props;
    return (
      <FormBuilder
        form={form}
        gid={form.gid}
        submitUrl={'/surveys'}
        submitCallback={this.callback}
        type={'survey'}
      />
    );
  }
}

export default SurveyFormWrapper;
