import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Carousel from "react-multi-carousel";


class AgendaSlideButtons extends React.Component {

  render() {
    const { next, previous} = this.props;
    return (
      <div className="sg-landing-page-agenda-carousel-buttons">
        <button onClick={next} aria-label="Go to next slide" className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right " type="button"/>
        <button  onClick={previous} aria-label="Go to previous slide" className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left " type="button"/>
      </div>

    )
  }
}

export default AgendaSlideButtons;
