import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";


class PublicActivityDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      open: false
    }
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  toggleOpen(){
    console.log("test");
    const { open } = this.state;
    this.setState({
      open: !open
    })
  }

  getClickProps() {
    const { click_id, activity_name} = this.props;
    return {
      "data-track-id": click_id ? click_id : "dashboard-click",
      "data-track-info":`Activity: ${activity_name}`,
    }
  }

  render() {
    const { title, children } = this.props;
    const { open } = this.state;
    return (
      <div className={`sg-landing-page-activity-dropdown-container ${open ? "open" : "closed"}`}>
        <div className="sg-landing-page-activity-dropdown-content row">
          <div className="hidden-xs col-sm-4">

          </div>
          <div className="col-xs-12 col-sm-8">
            {children}
          </div>

        </div>
        <div className="sg-landing-page-activity-dropdown-header" {...this.getClickProps()} onClick={this.toggleOpen}>
          <div className="row">
            <div className="hidden-xs col-sm-4">

            </div>
            <div {...this.getClickProps()} className="col-xs-12 col-sm-8">
              <img {...this.getClickProps()} src="/images/landing/public_activity/dropdown.svg" className="sg-landing-page-activity-dropdown-signifier"/>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default PublicActivityDropdown;
