import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

class SpeakerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  renderSponsors() {
    return this.getSponsors().map(sponsor=>this.renderSponsor(sponsor))
  }

  renderSponsor(sponsor) {
    return (
      <div className="col-xs-6 col-sm-5ths sg-landing-page-sponsors-section-sponsor">
        <img className="" src={`/images/landing/sponsors/${sponsor.image}`}/>
      </div>
    )
  }

  getSponsors() {
    return [
      {image:"Logo_Prudential.png"},
      {image:"Logo_Nike.png"},
      {image:"Logo_HSBC.png"},
      {image:"Logo_Charter.png"},
      {image:"Logo_IBM.png"},
      {image:"Logo_Northrop-Grumman.png"},
      {image:"Logo_Komatsu.png"},
      {image:"Logo_UoM.png"},
      {image:"Logo_USBank.png"},
      {image:"Logo_Ford.png"},
    ]
  }

  render() {
    return (
      <div className="row sg-landing-page-sponsors-section-sponsors-container">
        {this.renderSponsors()}
      </div>
    );
  }
}

export default SpeakerModal;
