import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

class SpeakerModalSandbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalDescription: {description:""},
    }
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen,
    });
  }

  renderModal() {
    return (
      <div className="sg-rsvp-eori-modal-content">
        <div className="sg-rsvp-eori-modal-content-header">
          Economic Operator Registration and Identification Number (EORI)
        </div>
        <div class="sg-rsvp-eori-modal-content-copy">
          Businesses and people wishing to trade must use the EORI number as an identification number in all customs procedures when exchanging information with Customs administrations.
          The EORI number exists out of two parts:
          <ul className="sg-rsvp-eori-content-list">
            <li>the country code of the issuing Member State; followed by</li>
            <li>a code or number that is unique in the Member State</li>
          </ul>
          Persons established in the customs territory of the Union should request the assignment of the EORI number to the customs authorities of the EU country in which they are established.
          <br/>
          <br/>
          Persons not established in the customs territory of the Community should request the assignment of the EORI number to the customs authorities of the EU country responsible for the place where they first lodge a declaration or apply for a decision.
          <br/>
          <br/>
          <img 
            className="sg-rsvp-eori-modal-content-close-image"
            src="/images/close_modal.png" style={{cursor: "pointer"}} onClick={this.toggleModal}/>
        </div>
      </div>
    )
  }

  render() {
    const { modalOpen } = this.state;
    return (
      <>
        (<a id="eori-section" onClick={this.toggleModal} href="#eori-section">What is this?</a>)
        <Modal
          className="sg-admin-modal white-background disable-scrollbars"
          overlayClassName="sg-channel-survey-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default SpeakerModalSandbox;
