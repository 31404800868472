import React from "react";
import LandingFaqQuestion from "./LandingFaqQuestion"

class LandingFaq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  getSections() {
    return [
      {
        name:"Program",
        questions: [
          {
            question: "When will I need to register for this experience?",
            defaultOpen: true,
            answer: (
              "All attendees will receive a digital invitation at the beginning of 2024 that will give information on how to register."
            )
          },
          {
            question: "What is included in my registration?",
            answer: "All attendees will receive accommodation at the Le Bristol Paris, meals during your stay as well as executive transfers. If you have any dietary restrictions, please note this during the registration process."
          },
          {
            question: "May I bring a guest?",
            answer: "Yes, your invitation includes you and one guest. Please note that your guest must be at least 21 years old."
          }
        ]
      },

      {
        name:"Accommodations",
        questions: [
          {
            question: "Are accommodations included?",
            answer: (
              <>
                Yes, accommodation is included for the duration of your program at <a href="https://www.oetkercollection.com/hotels/le-bristol-paris/" target="_blank">Le Bristol Paris</a>.
              </>
            )
          },
          {
            question: "Can I add a day before or after my scheduled program?",
            answer: (
              "We cannot guarantee availability but will be happy to work with you after you formally register in the beginning of 2024 to see what is available."
            )
          },
        ]
      },

      {
        name:"Activities",
        questions: [
          {
            question: "What is the dress code?",
            answer: "Smart casual is recommended. Due to anticipated traffic conditions, we strongly recommend bringing comfortable walking shoes in case we need to walk long distances. A full detailed itinerary will be sent prior to your arrival with specific attire requirements for dinners, cultural experiences or any anticipated long walks."
          }
        ]
      }
    ]
  }

  renderSections() {
    return this.getSections().map(section => (
      <div className="sg-landing-page-faq-group">
        <div className="sg-landing-page-faq-group-header">
          {section.name}
        </div>
        <div className="sg-landing-page-faq-group-questions">
          {section.questions.map(question => (
            <LandingFaqQuestion 
              question={question}
              section={section}
            />
          ))}
        </div>
      </div>
    ))
  }



  render() {
    return (
      <div className="sg-landing-page-faq-container">
        {this.renderSections()}
      </div>
    )
  }
}

export default LandingFaq;
