import React from "react";
import { Field } from "formik";
class GeneralField extends React.Component {
  render() {
    const {
      checkError,
      optional,
      formikProps,
      objectName,
      name,
      type,
      placeholder,
      component,
      onKeyUp,
      disabled,
      inputProps,
      customValidation
    } = this.props;
    let erroring = checkError && (formikProps.values[objectName][name] == undefined || formikProps.values[objectName][name].length <= 0 || customValidation);
    if(optional) {
      erroring = false || customValidation;
    }
    return (
      <Field
        {...(inputProps||{})}
        disabled={disabled}
        onKeyUp={() => onKeyUp(formikProps)}
        component={component}
        className={`dark-form-control ${erroring ? 'field-erroring': "" }`}
        type={type}
        name={`${objectName}[${name}]`}
        placeholder={placeholder||""}
      />
    )
   }
}

export default GeneralField;
