import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
class RegistrationChangeDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      emailValue: "",
      changeDateValue: "",
      verifiedEmail: null,
      success: false,
      selectedDate: [],
    }
    this.verifyEmail = this.verifyEmail.bind(this);
    this.submitChangeRequest = this.submitChangeRequest.bind(this);
  }


  verifyEmail(e) {
    e.preventDefault()
    const { emailValue } = this.state;
    fetchAPI(`/registration/verify_email_change_date`,(json)=>{
      if(json && json.error == null){
        this.setState({
          verifiedEmail: emailValue,
          date: json.date,
          error: null,
        });
      }else if(json && json.error) {
        this.setState({
          error: json.error
        })
      }else {
        this.setState({
          error: "An error occurred. Please try again in a moment."
        })
      }
    },{
      method: 'POST',
      body: {
        email: emailValue||""
      }
    })
  }

  submitChangeRequest(e) {
    e.preventDefault()
    const { emailValue, selectedDate } = this.state;
    if(!selectedDate || selectedDate.length <= 0) {
      this.setState({
        error: "Please select a new date."
      })
      return;
    }
    let dates = {}
    selectedDate.map(x=>
      dates[x]=true
    )
    fetchAPI(`/registration/change_date`,(json)=>{
      if(json && json.error == null){
        this.setState({
          verifiedEmail: emailValue,
          success: true,
          error: null,
        });
      }else if(json && json.error) {
        this.setState({
          error: json.error
        })
      }else {
        this.setState({
          error: "An error occurred. Please try again in a moment."
        })
      }
    },{
      method: 'POST',
      body: {
        email: emailValue||"",
        dates: dates
      }
    })
  }

  renderCheckboxes() {

  }

  registrationAnswerToSlug(fields,id, answer) {
    try {
      const question = fields[id]
      return question.options.find(x=> x.label == answer).slug
    }catch(e) {
      console.log(e);
      return null
    }
  }

  getDate() {
    const { date } = this.state;
    const { participant, fields } = this.props;
    const slug = this.registrationAnswerToSlug(fields, 'programname', date)
    switch(slug) {
      case "day1":
        return (<>Thursday, April 7th</>)
      case "day2":
        return (<>Friday, April 8th</>)
      case "day3":
        return (<>Saturday, April 9th</>)
      case "day4":
        return (<>Sunday, April 10th</>)
      case "day5":
        return (<>Monday, April 11th</>)
      case "overnight1":
        return (<>Wednesday, Overnight Package 1</>)
      case "overnight1":
        return (<>Wednesday, Overnight Package 2</>)
    }
  }

  renderCheckbox(name, renderOptions={}) {
    let value = false;
    let { selectedDate } = this.state;
    try {
      value = selectedDate.indexOf(name) != -1
    }catch(e){

    }
    return(
      <img
        src={ value  ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
        style={{cursor:"pointer", marginBottom: "7px"}}
        onClick={(e) => {
          if(value) {
            selectedDate = selectedDate.filter(x=> x != name)
          }else {
            selectedDate = selectedDate.concat(name)
          }
          this.setState({
            selectedDate
          })
        }}
      />
    )
  }

  renderCheckboxes() {
    const { fields } = this.props;
    const { date } = this.state;
    const slug = this.registrationAnswerToSlug(fields, 'programname', date)
    const options = [
      {value: 'day1', label:"Thursday, April 7th"},
      {value: 'day2', label:"Friday, April 8th "},
      {value: 'day3', label:"Saturday, April 9th "},
      {value: 'day4', label:"Sunday, April 10th "},
      {value: 'day5', label:"Monday, April 11th "}
    ].filter(x=> x.value != slug);
    return (
      <div className="att-proam-change-date-checkbox-container">
        {options.map(x=>
          <div className="att-proam-change-date-checkbox">
            {this.renderCheckbox(x.value)}
            <label> {x.label} </label>
          </div>
        )}
      </div>
    )
  }

  render() {
    const { emailValue, error, success, verifiedEmail, date} = this.state;
    if(success) {
      return (
        <div className="att-proam-registration-email-validation-copy text-left">
          <div class="att-proam-registration-section-header">Thank you for requesting an alternate day</div>
          <br/>
          Someone from our team will be in contact with you shortly.
          <br/>
          <br/>
          If you have any questions, please contact the <a href="mailto:cxoevents@cisco.com">Cisco Executive Relations Team</a>.
          <br/>
          <br/>
          <div className="text-center">
            <a target="_blank" href="mailto:cxoevents@cisco.com">
              <button type="button" style={{paddingTop: "10px", paddingBottom: "20px"}} className="att-proam-button att-proam-submit-button">
                Contact Us
              </button>
            </a>
          </div>
        </div>
      )
    }
    if(verifiedEmail) {
      return (
        <form src="#" onSubmit={this.submitChangeRequest} className="att-proam-registration-email-validation registration-form text-left">
          <div className={`registration-page-one container`} style={{maxWidth: "630px"}}>
            <div className="row" >
              <div className="col-xs-12 att-proam-registration-section-header text-left">
                Alternate Day Request
              </div>
              <div className="col-xs-12 att-proam-registration-section-copy text-left">
                We will be in contact with you soon regarding your request and, if available, next steps in securing your tickets. Please select all applicable date(s) below that fit your availability
              </div>
            </div>
          </div>
          <div className="att-proam-registration-email-validation-fields">
            {this.renderCheckboxes()}
          </div>
          <div className={`registration-page-one container`} style={{maxWidth: "630px"}}>
            <div className="row" >
              <div className="col-xs-12 att-proam-registration-section-copy text-left">
                Please note: by submitting this request for an alternate date, we are releasing the ticket hold for your original date of {this.getDate()}.

                <br/>
                <br/>
                For questions or assistance please contact the <a target="_blank" href="mailto:cxoevents@cisco.com">Cisco Executive Relations Team</a>.
              </div>
            </div>
          </div>
          {error ? (
            <div className="att-proam-registration-email-validation-error">
              {error}
            </div>
          ):""}
          <div className="att-proam-registration-email-validation-submit">
            <br/>
            <br/>
            <button
              type="button"
              onClick={this.submitChangeRequest}
              className="att-proam-button att-proam-submit-button"
            >
              Submit
            </button>
          </div>
        </form>
      );
    }
    return (
      <form src="#" onSubmit={this.verifyEmail} className="att-proam-registration-email-validation registration-form text-left">
        <div className={`registration-page-one container`} style={{maxWidth: "630px"}}>
          <div className="row" >
            <div className="col-xs-12 att-proam-registration-section-header text-left">
              Alternate Day Request
            </div>
            <div className="col-xs-12 att-proam-registration-section-copy text-left">
              Thank you for your interest in attending the 2022 Masters Tournament, we are sorry you will be unable to join us on your chosen date.
              <br/>
              <br/>
              Tickets are limited and we will do our best to accommodate your request. To confirm your response, please provide your email address below.
              <br/>
            </div>
          </div>
        </div>
        <div className="att-proam-registration-email-validation-fields">
          <br/>
          <label>Enter Email Address*</label>
          <input
            value={emailValue}
            onChange={(e) => this.setState({
              emailValue: e.target.value
            })}
            className="form-control"
          />
        </div>
        {error ? (
          <div className="att-proam-registration-email-validation-error">
            {error}
          </div>
        ):""}
        <div className="att-proam-registration-email-validation-submit">
          <br/>
          <br/>
          <button
            type="button"
            onClick={this.verifyEmail}
            className="att-proam-button att-proam-submit-button"
          >
            Continue
          </button>
        </div>
        <div className={`registration-page-one container`} style={{maxWidth: "630px"}}>
          <div className="row" >
            <div className="col-xs-12 att-proam-registration-section-copy text-left">
              <br/>
              <br/>
              If you have any questions, please contact the <a href="mailto:cxoevents@cisco.com">Cisco Executive Relations Team</a>.
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default RegistrationChangeDate;
