import React from "react";


class LandingFaqQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.question.defaultOpen
    }
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  toggleOpen() {
    this.setState({
      open: !this.state.open
    })
  }
  render() {
    const { question } = this.props;
    const { open } = this.state;
    return (
      <div className={`sg-landing-page-faq-question ${open ? 'open' : 'closed'}`}>
        <div onClick={this.toggleOpen} className="sg-landing-page-faq-question-header">
          <b>Question: </b>{question.question}
          <img src="/images/landing/arrow.png" className="sg-landing-page-faq-question-arrow"/>
        </div>
        <div className="sg-landing-page-faq-question-answer">
          <div className="sg-landing-page-faq-question-answer-copy">
            <b>Answer: </b>{question.answer}
          </div>
        </div>
      </div>
    )
  }
}

export default LandingFaqQuestion;
