import React from "react";
import { getKbygSections } from "./KbygHelpers"
import KbygIndexLinks from "./KbygIndexLinks";
class KbygIndex extends React.Component {
  
  renderSections() {
    const { attendee, sessions, fields } = this.props;
    return getKbygSections().map(section => {
      return (
        <div id={`kbyg-${section.key}`} className={`col-xs-12 sg-kbyg-section sg-kbyg-section-${section.key}`} key={"kbyg-section"+section.key}>
          <div className="sg-kbyg-section-title sg-landing-page-section-header">
            {section.title}
            <br/>
          </div>
          <div className={`sg-kbyg-section-content`}>
            {typeof section.render === 'function' ? section.render({
              sessions: sessions,
              attendee: attendee,
              fields: fields
            }) : section.render}
          </div>
        </div>
      )
    })
  }

  render() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <KbygIndexLinks sections={getKbygSections()}/>
        </div>
        {this.renderSections()}
      </div>
    )
  }
}

export default KbygIndex;
