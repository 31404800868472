import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Carousel from "react-multi-carousel";


class LandingPageQuoteCarousel extends React.Component {

  getQuotes(){
    return [
      "Networking with peers is invaluable. Commonality amongst us, regardless of company or field, makes the conversations inclusive and relevant.",
      "What this event does very well is identifies pain points, and brings in people who can look at problems from a completely new perspective.",
      "One of the best CIO events for sure. I got some great things from other CIOs on some of the strategies they use in many different areas."
    ]
  }
  getResponsiveOptions(){
    return {
        mobile: {
        breakpoint: { max: 100000, min: 0 },
        items: 1
      }
    }
  }

  renderQuotes() {
    return this.getQuotes().map(x=>
      (
        <div className="sg-landing-page-quote">
          	&ldquo;{x}&rdquo;
        </div>
      )
    )
  }
  render() {
    return (
      <div className="sg-landing-page-carousel sg-landing-page-quote-carousel">
        <Carousel
          autoPlaySpeed={5000}
          autoPlay={true}
          infinite={true}
          responsive={this.getResponsiveOptions()}
        >
          {this.renderQuotes()}
        </Carousel>
      </div>

    )
  }
}

export default LandingPageQuoteCarousel;
