import React from "react";

class KbygDiningSection extends React.Component {
  render() {
    return (
      <>
        <div className="sg-kbyg-overview-section-block">
          <div className="sg-kbyg-copy font-light" style={{lineHeight:"2em"}}>
            <b>Tuesday, April 25</b>
            <br/>
            6:00 – 8:00 pm | Cocktail Reception and Dinner | Ritz-Carlton Ballroom Terrace
            <br/>
            <br/>
            <b>Wednesday, April 26</b>
            <br/>
            7:00 – 8:30 am | Breakfast | Ritz-Carlton Ballroom Terrace
            <br/>
            12:10 - 1:30 pm | Lunch | Ritz-Carlton Ballroom Terrace
            <br/>
            5:30 – 7:30 pm | Closing Reception and Dinner | L’Edge (on property)
            <br/>
            <br/>
            <b>Thursday, April 27</b>
            <br/>
            7:00 am | Breakfast | State Fare Kitchen or In-room Dining (at leisure)
          </div>
        </div>  


      </>

    )
  }
}

export default KbygDiningSection;
