import React from "react";
import { getKbygSections } from "./KbygHelpers"
class KbygIndexLinks extends React.Component {
  
  renderSections() {
    return getKbygSections().map(section => {
      return (
        <a href={`#kbyg-${section.key}`} data-track-id="logged-in-landing-page-click" data-track-info={`#kbyg-${section.key}-link`}>
          {section.title}
        </a>
      )
    })
  }

  render() {
    return (
      <div className="kbyg-index-section-links">
        {this.renderSections()}

        <a href={`#agenda`} data-track-id="logged-in-landing-page-click" data-track-info={`agenda-link`}>
          Agenda
        </a>
        <a href={`#speakers`} data-track-id="logged-in-landing-page-click" data-track-info={`speakers-link`}>
          Speakers
        </a>
      </div>
    )
  }
}

export default KbygIndexLinks;
