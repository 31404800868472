import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";

class UniversalLinkTracker extends React.Component {
  constructor(props) {
    super(props);

    this.sendTrackingData = this.sendTrackingData.bind(this);
    this.trackClick = this.trackClick.bind(this);
  }

  componentDidMount() {
    $(document).click((e)=>this.trackClick(e));
  }

  trackClick(event) {
    let dataTrackID = $(event.target).attr('data-track-id');
    let dataTrackInfo = $(event.target).attr('data-track-info')
    let url = $(event.target).attr('href');
    let dataTrackTarget = $(event.target).attr('target');
    if($(event.target).closest('a').length > 0) {
      url = $(event.target).closest('a').attr('href');
      dataTrackTarget = $(event.target).closest('a').attr('target');
      console.log({url});
      if(!dataTrackID) {
        dataTrackID = $(event.target).closest('a').attr('data-track-id');
        dataTrackInfo = $(event.target).closest('a').attr('data-track-info')
      }
    }
    console.log({dataTrackID});
    if(dataTrackID) {
      console.log()
      if(url && url.length >= 1) {
        event.preventDefault();
        event.stopPropagation();
        console.log("url detected");
      }
     this.sendTrackingData(
       dataTrackID,
       dataTrackInfo,
       url,
       {
         target: dataTrackTarget
       }
     )
    }
  }


  sendTrackingData(dataTrackID, dataTrackInfo, url, opts={}) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    console.log("sendingData")
    fetch('/track/universal_link_tracker', {
      method: 'POST',
      redirect: "manual",
      body: JSON.stringify(
        {
          id: dataTrackID,
          info: dataTrackInfo
        }
      ),
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token,
      }
    }).then(x=> {
      if(url && url.length > 3) {
        if((opts['target']||"").indexOf("_blank")>-1){
          window.open(url);
        }else {
          window.location = url
        }
      }
    })
  }

  render() {
    return (
      <>

      </>
    );
  }
}

export default UniversalLinkTracker;
