import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import PublicActivityDropdown from "./public_activities/PublicActivityDropdown";
class LandingSessionAgenda extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  renderGolf() {
    return (
      <div className="row sg-landing-page-public-activity">
        <div className="sg-landing-page-public-activity-activity-image col-xs-12 col-sm-4">
          <img src="/images/landing/public_activity/golf.png"/>
        </div>
        <div className="sg-landing-page-public-activity-copy-section col-xs-12 col-sm-8">
          <div className="row">
            <div className="col-xs-12">
              <div className="sg-landing-page-public-activity-copy-header">
                Golf Like a Pro at Pete Dye Challenge Course
              </div>
              Step up to the tee and play alongside Pro Golfers at the legendary Pete Dye Challenge Course, part of Mission Hills Country Club in Palm Springs, golf capital of the world. Experience the tradition and prestige of one of Dye’s most enjoyable layouts, complete with signature elements like railroad ties, pot bunkers, elevated tees, and hidden pin placements.
              <br/>
              <br/>
              <div className="sg-landing-page-public-activity-dropdown-section-copy-header">
                Meet the Pro Golfers Joining You on the Course
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12">
          <PublicActivityDropdown
            click_id={this.props.click_id}
            activity_name="Golf"
          >
            <div className="sg-landing-page-public-activity-copy-section">
            Grab your clubs and get ready to join pro golfers Cameron Young and Brendon Todd for a day on the green at the legendary Mission Hills Country Club – Pete Dye Challenge Course.
              <br/>
              <br/>
              <div className="row">
                <div className="col-xs-12 col-sm-6 sg-landing-page-public-activity-golfer-section sg-landing-page-copy">
                  <img src="/images/landing/public_activity/cameron.png" className="sg-landing-page-public-activity-golfer-headshot"/>
                  <b>Cameron Young</b>
                  <ul>
                    <li>Top 10 Finishes: 7</li>
                    <li>Career High FedEx Cup Rank: 19</li>
                  </ul>
                </div>

                <div className="col-xs-12 col-sm-6 sg-landing-page-public-activity-golfer-section sg-landing-page-copy ">
                  <img src="/images/landing/public_activity/brendon.png" className="sg-landing-page-public-activity-golfer-headshot"/>
                  <b>Brendon Todd </b>
                  <ul>
                    <li>3-time PGA TOUR winner</li>
                    <li>Career High FedEx Cup Rank: 20</li>
                  </ul>
                </div>
              </div>
              <div className="sg-registration-small-copy">
                Participating golfers subject to change.
                <br/>
                <br/>
                <br/>
                <br/>
              </div>
            </div>
          </PublicActivityDropdown>
        </div>
      </div>
    )
  }

  getClickProps(activity_name) {
    const { click_id } = this.props;
    return {
      "data-track-id": click_id ? click_id : "dashboard-click",
      "data-track-info":`Activity: ${activity_name}`,
    }
  }

  renderArchitectureTour() {
    return (
      <div className="row sg-landing-page-public-activity">
        <div className="sg-landing-page-public-activity-activity-image col-xs-12 col-sm-4">
          <img src="/images/landing/public_activity/architecture-tour.png"/>
        </div>
        <div className="sg-landing-page-public-activity-copy-section col-xs-12 col-sm-8">
          <div className="row">
            <div className="col-xs-12">
              <div className="sg-landing-page-public-activity-copy-header">
                Uncover the Stories Behind Palm Springs’ Iconic Architecture
              </div>
              Explore the beauty of Palm Springs’ mid-century modern architecture and design with expert guide and architecture historian, Michael Stern.
              <br/>
              <br/>
              Discover the cultural and geological contexts, as well as the fascinating Hollywood history that have shaped this iconic architectural style. Immerse yourself in the beauty of significant buildings and get a rare chance to tour the interiors of some of the most well-known homes designed by legends like Arthur Elrod or Albert Frey.
              <br/>
              <br/>
              Conclude with lunch in a historic building, Palm Springs’ first U.S. Post Office.
              <br/>
              <br/>
              <div className="sg-landing-page-public-activity-dropdown-section-copy-header">
                Meet Your Expert Guide
              </div>
            </div>
          </div>


        </div>
        <div className="col-xs-12">
          <PublicActivityDropdown
            click_id={this.props.click_id}
            activity_name="Architecture Tour"
          >
            <div className="sg-landing-page-public-activity-copy-section">
              <img src="/images/landing/public_activity/michael.png" className="sg-landing-page-public-landing-page-activity-michael"/>
              <b>Michael Stern</b> is author of the best-selling Rizzoli publication Julius Shulman: Palm Springs, and the Director and Producer of the film Julius Shulman: Desert Modern which has aired on PBS. He curated the exhibition Julius Shulman: Palm Springs, which is the largest exhibition that has ever been produced with a focus on Palm Springs’ astonishing collection of mid-century modern architecture.
              <br/>
              <br/>
              He has lectured extensively, has appeared on “The Today Show” and has made numerous film and television appearances discussing the joys of modernism and the Palm Springs modern style.
              <br/>
              <br/>
              His new book, “Hollywood Modern” explores the relationship between celebrities and works of very high-caliber modern architecture, all done in modern style. He was close friends with several of the notable Palm Springs architects, including E. Stewart Williams, Donald Wexler, William Krisel, and Hugh Kaptur.
              <div className="row">
                <div className="col-xs-5" style={{paddingTop: "20px", paddingBottom: "20px"}}>
                  <img src="/images/landing/public_activity/h1.png" style={{width: "100%", paddingRight:"25px"}}/>
                </div>
                <div className="col-xs-5"  style={{paddingTop: "20px", paddingBottom: "20px"}}>
                  <img src="/images/landing/public_activity/h2.png" style={{width: "100%", paddingRight:"25px"}}/>
                </div>
              </div>

            </div>
          </PublicActivityDropdown>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="sg-landing-page-activity-section-container">
        {this.renderGolf()}
        {this.renderArchitectureTour()}
      </div>
    )
  }
}

export default LandingSessionAgenda;
