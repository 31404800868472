import React from "react";

class KbygOverviewSection extends React.Component {
  render() {
    return (
      <>
        <div className="sg-kbyg-overview-section-block">
          <div className="sg-kbyg-large-copy font-bold">
          Event Location
          </div>
          <div className="sg-kbyg-copy font-light">
            Ritz-Carlton, Rancho Mirage
            <br/>
            68900 Frank Sinatra Drive
            <br/>
            Rancho Mirage, CA 92270
          </div>
        </div>

        <div className="sg-kbyg-overview-section-block">
          <div className="sg-kbyg-large-copy font-bold">
            Event Concierge
          </div>
          <div className="sg-kbyg-copy font-light">
            Upon arrival, a Cisco representative will greet you and escort you to the Event Concierge where you will receive event materials and general information. For your convenience, event staff will be available to assist you during the following times:
            <br/>
            <br/>

            Monday, April 24, 12 pm – 6 pm | Reservation Lounge
            <br/>
            Tuesday, April 25, 7 am - 6 pm | Ritz-Carlton Ballroom
            <br/>
            Wednesday, April 26, 7 am - 5 pm | Ritz-Carlton Ballroom
          </div>
        </div>

        <div className="sg-kbyg-overview-section-block">
          <div className="sg-kbyg-large-copy font-bold">
            Onsite Contact
          </div>
          <div className="sg-kbyg-copy font-light">
            Casey O’Looney, Director, Cisco Global Events
            <br/>
            Mobile: 775-240-8065 | Email: colooney@cisco.com
          </div>
        </div>
      </>

    )
  }
}

export default KbygOverviewSection;
