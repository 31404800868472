import React from "react";
import FormBuilder from "../forms/FormBuilder";
import { subHours, format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
class RegistrationFormWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  callback(json) {
    console.log('reg submit complete');
    console.log(json);
  }

  getReplacableStrings() {
    const { participant } = this.props;
    return [
      {key: '*|EMAIL|*', value: participant.email },
      {key: '*|ticket_date|*', value: (participant.registration_answers.programname||"").replaceAll("-","–") },
      {key: '*|rsvp_date|*', value: this.getRsvpDate() }
    ]
  }

  getRsvpDate() {
    const { participant } = this.props;
    const unformattedRsvpDate = participant.registration_answers.rsvpby;
    if(!unformattedRsvpDate) {
      return "";
    }
    const formattedRsvpDate = format(new Date(unformattedRsvpDate), 'MMMM dd, yyyy')
    return formattedRsvpDate
  }

  render() {
    const { ticket_info, participant, form } = this.props;
    return (
      <FormBuilder
        ticket_info={ticket_info}
        participant={participant}
        form={form}
        gid={form.gid}
        replacableStrings={
          this.getReplacableStrings()
        }
        submitUrl={'/registration'}
        submitCallback={this.callback}
        type={'registration'}
      />
    );
  }
}

export default RegistrationFormWrapper;
