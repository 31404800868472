import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
class RegistrationDecline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      emailValue: "",
      success: false,
      date: null,
    }
    this.declineEmail = this.declineEmail.bind(this);
  }


  declineEmail(e) {
    e.preventDefault()
    const { emailValue } = this.state;
    fetchAPI(`/registration/decline`,(json)=>{
      if(json && json.error == null){
        this.setState({
          success: true,
          date: json.date,
        });
      }else if(json && json.error) {
        this.setState({
          error: json.error
        })
      }else {
        this.setState({
          error: "An error occurred. Please try again in a moment."
        })
      }
    },{
      method: 'POST',
      body: {
        email: emailValue||""
      }
    })
  }


  render() {
    const { emailValue, error, success, date} = this.state;
    if(success) {
      return (
        <div className="container registration-full-container sg-registration-copy">
          <br/>
          Thank you for your response.
          <br/>
          <br/>
          We regret, but understand, that you are unable to join us at Cisco CIO Exchange.
          <br/>
          If your plans change, please  <a className="sg-registration-link" href="mailto:cxoevents@cisco.com">contact us</a>. We would be glad to have you participate.
          <br/>
          <br/>
          Regards,
          <br/>
          Cisco CIO Exchange Team
          <div className="text-center">
            <a target="_blank" href="mailto:cxoevents@cisco.com">
              <button type="button" style={{paddingTop: "10px"}} className="att-proam-button att-proam-submit-button">
                Contact Us
              </button>
            </a>
          </div>
        </div>
      )
    }
    return (
      <form src="#" onSubmit={this.declineEmail} className="att-proam-registration-email-validation registration-form ">
        <div className={`registration-page-one container registration-full-container`}>
          <div className="row" >
            <div className="col-xs-12 att-proam-registration-section-copy text-left">
              Thank you for your response.
              <br/>
              <br/>
              We regret, but understand, that you are unable to join us at Cisco CIO Exchange.
              <br/>
              If your plans change, please  <a className="sg-registration-link" href="mailto:cxoevents@cisco.com">contact us</a>. We would be glad to have you participate.
              <br/>
              <br/>
              To confirm your response, please provide your email address below.
              <br/>
              <br/>
            </div>
          </div>
          <div className="att-proam-registration-email-validation-fields" style={{textAlign: "left"}}>
            <input
              value={emailValue}
              onChange={(e) => this.setState({
                emailValue: e.target.value
              })}
              className="form-control"
              style={{margin: 0}}
            />
          </div>
          {error ? (
            <div className="att-proam-registration-email-validation-error">
              {error}
            </div>
          ):""}
        </div>

        <div className="att-proam-registration-email-validation-submit">
          <br/>
          <br/>
          <button
            type="button"
            onClick={this.declineEmail}
            className="att-proam-button att-proam-submit-button"
          >
            Continue
          </button>
        </div>
        <div className={`registration-page-one container registration-full-container`}>
          <div className="row" >
            <div className="col-xs-12 att-proam-registration-section-copy text-left">
              <br/>
              If you have any questions, please contact the <a className="sg-registration-link" href="mailto:cxoevents@cisco.com">cxoevents@cisco.com</a>.
              <br/>
              <br/>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default RegistrationDecline;
