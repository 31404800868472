import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

class SpeakerModalSandbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalDescription: {description:""},

      speakers: [
        {
          name: "Chuck Robbins",
          title: "Chair and CEO, Cisco",
          url: "/images/landing/speakers/Speaker-Chuck.png",
          miniDescription:"Interactive exchange with Chuck Robbins on his top of mind and the future of Cisco",
          description: `
          Chuck Robbins is the Chair and Chief Executive Officer of Cisco. He assumed the role of CEO on July 26, 2015 and was elected Chair of the Board on December 11, 2017.
          <br/>
          <br/>
          As Chair and CEO, He is focused on helping companies, cities, and countries around the world as they look to Cisco to connect, secure, and automate their organizations for a digital-first world.
          <br/>
          <br/>
          With over 20 years of experience at Cisco, Chuck has served as Senior Vice President of Worldwide Field Operations, where he led the Worldwide Sales and Partner Organizations, and helped drive and execute many of the company’s largest investment areas and strategy shifts; Senior Vice President of The Americas, Cisco’s largest geographic region; Senior Vice President of U.S. Enterprise, Commercial and Canada; Senior Vice President of U.S. Commercial Sales; and Segment Vice President, U.S. and
          Canada channel organization, where he was instrumental in helping build the industry’s most powerful partner program.
          <br/>
          <br/>
          Prior to joining Cisco, Chuck held management positions at Bay Networks and Ascend Communications.
          <br/>
          <br/>
          Mr. Robbins serves on the Board of Directors for BlackRock, the International Business Council of the World Economic Forum, the Business Roundtable, and is on the Board of Trustees for the Ford Foundation. He also serves as a member of The Business Council, the International Council for the Belfer Center for Science and International Affairs at Harvard University and is Chairman Emeritus of the U.S.-Japan Business Council. Other past service includes board membership for the Multiple Sclerosis Society of Northern California and Advisory Board of Georgia Tech.
          <br/>
          <br/>
          Chuck holds a bachelor’s degree in mathematics with a computer science concentration from the University of North Carolina.`,

        },
        {
          name: "Francine Katsoudas",
          title: "EVP and Chief People, Policy & Purpose Officer, Cisco",
          url: "/images/landing/speakers/Speaker-Francine.png",
          miniDescription:"Exploration of how culture can create an environment where employees thrive and companies can use their influence to impact the world for the better",
          description: `
          Francine Katsoudas is Executive Vice President and Chief People, Policy & Purpose Officer of Cisco. In this role, Fran oversees critical functions that instill Cisco’s conscious culture, contribute to the company’s overall performance, and advance Cisco’s purpose to Power an Inclusive Future for All.
          <br/>
          <br/>
          As head of the People, Policy & Purpose Organization, Fran leads an ecosystem comprised of People & Communities, Corporate Affairs, Workplace Resources, and Government Affairs & Country Digital Acceleration. This strategic alignment of functions and expertise ensures holistic care for the well-being of Cisco’s people, establishes Cisco as a trusted and valued partner to governments and global leaders, and extends Cisco’s reach to positively impact communities everywhere in alignment with the company purpose.
          <br/>
          <br/>
          A 25-year veteran of Cisco, Fran has extensive experience leading organizational transformations, driving large scale growth, cultivating successful leaders and teams, and constructing an employee-first culture. Prior to her current role, she served as HR business partner to the Engineering leadership team, and held positions in the Service Provider, HR Operations, Customer Service, Acquisition Integration and Services groups. Prior to Cisco, Fran worked in both the financial and professional services industries with a focus on customer service and operations.
          <br/>
          <br/>
          Fran currently serves on the Board of Directors for Americares, Global Citizen, and ADP.
          <br/>
          <br/>
          Passionate about social justice, Fran is an activist and advocate for a variety of causes close to her heart, particularly women’s leadership, homeless youth, and the Latino community. A graduate of the University of California Berkeley, Fran lives in the Bay Area with her husband and two children.
          `
        },
        {
          name: "Fletcher Previn",
          title: "CIO, Cisco",
          url: "/images/landing/speakers/Speaker-Fletcher.png",
          miniDescription:"CIO panel discussion on the evolution of IT, highlighting what - and who - you need to be prepared",
          description: `
          Fletcher Previn serves as Cisco's Chief Information Officer and is an executive sponsor for Cisco’s work with various US Government agencies. He and his team drive simplification and business transformation to empower Cisco's 140,000 employees to do their best work while determining the systems, infrastructure, and technical capabilities to create secure global IT at scale.
          <br/>
          <br/>
          Fletcher has an established reputation for guiding large organizations through significant shifts. He has extensive experience in driving Agile transformation and modern ways of working, IT modernization, software development, and delivering productivity tools. A systems engineer by training, Fletcher holds Microsoft and Cisco certifications.
          <br/>
          <br/>
          Prior to joining Cisco, Fletcher served as Global Chief Information Officer at IBM, where he led a global team of more than 12,000 IT professionals to provide a secure, global IT at scale for IBM employees worldwide.
          `,

        },
        {
          name: "Ian Bremmer",
          title: "President of Eurasia Group and GZERO Media",
          url: "/images/landing/speakers/Speaker-Ian.png",
          miniDescription:"Fireside chat with Chuck Robbins on global trends and managing risk",
          description: `
          Ian Bremmer is a political scientist who helps business leaders, policy makers, and the general public make sense of the world around them. He is president and founder of Eurasia Group, the world's leading political risk research and consulting firm, and GZERO Media, a company dedicated to providing intelligent and engaging coverage of international affairs. Ian is an independent voice on critical issues around the globe, offering clearheaded insights through speeches, written commentary, and even satirical puppets (really!).
          <br/>
          <br/>
          Ian is credited with bringing the craft of political risk to financial markets, creating Wall Street's first global political risk index (GPRI), and for establishing political risk as an academic discipline. His definition of emerging markets— "those countries where politics matters at least as much as economics for market outcomes”—has become an industry standard. “G-Zero,” his term for a global power vacuum in which no country is willing and able to set the international agenda, is widely used by policymakers and thought leaders.
          <br/>
          <br/>
          A prolific writer, Ian is the author of eleven books, including two New York Times bestsellers, “Us vs Them: The Failure of Globalism” which examines the rise of populism across the world, and his latest book “The Power of Crisis: How Three Threats—and Our Response—Will Change the World” which details a trio of looming global crises (health emergencies, climate change, and technological revolution) and outlines how governments, corporations, and concerned citizens can use these crises to create global prosperity and opportunity.
          <br/>
          <br/>
          He is the host of GZERO World with Ian Bremmer, which airs weekly on US national public television. Ian is also a frequent guest on CNN, Fox News, MSNBC, the BBC, Bloomberg, and many other television stations around the world. He also serves as the foreign affairs columnist and editor at large for Time magazine.
          <br/>
          <br/>
          Ian earned a master's degree and a doctorate in political science from Stanford University, where he went on to become the youngest-ever national fellow at the Hoover Institution. He received his bachelor's degree in international relations from Tulane University. He currentlyteaches at Columbia University's School of International and Public Affairs and previously was a professor at New York University.
          <br/>
          <br/>
          Ian established Eurasia Group with just $25,000 in 1998. Today, the company has offices in New York, Washington, San Francisco, London, Brasilia, Sao Paulo, Singapore, and Tokyo, as well as a network of experts and resources in 90 countries.
          <br/>
          <br/>
          He lives in New York City with his wife Ann and their wily Norfolk Terrier, Moose.

          `,

        },
        {
          name: "Diane C. Swonk ",
          title: "Chief Economist, KPMG",
          url: "/images/landing/speakers/Speaker-Diane.png",
          description: `
            Started her career with money center bank First Chicago and has won many awards in her career for excellence in her profession and leadership in the broader business community. She climbed from entry level to Director of Research and Chief Economist at Bank One, the merged bank. Before joining KPMG, Diane had her own economic consulting firm and worked at Grant Thornton. She spent the prior 10+ years as Senior Managing Director and Chief Economist at the financial services firm, Mesirow Financial.
            <br/>
            <br/>
            She served as an advisor to the Congressional Budget Office (CBO) and the National Economic Council (NEC) on a nonpartisan basis. She regularly briefs the regional Federal Reserve banks and the Board of Governors in Washington, DC. She has provided Congressional testimony on income inequality and how to preserve and bolster the quality of government statistics on the economy. 
            <br/>
            <br/>
            She was honored by her peers as Fellow of the National Association for Business Economics (NABE) for her outstanding contributions to the field. She serves on the NABE statistics committee to advocate for better information on the economy. Diane serves on the board of the Posse Foundation in Chicago, an organization dedicated to increasing access to higher education. She is active in supporting scholarships and programs to diversity the ranks of economists with more women and underrepresented minorities with her alumni groups and work on the NABE Foundation. She is a member of many business groups, including the Economic Advisory Board of the US Chamber of Commerce and the Council on Foreign Relations.
            <br/>
            <br/>
            Diane recently took over the growing economics team at KPMG to serve partners and clients, and to engage with the media to help showcase the firm’s many achievements. Diane was named one of the top 50 most influential economists during the pandemic. She has won numerous awards through the financial press and been recognized for her excellence in forecasting by the regional Federal Reserve banks.
          `,

        },
        // {
        //   name: "Bozoma Saint John",
        //   title: "Former Global Chief Marketing Officer, Netflix",
        //   url: "/images/landing/speakers/Speaker-Bozoma.png",
        //   description: `
        //     Bozoma Saint John is the former Global Chief Marketing Officer at Netflix, the world’s leading streaming entertainment service with 200 million paid memberships in over 190 countries enjoying TV series, documentaries and feature films across a wide variety of genres and languages. Before Netflix, Boz served as Chief Marketing Officer at Endeavor, a global leader in entertainment, sports and fashion. Operating in more than 30 countries, Endeavor is the parent of a number of subsidiaries with leadership positions in their respective industries, including WME, IMG and UFC. Collectively, Endeavor specializes in talent representation and management; brand marketing, sponsorship and licensing; media development, sales and distribution; and event operation, sports training and league development.
        //     <br/>
        //     <br/>
        //     In her role, Boz focused on driving marketing efforts across Endeavor’s growing portfolio, as well as its 160over90 business, which works with premium brands including Anheuser Busch InBev, Marriott, T-Mobile, USAA and Visa.
        //     <br/>
        //     <br/>
        //     Over the course of her career, Boz has earned a formidable reputation as a trailblazing marketing and advertising executive. She most recently served as Chief Brand Officer for Uber, where she was tasked with telling the stories of millions of riders and driver partners across 600+ cities. Prior to Uber, Boz served as Head of Global Consumer Marketing for Apple Music and iTunes, spearheading campaigns geared towards growing consumer brand recognition, loyalty and equity around the world. She stole the show at Apple’s keynote World Wide Developers Conference in 2016, where she introduced the new interface for Apple Music. Following the presentation, Buzzfeed called her the "coolest person to ever go on stage at an Apple event.”
        //     <br/>
        //     <br/>
        //     Prior to Apple, Boz served as Head of the Music and Entertainment Marketing Group at Pepsi-Cola North America, where she drove breakthrough consumer engagement plans for brands across the soda giant’s beverages portfolio by engaging tastemaker-consumers. Before Pepsi, Boz served as VP of Marketing for Ashley Stewart, a women’s fashion brand, as well as managed accounts at advertising agencies Arnold Worldwide and Spike Lee's SpikeDDB.
        //     <br/>
        //     <br/>
        //     Boz has been recognized for her breakthrough work by both the industry and her peers. She has been inducted into Billboard’s Women in Music 2018 Hall of Fame and in 2014, she was inducted into the American Advertising Federation Hall of Achievement, where she currently sits on their Executive Committee. She was named to The Hollywood Reporter’s 2018 Women in Entertainment Power 100 list and 2016’s Executive of the Year by Billboard Magazine; she has been featured in Fortune Magazine’s Disruptors, Innovators & Stars 40 Under 40 feature, Billboard’s Top Executives 40 Under 40 and Power 100 lists, Fast Company’s 100 Most Creative People, Ad Age’s 50 Most Creative People, Ebony Magazine’s 100 Powerful Executives, Black Enterprise’s Most Powerful Women in Business, Fortune Magazine’s 2018 list of Most Influential CMOs, and made the cover of Adweek as one of the most exciting personalities in Advertising. In 2021, Boz was named #1 on Forbes’ World’s Most Influential CMOs list and the Harvard Business School published a case study about her career and leadership; “Bozoma Saint John: Leading with Authenticity and Urgency”.
        //     <br/>
        //     <br/>
        //     Additionally, Boz has been awarded African Health Now’s Inaugural Gift of Life Leadership Award as well as BET’s Leading Women Defined Butterfly Award. In March, 2017, she was named as a Henry Crown Fellow in the Aspen Institute. During the 2020 Spring semester, Professor Frances Frei of Harvard Business School used Saint John’s successful marketing career as a case study in her MBA class, “Leading Difference.” Boz appeared as a guest lecturer for the Harvard MBA students, delving deep into the tactical and pivotal marketing moves she made throughout her career, which she now credits as intrinsic to her success. In May 2020, Saint John launched a podcast alongside award
        //   `,
        //
        // },
        {
          name: "Emmanuel Acho",
          title: <>#1 NYT Best&#8209;selling Author, Two-time Emmy Award Winner</>,
          url: "/images/landing/speakers/Speaker-Emmanuel.png",
          miniDescription:"Intimate conversation about inclusivity in America and how to create a truly inclusive organization",
          description: `
          Emmanuel Acho is a Fox Sports Analyst, the Host of FS1’s SPEAK and Host/ Producer of the Emmy Award-Winning Uncomfortable Conversations with a Black Man, a web series focused on driving an open, difficult, but ultimately productive conversation on race in America. Acho has received two Emmy Awards — Outstanding Sports Personality/Emerging On Air Talent and Outstanding Short Form Nonfiction or Reality Series for Uncomfortable Conversations.
          <br/>
          <br/>
          In partnership with Oprah Winfrey, Acho released the Uncomfortable Conversations with a Black Man book on November 10th, 2020. It is the third title published as “An Oprah Book” and debuted at #3 on the New York Times bestsellers list. His latest book, Uncomfortable Conversations with a Black Boy released in the Spring of 2021. His latest book, Illogical: Saying Yes to a Life Without Limits is now available.
          <br/>
          <br/>
          After earning his undergraduate degree in sports management in 2012, Emmanuel was drafted by the Cleveland Browns. He was then traded to the Philadelphia Eagles in 2013, where he spent most of his career. While in the NFL, Emmanuel spent off-seasons at the University of Texas to earn his master’s degree in Sports Psychology. In 2016, Emmanuel left the football field and picked up the microphone to begin his broadcast career. In 2018, Emmanuel was promoted within ESPN, where he served as the youngest national football analyst and was named a 2018 Forbes 30 Under 30 Selection. In 2017, he and his family’s non-profit organization, Living Hope Christian Ministries, raised enough funds to build a hospital in rural Nigeria.
          `,

        },
        {
          name: "Alex Stamos",
          title: <>Adjunct Professor,<br/>Stanford University,<br/>Former CSO, Facebook,<br/>Former CISO, Yahoo</>,
          url: "/images/landing/speakers/Speaker-Alex.png",
          description: `
            Alex Stamos is a cybersecurity expert, business leader and entrepreneur whose career has focused on improving the security and safety of the Internet. The former Chief Security Officer of Facebook and the former Chief Information Security Officer at Yahoo, he is currently an Adjunct Professor at Stanford University, where his teaching and research focuses on international security and cooperation. During his time at Facebook, Stamos led a threat intelligence team of engineers, researchers, investigators, and analysts charged with understanding and mitigating information security risks to the company and safety risks to the 2.5 billion people on Facebook, Instagram, and WhatsApp. The New York Times called Stamos “a celebrated figure in privacy and security circles,” and Tech Crunch said he is “widely regarded as one of the smartest people working in the security space.” Exclusively represented by Leading Authorities speakers bureau, Stamos helps groups better understand the cyber threat and how to stay one step ahead of bad actors.
            <br/>
            <br/>
            During Stamos’ time at Facebook, he led the company’s investigation into manipulation of the 2016 US election and helped pioneer several successful protections against these new classes of abuse. According to The New York Times, through that work he became known for his role as a “strong advocate inside [Facebook] for investigating and disclosing Russian activity on Facebook.” Alex represented the company to regulators, lawmakers and civil society on six continents, and has served as a bridge between the interests of the Internet policy community and the complicated reality of platforms operating at billion-user scale. In April 2017, he co-authored “Information Operations and Facebook,” a highly cited examination of the influence campaign against the US election, which still stands as the most thorough description of the issue by a major technology company.
            <br/>
            <br/>
            Before joining Facebook, Alex was the Chief Information Security Officer at Yahoo, rebuilding a storied security team while dealing with multiple assaults by nation-state actors. While at Yahoo, he led the company’s response to the Snowden disclosures by implementing massive cryptographic improvements in his first months. He also represented the company in an open hearing of the US Senate’s Permanent Subcommittee on Investigations.
            <br/>
            <br/>
            In 2004, Alex co-founded iSEC Partners, an elite security consultancy known for groundbreaking work in secure software development, embedded and mobile security. As a trusted partner to world’s largest technology firms, Alex coordinated the response to the “Aurora” attacks by the People’s Liberation Army at multiple Silicon Valley firms and led groundbreaking work securing the world’s largest desktop and mobile platforms. During this time, he also served as an expert witness in several notable civil and criminal cases, such as the Google Street View incident and pro bono work for the defendants in Sony vs George Hotz and US vs Aaron Swartz. After the 2010 acquisition of iSEC Partners by NCC Group, Alex formed an experimental R&D division at the combined company, producing five patents.
            <br/>
            <br/>
            Stamos is an Adjunct Professor at Stanford’s Freeman-Spogli Institute, a William J. Perry Fellow at the Center for International Security and Cooperation, and a visiting scholar at the Hoover Institution. He has been involved with securing the US election system as a contributor to Harvard’s Defending Digital Democracy Project and involved in the academic community as an advisor to Stanford’s Cybersecurity Policy Program and UC Berkeley’s Center for Long-Term Cybersecurity. He is a member of the Aspen Institute’s Cyber Security Task Force, the Bay Area CSO Council and the Council on Foreign Relations. Alex also serves on the advisory board to NATO’s Collective Cybersecurity Center of Excellence in Tallinn, Estonia.
          `,

        },
        {
          name: "Zak Brown",
          title: <>CEO, McLaren Racing</>,
          url: "/images/landing/speakers/Speaker-Zak.png",
          description: `
            As Chief Executive of McLaren Racing, Zak Brown has overall responsibility for the business, including strategic direction, operational performance, marketing and commercial development. In his role Zak leads McLaren’s direction and involvement in professional motorsport, spearheaded by the McLaren Formula 1 team. Operating at the pinnacle of global motorsport, McLaren uses the white heat of competition to drive innovation and develop synergies across the group.
            <br/>
            <br/>
            Born and raised in California, Zak raced professionally around the world for 10 years before developing his skills in motorsport’s business and commercial worlds.
            <br/>
            <br/>
            In 1995, he founded JMI, which grew to become the largest and most successful motorsport marketing agency in the world. When JMI was acquired by CSM, a division of Chime Communications, in 2013, Brown became the company’s Chief Executive Officer. He then relinquished that post to embark on his journey at McLaren in the winter of 2016.
            <br/>
            <br/>
            A passionate racer, collector and enthusiast, Brown also sits on the board of Cosworth Engineering and competes and races as a partner and driver in Anglo-American racing team United Autosports.
          `
        },
        {
          name: "Ed Green",
          title: <>Head of Commercial Technology, McLaren Racing</>,
          url: "/images/landing/speakers/Speaker-Ed.png",
          description: `
            Edward Green joined McLaren in March 2018 and leads the Commercial Technology function within the McLaren Racing team.
            <br/>
            <br/>
            He is responsible for the successful integration of the team’s Technology Partners into the McLaren ecosystem and for developing services to support the team's commercial ambitions.
            <br/>
            <br/>
            He has led the design of new infrastructure to support the growth across the McLaren Group, including the Composite Technology Centre, new updated office facilities, and providing thought leadership for new working practices. He is also the technical lead for McLaren’s Esports team, McLaren Shadow.
            <br/>
            <br/>
            Edward works across the Group with executive teams to help set and realise a new vision for the future of McLaren.
          `
        },
      ]
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(speaker) {
    this.setState({
      modalOpen: true,
      modalSpeaker: speaker,

    });
  }

  closeModal() {
    this.setState({
      modalOpen: false,
      modalSpeaker: null,

    });
  }

  renderSpeakers() {
    const { speakers } = this.state;
    const renderedSpeakers = [];
    speakers.forEach(speaker => {
      renderedSpeakers.push(
        <div className="col-xs-12 col-sm-6 sg-landing-page-speaker-card-col">
          <div className=" sg-landing-page-speaker-card" onClick={(e) => this.openModal(speaker)}>
            <div className="sg-landing-page-speaker-image">
              <img src={speaker.url} />
            </div>
            <div className="sg-landing-page-speaker-content">
              <div data-track-id="dashboard-click" data-track-info={`speaker ${speaker.name}`} className="sg-landing-page-speaker-name" onClick={(e) => this.openModal(speaker)}>
                {speaker.name}
              </div>
              <div className="sg-landing-page-speaker-title">
                {speaker.title}
              </div>
            </div>
          </div>
        </div>
      );
    });
    return renderedSpeakers;
  }

  renderModal() {
    const speaker = (this.state.modalSpeaker||{})
    console.log('render modal');
    return (
      <div className="speaker-modal-inner">
        <div className="sg-speaker-modal-header">
          <div className="sg-landing-page-speaker-header-image">
            <img src={speaker.url} />
          </div>
          <div className="sg-landing-page-speaker-header-details">
            <div className="sg-landing-page-speaker-header-details-inner">
              <div className="sg-landing-page-speaker-header-name">
                {speaker.name}
              </div>
              <div className="sg-landing-page-speaker-header-title">
                {speaker.title}
              </div>
            </div>
          </div>
        </div>
        <div className="sg-speaker-modal-divider"/>
        <div className="speaker-modal-inner-description" dangerouslySetInnerHTML={{__html: (speaker.description)}} />
      </div>
    );
  }

  render() {
    const { modalOpen } = this.state;
    return (
      <>
        <div className="sg-landing-page-speakers sg-landing-page-speakers-v2">
          {this.renderSpeakers()}
        </div>

        <Modal
          className="sg-channel-survey-modal white-background disable-scrollbars"
          overlayClassName="sg-channel-survey-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.closeModal}
          contentLabel=""
        >
          <div className="speaker-modal-close-button" onClick={this.closeModal}>
            <div className="speaker-modal-close-button-inner">
              &#10005;
            </div>
          </div>
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default SpeakerModalSandbox;
