import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import Modal from "react-modal";
import { dataURItoBlob } from "@utility/FileUtils";
import Cropper from "react-cropper";

class DropzoneComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropzoneText: "",
      file: '',
      fileDropped: false,
      imagePreviewUrl: this.props.setImage,
      openModal: false,
      cropResult: ''
    };
    this.onDrop = this.onDrop.bind(this);
    this.exitModal = this.exitModal.bind(this);
    this.cropImage = this.cropImage.bind(this);
    this.removeImage = this.removeImage.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement("body");
  }

  formUrl() {
    return "/tiles";
  }

  onDrop(acceptedFiles) {
    if (acceptedFiles.length > 0) {
      let reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onloadend = () => {
        this.setState({
          dropzoneText: "File attached",
          file: acceptedFiles[0],
          fileDropped: true,
          imagePreviewUrl: reader.result,
          openModal: true
        });
      }
    } else {
      this.setState({
        dropzoneText: "This file type is not supported."
      });
    }
  }

  exitModal() {
    this.setState({ openModal: false });
  }

  cropImage() {
    const { name, setFiles } = this.props;
    const { file, tagList, usingTags } = this.state;
    if (typeof this.cropper.getCroppedCanvas() === "undefined") {
      return;
    }
    let dataURL = this.cropper.getCroppedCanvas().toDataURL()
    this.setState({
      cropResult: dataURL,
      openModal: false
    });
    let tags = tagList;
    if (!usingTags) {
      tags = [];
    }
    let blob = dataURItoBlob(dataURL, file.name);
    setFiles(name, blob, dataURL, tags);
  }

  setDropzoneBeforeDrop() {
    const { setImage, profile_image } = this.props;
    const { dropzoneText, imagePreviewUrl } = this.state;
    if (setImage && imagePreviewUrl) {
      return (
        <>
          <img src={imagePreviewUrl} className="drop-section-preview"/>
        </>
      );
    }
    return (
      <>
        <h3 className="dropzone-text">{dropzoneText}</h3>
      </>
    );
  }

  showPreviews() {
    const { editCrop } = this.props;
    const { cropResult, file } = this.state;
    return (
      <>
        <img src={cropResult} className="drop-section-preview"/>
      </>
    );
  }

  editCrop(e) {
    e.cancelBubble = true;
    e.stopPropagation();
    this.onDrop([this.state.file]);
  }

  renderCropper() {
    return (
      <Cropper
        src={this.state.imagePreviewUrl}
        style={{ height: "50vh", width: "100%",maxHeight:"50vh", maxWidth: "90vw", minWidth:"40vw" }}
        aspectRatio={
          this.props.freeRatio ? null : (this.props.forceRatio ? this.props.forceRatio : 16 / 16)
        }
        preview=".img-preview"
        guides={true}
        ref={cropper => {
          this.cropper = cropper;
        }}
        viewMode={1}
        dragMode="move"
        autoCropArea={1}
        cropBoxMovable={false}
      />
    );
  }

  renderModal() {
    return (
        <div className="sg-modal-cropper">
          <h4>Crop Image</h4>
          {this.renderCropper()}
          <div className="clear-div" />
          <button className="cms-button cms-primary-button" onClick={this.cropImage}>Crop</button>
          <span>{" "}</span>
          <button className="cms-button cms-secondary-button" onClick={this.removeImage}>Cancel</button>
          <div className="clear-div" />
        </div>
    );
  }

  removeImage(e) {
    const { setFiles } = this.props;
    e.stopPropagation();
    this.setState({
      dropzoneText: " ",
      file: null,
      fileDropped: false,
      imagePreviewUrl: null,
      openModal: false
    });
    setFiles(null, null, null, null);
  }

  renderChangeRemoveOptions() {
    const { imagePreviewUrl } = this.state;
    if(imagePreviewUrl) {
      return (
        <div className="change-remove-options">
          Change | <div style={{display: "inline-block"}} onClick={this.removeImage}>Remove</div>
        </div>
      )
    }
    return (
      <div className="change-remove-options">
        {this.props.customUploadPhotoText ? this.props.customUploadPhotoText :"Upload Photo"}
      </div>
    )
  }

  render() {
    const { showPreviews, fullWidth } = this.props;
    const { imagePreviewUrl, cropResult, file, fileDropped, dropzoneText, openModal } = this.state;
    return (
      <>
        <Dropzone onDrop={this.onDrop} name="hero_image[image]">
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div {...getRootProps()} className={fullWidth ? "drop-section-tiles-full" : "drop-section-tiles"}>
                <input {...getInputProps()} id="item-form-attachment-dropzone-input"/>
                {fileDropped && showPreviews ? (
                  this.showPreviews()
                ) : (
                  this.setDropzoneBeforeDrop()
                )}
                {this.props.showOptions ? this.renderChangeRemoveOptions() :""}
              </div>
            );
          }}
        </Dropzone>
        <Modal
          className="disable-scrollbars"
          overlayClassName="video-modal-overlay"
          isOpen={openModal}
          contentLabel="View Image"
        >
          {this.renderModal()}
        </Modal>
      </>
    );
   }
}

export default DropzoneComponent;
