import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import { subHours, format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';
import { getKbygSections } from "./kbyg/KbygHelpers"

class LandingSessionAgendaKbyg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  renderSections() {
    const { attendee, sessions, fields } = this.props;
    return getKbygSections().map(section => {
      return (
        <>
          {section.divider ? (
            <div className="col-xs-12">
              <img src={section.divider} className="sg-kbyg-section-divider"/>
            </div>
          ):""}
          <div id={`kbyg-${section.key}`} className={`col-xs-12 col-md-10 sg-kbyg-section sg-kbyg-section-${section.key}`} key={"kbyg-section"+section.key}>

            <div className="sg-kbyg-section-title sg-landing-page-section-header">
              {section.title}
              <br/>
            </div>
            <div className={`sg-kbyg-section-content`}>
              {typeof section.render === 'function' ? section.render({
                sessions: sessions,
                attendee: attendee,
                fields: fields
              }) : section.render}
            </div>
          </div>
        </>
      )
    })
  }


  renderDietaryPreferences() {
    const { attendee } = this.props;
    const {dietarypreference, dietarypreferenceother} = attendee['registration_answers']
    //Gross, overrides for specific behavior
    //None should be falsy.
    let filteredDietaryPreferences = (dietarypreference)
    if(filteredDietaryPreferences == "Other" && dietarypreferenceother) {
      filteredDietaryPreferences = dietarypreferenceother
    }
    return (
      <div className="col-xs-12 sg-landing-page-travel-details-detail-container">
        <div className="sg-landing-page-travel-details-detail-header">
          Dietary Preferences
          {this.renderEditButton()}
        </div>
        <div className="sg-landing-page-travel-details-detail-copy">
          {filteredDietaryPreferences && filteredDietaryPreferences != "None" ? (
            `You have selected ${filteredDietaryPreferences} as your dietary preference. `
          ): (
            "You have not selected any dietary preference."
          )}
        </div>
      </div>
    )
  }


  render() {
    return (
      <>
        {this.renderSections()}
      </>
    )
  }
}

export default LandingSessionAgendaKbyg;
