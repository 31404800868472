import React from "react";

class KbygTravelDetails extends React.Component {
  renderGroundTransportation() {
    const { attendee } = this.props;
    const {
      requiretransportfromhotel,
      requiretransportfromairport,
      arrivaldate,
      arrivalairline,
      arrivalflightnumber,
      arrivalorigin,
      departuredate,
      arrivaltime,
      departuretime,
      departureairline,
      departureflightnumber
    } = attendee['registration_answers']

    if((requiretransportfromhotel && requiretransportfromhotel == 'True' )||(requiretransportfromairport && requiretransportfromairport == 'True')) {
      return (
        <div className="sg-kbyg-overview-section-block sg-kbyg-general-information-block">
          <div className="sg-kbyg-large-copy font-bold">
            Ground Transportation
          </div>
          <div className="sg-kbyg-copy font-light">
            Cisco will provide complimentary ground transportation upon arrival to and departure from Palm Springs International Airport (PSP).
            <br/>
            <br/>
            {!this.isTransportValid() ? (
              <>
              <b>Important:</b> Please contact us as soon as possible at <a href="mailto:cxoevents@cisco.com" target="_blank">cxoevents@cisco.com</a> to provide your arrival and departure dates and flight information. This is required so that we can secure your ground transportation.
              </>
            ) : (
              <>
                You have provided the following flight information:
                {(requiretransportfromairport == "True")? (
                    <>
                      <br/>
                      <br/>
                      Arrival Date: {arrivaldate}
                      <br/>
                      Flight Arrival Time: {arrivaltime}
                      <br/>
                      Flight Origin: {arrivalorigin}
                      <br/>
                      Airline Carrier / FBO Name: {arrivalairline}
                      <br/>
                      Flight Number / Tail Number: {arrivalflightnumber}
                      <br/>
                      <br/>
                      <b>
                        You will be met by a transportation coordinator inside the terminal holding a Cisco placard. The coordinator will escort you to baggage claim to collect luggage, as needed.
                        <br/>
                        <br/>
                        If you do not connect with the coordinator, please call our representative at +1619.843.6015.
                      </b>
                    </>
                ):""}
                {(requiretransportfromhotel == "True")? (
                    <>
                      <br/>
                      <br/>
                      Departure Date: {departuredate}
                      <br/>
                      Flight Departure Time: {departuretime}
                      <br/>
                      Airline Carrier / FBO Name: {departureairline}
                      <br/>
                      Flight Number / Tail Number: {departureflightnumber}
                      <br/>
                      <br/>
                      <b>Exact time to depart the Ritz-Carlton will be provided onsite if Cisco ground transportation has been requested.</b>
                    </>
                ):""}
              </>
            )}
          </div>
        </div>  
      )
    }else {
      return (
        <div className="sg-kbyg-overview-section-block sg-kbyg-general-information-block">
          <div className="sg-kbyg-large-copy font-bold">
            Ground Transportation
          </div>
          <div className="sg-kbyg-copy font-light">
            You elected not to receive complimentary ground transportation to/from the hotel. If you changed your plans, please <a data-track-id="logged-in-landing-page-click" data-track-info="contact-us-link" href="mailto:cxoevents@cisco.com" target="_blank">contact us</a>. We’d be happy to assist you.
            <br/>
            <br/>
            Additionally, there are Taxi service or Uber and Lyft rideshare transportation options from Palm Spring International Airport that take approximately 30 minutes, depending on traffic conditions.
          </div>
        </div>
      )
    }
  }

  isTransportValid() {
    const { attendee } = this.props;
    const {
      requiretransportfromhotel,
      requiretransportfromairport,
    } = attendee['registration_answers']

    let requiredFields = []
    if(requiretransportfromairport == "True") {
      requiredFields = requiredFields.concat(
        [
          'arrivaldate',
          'arrivaltime',
          'arrivalairline',
          'arrivalflightnumber',
          'arrivalorigin',
        ]
      )
    }
    if(requiretransportfromhotel == "True") {
      requiredFields = requiredFields.concat(
        [
          'departuredate',
          'departuretime',
          'departureairline',
          'departureflightnumber'
        ]
      )
    }

    const invalidFields = requiredFields.filter(fieldName =>
      !(attendee['registration_answers'][fieldName])
    )

    return invalidFields.length == 0
  }

  renderDietaryPreference() {
    const { attendee } = this.props;
    const {dietarypreference, dietarypreferenceother} = attendee['registration_answers']
    //Gross, overrides for specific behavior
    //None should be falsy.
    let filteredDietaryPreferences = (dietarypreference)
    if(filteredDietaryPreferences == "Other" && dietarypreferenceother) {
      filteredDietaryPreferences = dietarypreferenceother
    }
    return (
      <div className="sg-kbyg-overview-section-block sg-kbyg-general-information-block">
        <div className="sg-kbyg-large-copy font-bold">
          Dietary Preference
        </div>
        <div className="sg-kbyg-copy font-light">
          {filteredDietaryPreferences && filteredDietaryPreferences != "None" ? (
            `You have selected ${filteredDietaryPreferences} as your dietary preference. `
          ): (
            "You have not selected any dietary preference."
          )}
        </div>
      </div>

    )
  }

  renderHotelAccomodations() {
    const { attendee } = this.props; 
    const { 
      ciscosecuresreservation,
      gcabMember,
      gcaphotelarrangesdoesnotpay,
      hotelcheckindate,
      hotelcheckoutdate,
      hotelspecialrequests
    } = attendee['registration_answers'];


    return (
      <div className="sg-kbyg-overview-section-block sg-kbyg-general-information-block">
        <div className="sg-kbyg-large-copy font-bold">
          Hotel Accommodations
          <br/>
        </div>
        <div className="sg-kbyg-copy font-light row">
          <div className="col-xs-12 col-md-4">
            <img src="/images/landing/kbyg/hotel-picture.png" style={{width:"100%", margin:"auto", maxWidth:"300px"}}/>
          </div>
          <div className="col-xs-12 col-md-8">
            Your hotel reservation details:
            <br/>
            <br/>
            {ciscosecuresreservation == "True" || (gcabMember && gcaphotelarrangesdoesnotpay == "True") ? (
              (
                <>
                  Check In Date:  {hotelcheckindate}
                  <br/>
                  Check Out Date:  {hotelcheckoutdate}
                  {hotelspecialrequests ? (
                    <>
                      <br/>
                      Special Requests: {hotelspecialrequests}
                    </>
                  ):""}
                </>
              )
            ):(
              <>
                Check In Date: Not requested
                <br/>
                Check Out Date: Not requested
              </>
            )}

            {ciscosecuresreservation == "True" || (gcabMember && gcaphotelarrangesdoesnotpay == "True") ? (
              <>
                <br/>
                <br/>
                For more information regarding your hotel reservation, please contact The Ritz-Carlton, Rancho Mirage at (760)&nbsp;321-8282. Or contact us at <a href="mailto:cxoevents@cisco.com" target="_blank">cxoevents@cisco.com</a>, and we can facilitate assistance.
              </>
            ):""}
            
          </div>
        </div>
      </div>

    )
  }

  render() {
    return (
      <>
        {this.renderGroundTransportation()}
        {this.renderHotelAccomodations()}
        {this.renderDietaryPreference()}
      </>
    )
  }
}

export default KbygTravelDetails;
