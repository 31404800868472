import React from "react";
import KbygOverviewSection from "./kbyg_sections/KbygOverviewSection";
import KbygGeneralInformationSection from "./kbyg_sections/KbygGeneralInformationSection";
import KbygDiningSection from "./kbyg_sections/KbygDiningSection";
import KbygPersonalAgendaSection from "./kbyg_sections/KbygPersonalAgendaSection";
import KbygTravelDetails from "./kbyg_sections/KbygTravelDetails"
export function getKbygSections() {
  return [
    {title:"Overview",key:"overview", render: (props)=><KbygOverviewSection {...props}/>},
    {title:"Travel Details",divider:"/images/landing/kbyg/travel-details-divider.png", key:"travel-details", render: (props)=><KbygTravelDetails {...props}/>},
    {title:"Dining & Receptions",divider:"/images/landing/kbyg/dining-reception-divider.png", key:"dining-reception", render: (props)=><KbygDiningSection {...props}/>},
    {title:"General Information",divider:"/images/landing/kbyg/general-information-divider.png", key:"general-information", render: (props)=><KbygGeneralInformationSection {...props}/>},
  ]
}