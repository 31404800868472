import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { subHours, format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';

class ActivitiesSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedDates:[]
    }
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  componentDidMount() {
    this.setState({
      closedDates: this.getDates().filter((x,i)=> i!=0)
    })
  }

  getActivities() {
    const { sessions, attendee } = this.props;
    const activities = sessions.filter(x=>
      x.session_type_name == 'Activity'
    )
    return activities
  }

  registrationAnswerToSlug(fields,id, answer) {
    try {
      const question = fields[id]
      return question.options.find(x=> x.label == answer).slug
    }catch(e) {
      console.log(e);
      return null
    }
  }


  getUserActivities() {
    const { attendee, fields } = this.props;
    if(attendee){
      const activities = this.getActivities();
      const chosenActivity = attendee['registration_answers']['selectedactivity']
      const chosenActivitySlug =this.registrationAnswerToSlug(fields, 'selectedactivity',chosenActivity);
      console.log({chosenActivitySlug})
      return activities.filter(activity=>{
        return (activity.session_tags||[]).filter(tag =>
          [chosenActivitySlug].map(x=>`activity-${x}`).indexOf(tag) != -1
        ).length > 0
      })
    }else {
      return []
    }
  }

  getFilteredSessions() {
    const { sessions, attendee } = this.props;
    let filteredSessions = sessions
    if(attendee) {
      filteredSessions = filteredSessions.filter(x=>x.session_type_name=="Session" || x.session_type_name=="Meal" ||
      (
        x.session_type_name == "Activity" && (x.session_tags||[]).indexOf("Global Activity") > -1
      )
    ).concat(this.getUserActivities())
    }else {
      filteredSessions = filteredSessions
    }
    filteredSessions = filteredSessions.sort((a,b)=>{
      const dateA = new Date(a.date_and_time)
      const dateB = new Date(b.date_and_time)
      return dateB < dateA ? 1 : -1
    })
    return filteredSessions
  }

  getDates() {
    return this.getFilteredSessions().filter(x=>x.date_and_time).map(x=>this.getSessionDate(x)).sort().filter((x,i,t)=>t.indexOf(x) == i)
  }

  renderDates() {
    const dates = this.getDates();
    return dates.map(date=>this.renderDate(date))
  }

  renderDate(date) {
    const sessions = this.getFilteredSessions()
    const currentDateSessions = sessions.filter(session=> this.getSessionDate(session) == date)
    return (
      <div className={`sg-landing-page-session-agenda-date-container ${this.isDateOpen(date) ? "open" : "closed"}`}>
        <div className="sg-landing-page-session-agenda-date-container-header" onClick={(e)=>this.toggleOpen(date)}>
          <img className="sg-landing-page-session-agenda-chevron" src="/images/landing/agenda-chevron.svg"/>
          <div className="sg-landing-page-session-agenda-date-container-header-title">
            {formatToTimeZone(new Date(date), "dddd, MMMM DD", { timeZone: 'UTC' })}
          </div>
        </div>
        <div className="sg-landing-page-session-agenda-date-container-body">
          {currentDateSessions.map(x=>this.renderSession(x))}
        </div>
      </div>
    )
  }

  renderSession(session) {
    return (
      <div className="sg-landing-page-session-agenda-session-container">
        <div className="sg-landing-page-session-agenda-session-date">
          {this.renderDateAndTime(session)}
        </div>
        <div className={`sg-landing-page-session-agenda-session-title`}>
          {session.title} {this.renderOptional(session)}
        </div>
        {(session.speakers||[]).length > 0 ? (
          <div className="sg-landing-page-session-agenda-session-speakers">
            {session.speakers.map(x=>this.renderSpeaker(x))}
          </div>
        ):""}
        <div className="sg-landing-page-session-agenda-session-session-abstract" dangerouslySetInnerHTML={{__html:session.description||""}}>

        </div>
      </div>
    )
  }

  renderOptional(session) {
    const { attendee } = this.props;
    if(!attendee && (session.session_tags||[]).indexOf('public-optional-session') != -1){
      return "(Optional)"
    }
  }
  renderSpeaker(speaker) {
    return (
      <>
        <b>{speaker.name_first} {speaker.name_last}</b>, {speaker.job_title}
        <br/>
      </>
    )
  }

  renderDateAndTime(session) {
    if(session.date_and_time) {
      const start_time = new Date(session.date_and_time_utc)
      const end_time = addMinutes(start_time, session.length_minutes||0)
      if(start_time != end_time && session.length_minutes && session.length_minutes > 0 ) {
        return (
          `${formatToTimeZone(new Date(start_time), "hh:mm aa", { timeZone: 'America/Los_Angeles' })} - ${formatToTimeZone(new Date(end_time), "hh:mm aa", { timeZone: 'America/Los_Angeles' })}`
        )
      }else {
        return `${formatToTimeZone(new Date(start_time), "hh:mm aa", { timeZone: 'America/Los_Angeles' })}`
      }
    }
  }

  toggleOpen(date) {
    const { closedDates } = this.state;
    if(this.isDateOpen(date)){
      this.setState({
        closedDates: closedDates.concat([date])
      })
    }else {
      this.setState({
        closedDates: closedDates.filter(x=>x!= date)
      })
    }
  }

  isDateOpen(date) {
    const { closedDates } = this.state;
    return closedDates.indexOf(date) == -1
  }

  getSessionDate(session) {
    if(session.date_and_time) {
      return formatToTimeZone(new Date(session.date_and_time), "YYYY-MM-DD", { timeZone: 'America/Los_Angeles' })
    }
  }

  render() {
    const { modalOpen } = this.state;
    return this.renderDates()
  }
}

export default ActivitiesSection;
