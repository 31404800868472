import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import { subHours, format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';


class LandingSessionAgenda extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  renderGroundTransportationSection() {
    const { attendee } = this.props;
    const {
      requiretransportfromhotel,
      requiretransportfromairport,
      arrivaldate,
      arrivalairline,
      arrivalflightnumber,
      arrivalorigin,
      departuredate,
      arrivaltime,
      departuretime,
      departureairline,
      departureflightnumber
    } = attendee['registration_answers']
    const isGTValid = this.isGroundTransportationValid()

    if((requiretransportfromhotel && requiretransportfromhotel == 'True' )||(requiretransportfromairport && requiretransportfromairport == 'True')) {
      return (
        <div className="col-xs-12 sg-landing-page-travel-details-detail-container">
          <div className="sg-landing-page-travel-details-detail-header">
            Ground Transportation
            {this.renderEditButton()}
          </div>
          <div className="sg-landing-page-travel-details-detail-copy">
            Cisco will provide complimentary ground transportation upon arrival to and departure from Palm Springs International Airport (PSP).
            {isGTValid && ((requiretransportfromairport == "True") || (requiretransportfromhotel == "True")) ? " You have entered the following information:" : " You will have the opportunity to return to add flight information once your flight is booked."}
            <br/>
            <br/>
            {requiretransportfromhotel == "False" && requiretransportfromairport == "False" ? (
              "You have selected that you don’t require ground transportation."
            ):""}
            {!isGTValid ? (
              <div className="sg-landing-page-travel-details-detail-alert">
                <img src="/images/landing/alert.png"/>
                Please add your flight information
              </div>
            ) : ""}
            {isGTValid && (requiretransportfromairport == "True")? (
                <>
                  Arrival Date: {arrivaldate}
                  <br/>
                  Flight Arrival Time: {arrivaltime}
                  <br/>
                  Flight Origin: {arrivalorigin}
                  <br/>
                  Airline Carrier / FBO Name: {arrivalairline}
                  <br/>
                  Flight Number / Tail Number: {arrivalflightnumber}
                </>
            ):""}
            {isGTValid && (requiretransportfromhotel == "True") && (requiretransportfromairport == "True")? (
              <div style={{ borderTop:"1px solid #eee", marginRight:"30%", marginTop: "10px", paddingBottom:"10px"}}/>
            ):""}
            {isGTValid && (requiretransportfromhotel == "True")? (
                <>
                  Departure Date: {departuredate}
                  <br/>
                  Flight Departure Time: {departuretime}
                  <br/>
                  Airline Carrier / FBO Name: {departureairline}
                  <br/>
                  Flight Number / Tail Number: {departureflightnumber}
                  <br/>
                  <br/>
                  <b>Exact time to depart hotel will be provided onsite.</b>
                </>
            ):""}
          </div>
        </div>
      )
    }else {
      return (
        <div className="col-xs-12 sg-landing-page-travel-details-detail-container">
          <div className="sg-landing-page-travel-details-detail-header">
            Ground Transportation
            {this.renderEditButton()}
          </div>
          <div className="sg-landing-page-travel-details-detail-copy">
            Cisco will provide complimentary ground transportation upon arrival to and departure from Palm Springs International Airport (PSP).

            <br/>
            <br/>
            {requiretransportfromhotel == "False" && requiretransportfromairport == "False" ? (
              <div className="sg-landing-page-travel-details-detail-alert">
                <img src="/images/landing/alert-x.svg"/>
                You have selected that you don’t require ground transportation.
              </div>

            ):""}
          </div>
        </div>
      )
    }
  }

  isGcabMember() {
    const { attendee } = this.props;
    return attendee['registration_answers']['gcabmember'] == "True";
  }

  isGroundTransportationValid(){
    const { attendee } = this.props;
    const {requiretransportfromhotel, requiretransportfromairport} = attendee['registration_answers']
    let fieldList = [
    ]
    if(requiretransportfromhotel == "True") {
      fieldList = fieldList.concat([
        'departuredate',
        'departuretime',
        'departureairline',
        'departureflightnumber'
      ])
    }

    if(requiretransportfromairport == "True") {
      fieldList = fieldList.concat([
        'arrivaldate',
        'arrivaltime',
        'arrivalorigin',
        'arrivalairline',
        'arrivalflightnumber'
      ])
    }
    const validFields = fieldList.filter(field => attendee['registration_answers'][field] && attendee['registration_answers'][field].length > 0);
    if (!(validFields.length == fieldList.length)) {
      return false;
    }
    return true

  }

  renderHotelAccomodations() {
    const { attendee, features } = this.props;
    const {gcaphotelarrangesdoesnotpay, hotelcheckindate, hotelcheckoutdate, hotelspecialrequests, ciscosecuresreservation, dietarypreferenceother} = attendee['registration_answers']

    const gcabMember = this.isGcabMember()
    return (
      <div className="col-xs-12 sg-landing-page-travel-details-detail-container">
        <div className="sg-landing-page-travel-details-detail-header">
          Hotel Accommodations
          {this.renderEditButton()}
        </div>
        <div className="sg-landing-page-travel-details-detail-copy">
          {ciscosecuresreservation == "Not at this time" ? (
            <>
              In the heart of the Palm Springs Valley, the Ritz-Carlton, Rancho Mirage offers nature, history, and luxury. This 4-star, mountain-top resort boasts sweeping views and an elevated experience, providing the perfect backdrop for this exclusive event.
              <br/>
              <br/>
              To assist with your travel plans and hotel accommodations, Cisco CIO Exchange programming will begin Tuesday, April 25 at 5 pm PT, and will conclude Wednesday, April 26 at 8 pm PT. The optional networking activities will begin Tuesday morning.
              {features.shut_down_profile ? "" : (
                "You can access this section later to amend your hotel accommodations as you finalize your travel plans."
              )}
              <br/>
              <br/>
              <div className="sg-landing-page-travel-details-detail-alert">
                <img src="/images/landing/notice.png"/>
                Please let us know if you would like Cisco to secure your hotel reservation
              </div>
            </>
          ):""}
          {ciscosecuresreservation == "False" && !(gcabMember && gcaphotelarrangesdoesnotpay == "True") ? (
            <>
              In the heart of the Palm Springs Valley, the Ritz-Carlton, Rancho Mirage offers nature, history, and luxury. This 4-star, mountain-top resort boasts sweeping views and an elevated experience, providing the perfect backdrop for this exclusive event.
              <br/>
              <br/>
              To assist with your travel plans and hotel accommodations, Cisco CIO Exchange programming will begin Tuesday, April 25 at 5 pm PT, and will conclude Wednesday, April 26 at 8 pm PT. The optional networking activities will begin Tuesday morning.
              {features.shut_down_profile ? "" : (
                "You can access this section later to amend your hotel accommodations as you finalize your travel plans."
              )}
              <br/>
              <br/>
              <div className="sg-landing-page-travel-details-detail-alert">
                <img src="/images/landing/alert-x.svg"/>
                You have selected that you do not require Cisco to assist with your accommodations
              </div>
            </>
          ):""}
          {ciscosecuresreservation == "True" || (gcabMember && gcaphotelarrangesdoesnotpay == "True") ? (
            <div className="">
              Your hotel reservation details:
              <br/>
              <br/>
              Check In Date: {hotelcheckindate}
              <br/>
              Check Out Date: {hotelcheckoutdate}
              {hotelspecialrequests ? (
                <>
                  <br/>
                  Special Requests: {hotelspecialrequests}
                </>
              ):""}
              <br/>
              <br/>
              For information regarding your hotel reservation please contact us at:  <a href="mailto:cxoevents@cisco.com">cxoevents@cisco.com</a> and we can facilitate assistance.
              <br/>
              <br/>
              {gcabMember && ciscosecuresreservation == "True" ? "" : (
                <>
                  <b>Rate:</b> $389.00 + applicable taxes and fees. An additional 13.38% tax charged per night will be levied on all bookings as well as a $20 taxed resort fee per night. Taxes and fees are subject to change. All room charges, including incidentals are the individual's responsibility.
                  <br/>
                  <br/>
                  <b>Room Description:</b> Standard Deluxe Room
                  <br/>
                  <br/>
                  <b>Cancellation Policy:</b> To avoid cancellation fees, please cancel your reservation 72 hours prior to arrival. For uncancelled reservations, the hotel will charge the credit card on file for the room rate and tax for the entire length of stay reserved.
                  <br/>
                  <br/>
                  <b>Deposit Policy:</b> The hotel reserves the right to charge the card on file for one night's deposit one week before arrival. The deposit amount will be applied to the final total upon checkout.
                </>
              )}

            </div>
          ):""}
        </div>
      </div>
    )
  }

  renderEditButton() {
    const { features } = this.props;
    if(features.shut_down_profile) {
      return ""
    }
    return (
      <a href="/registration" className="sg-landing-page-travel-details-header-edit-link">
        <div className="sg-landing-page-travel-details-header-edit-link-signifier"/>
        Edit
      </a>
    )
  }


  renderSelectedActivity() {
    const { attendee } = this.props;
    const activity = this.getUserActivities()
    return (
      <div className="col-xs-12 sg-landing-page-travel-details-detail-container">
        <div className="sg-landing-page-travel-details-detail-header">
          Networking Experience
          {this.renderEditButton()}
        </div>
        {activity && activity.length > 0? (
          activity.map(x=>this.renderActivity(x))
        ):(
          <>
          Select from two exceptional experiences, where you’ll have the opportunity to create fun and meaningful memories alongside your peers and Cisco leaders. Note: these activities are planned for the morning of Tuesday, April 25, 2023.
          <br/>
          <br/>
          <div className="sg-landing-page-travel-details-detail-alert">
            <img src="/images/landing/alert.png"/>
            Please let us know if you would like to participate in an experience.
          </div>
          </>
        )}
      </div>
    )
  }

  renderActivity(activity) {
    return (
      <>
      <div className="">
        You have selected to participate in the following experience.
        <br/>
        <br/>
      </div>
        <div className="sg-landing-page-activity-container">
          <div className="sg-landing-page-activity-image-container">
            <img src={activity.thumbnail_url}/>
          </div>
          <div className="sg-landing-page-activity-copy-container">
            <div className="sg-landing-page-activity-title">
              {activity.title}
            </div>
            <div className="" style={{fontWeight:600, paddingBottom:"10px"}}>
              {this.renderDateAndTime(activity)}
            </div>
            <div className="" dangerouslySetInnerHTML={{__html: activity.description}}/>
          </div>
        </div>
      </>
    )
  }


  getClickProps(activity_name) {
    const { click_id } = this.props;
    return {
      "data-track-id": click_id ? click_id : "dashboard-click",
      "data-track-info":`Activity: ${activity_name}`,
    }
  }

  getActivities() {
    const { sessions, attendee } = this.props;
    const activities = sessions.filter(x=>
      x.session_type_name == 'Activity'
    )
    return activities
  }

  getUserActivities() {
    const { attendee, fields } = this.props;
    const activities = this.getActivities();
    const chosenActivity = attendee['registration_answers']['selectedactivity']
    const chosenActivitySlug =this.registrationAnswerToSlug(fields, 'selectedactivity',chosenActivity);
    console.log({chosenActivitySlug})
    return activities.filter(activity=>{
      return (activity.session_tags||[]).filter(tag =>
        [chosenActivitySlug].map(x=>`activity-${x}`).indexOf(tag) != -1
      ).length > 0
    })
  }

  renderDateAndTime(activity) {
    if(activity.date_and_time) {
      const start_time = new Date(activity.date_and_time_utc)
      const end_time = addMinutes(start_time, activity.length_minutes||0)
      if(start_time != end_time && activity.length_minutes && activity.length_minutes > 0 ) {
        return (
          `${formatToTimeZone(new Date(start_time), "MMM DD | hh:mm aa", { timeZone: 'America/Los_Angeles' })} - ${formatToTimeZone(new Date(end_time), "hh:mm aa", { timeZone: 'America/Los_Angeles' })}`
        )
      }else {
        return `${formatToTimeZone(new Date(start_time), "MMM DD | hh:mm aa", { timeZone: 'America/Los_Angeles' })}`
      }
    }
  }

  registrationAnswerToSlug(fields,id, answer) {
    try {
      const question = fields[id]
      return question.options.find(x=> x.label == answer).slug
    }catch(e) {
      console.log(e);
      return null
    }
  }


  renderDietaryPreferences() {
    const { attendee } = this.props;
    const {dietarypreference, dietarypreferenceother} = attendee['registration_answers']
    //Gross, overrides for specific behavior
    //None should be falsy.
    let filteredDietaryPreferences = (dietarypreference)
    if(filteredDietaryPreferences == "Other" && dietarypreferenceother) {
      filteredDietaryPreferences = dietarypreferenceother
    }
    return (
      <div className="col-xs-12 sg-landing-page-travel-details-detail-container">
        <div className="sg-landing-page-travel-details-detail-header">
          Dietary Preferences
          {this.renderEditButton()}
        </div>
        <div className="sg-landing-page-travel-details-detail-copy">
          {filteredDietaryPreferences && filteredDietaryPreferences != "None" ? (
            `You have selected ${filteredDietaryPreferences} as your dietary preference. `
          ): (
            "You have not selected any dietary preference."
          )}
        </div>
      </div>
    )
  }


  render() {
    return (
      <>
        {this.renderGroundTransportationSection()}
        {this.renderHotelAccomodations()}
        {this.renderDietaryPreferences()}
        {this.renderSelectedActivity()}
      </>
    )
  }
}

export default LandingSessionAgenda;
