import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
import { Formik, Field, Form } from "formik";
import GeneralField from "@layout/forms/GeneralField";
import { getCountrySelectOptions, getStateSelectOptions, isCountryEori} from "../../registration/CountryCodes"
import Select from 'react-select'
import EoriModal from './EoriModal'
class SaveTheDateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      saving: false,
      checkError: false
    }
  }

  renderSelectField(name, formikProps, options, customValidation = false, renderOptions = {}) {
    const { checkError } = this.state;
    const erroring = !renderOptions['not_required'] && checkError && ((formikProps.values.user[name] == null || formikProps.values.user[name].length <= 0) || customValidation);
    let value = null;
    
    try {
       value = options.filter(option => option.value === formikProps.values['user'][name])[0]
    }catch (e) {

    }

      return (
        <Select
          className={erroring ? "registration-select-erroring" : ""}
          options={options}
          classNamePrefix='rsvp-select'
          defaultValue={{value:"Unassigned",label:renderOptions['placeholder']||""}}
          value={options ? value : ""}
          onChange={(option) => {
            const { user } = formikProps.values;
            user[name] = option.value
            formikProps.setFieldValue('user', user)
          }}
          filterOption={this.customFilter}
        />
      )
    }


  submitRegistration(values) {
    if(this.state.saving){
      return;
    }
    this.setState({
      saving: true,
      error: null,
    })
    const { submitCallback } = this.props;
    fetchAPI(`/save_the_date/confirm_post`,(json)=>{
      if(json.error) {
        this.setState({
          error: error,
          saving: false
        })
      }else {
        this.setState({
          saving: false
        })
        submitCallback(json);
      }
    }, {
      method: 'POST',
      body: {
        user: values.user
      }
    })
  }

  getInitialValues() {
    const { attendee } = this.props;
    return {
      user: {
        email: attendee.email,
        name_first: attendee.name_first,
        name_last: attendee.name_last,
        secondaryemail: "",
        company: "",
        job_title:"",
        work_phone: "",
        assistantnamefirst:"",
        assistantnamelast:"",
        assistantemail:"",
        assistantphone:"",
        work_address_street_1:"",
        work_address_street_2:"",
        work_address_country:"",
        work_address_state:"",
        work_address_city:"",
        work_address_postcode:"",
        eorinumber:"",
      }
    }
  }

  renderHeader() {
    return (
      <div className="row" >
        <div className="col-xs-12 sg-rsvp-copy-title">
          Cisco is pleased that you and your guest will be joining us at the Paris 2024 Olympics. Please take a moment to provide us with some additional information.
          <br/>
          <br/>
        </div>
      </div>
    )
  }
  renderForm(formikProps) {
    const { checkError } = this.state;
    return (
      <>
        {this.renderHeader()}
        <div className="row">
          <div className="col-xs-12 sg-rsvp-copy-subtitle">
            Your Information
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label required">
              First Name
            </div>
            <GeneralField
              name="name_first"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
            />
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label required">
              Last Name
            </div>
            <GeneralField
              name="name_last"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
            />
          </div>


          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label required">
              Email
            </div>
            <GeneralField
              name="email"
              disabled={true}
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
            />
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label">
              Secondary Email
            </div>
            <GeneralField
              name="secondaryemail"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
              optional={true}
            />
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label required">
              Company Name
            </div>
            <GeneralField
              name="company"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
            />
          </div>
          
          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label required">
             Job Title
            </div>
            <GeneralField
              name="job_title"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
            />
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label required">
              Mobile Telephone, including area and country code
            </div>
            <GeneralField
              name="work_phone"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 sg-rsvp-copy-subtitle">
            <br/>
            <br/>
            Your Mailing Address
            <br/>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label required">
              Address 1
            </div>
            <GeneralField
              name="work_address_street_1"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
            />
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label">
              Address 2 (optional)
            </div>
            <GeneralField
              name="work_address_street_2"
              objectName="user"
              checkError={false}
              formikProps={formikProps}
            />
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label required">
              Country 
            </div>
            {this.renderSelectField('work_address_country',formikProps, getCountrySelectOptions())}
          </div>
          {formikProps.values.user.work_address_country == "United States" ? (
            <div className="col-xs-12 col-md-6">
              <div className="sg-rsvp-copy-field-label required">
                State
              </div>
              {this.renderSelectField('work_address_state',formikProps, getStateSelectOptions())}
            </div>
          ):(
            <div className="col-xs-12 col-md-6">
              <div className="sg-rsvp-copy-field-label">
                State / Province
              </div>
              <GeneralField
                name="work_address_state"
                objectName="user"
                checkError={false}
                formikProps={formikProps}
              />
            </div>
          )}


          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label required">
              City
            </div>
            <GeneralField
              name="work_address_city"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
            />
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label required">
              Postal Code
            </div>
            <GeneralField
              name="work_address_postcode"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
            />
          </div>
          {isCountryEori(formikProps.values.user.work_address_country) ? (
            <div className="col-xs-12 col-md-6">
              <div className="sg-rsvp-copy-field-label required">
                EORI Number <EoriModal />
              </div>
              <GeneralField
                name="eorinumber"
                objectName="user"
                checkError={checkError}
                formikProps={formikProps}
              />
            </div>
          ):""}
        </div>

        <div className="row">
          <div className="col-xs-12 sg-rsvp-copy-subtitle">
            <br/>
            <br/>
            Assistant Information
            <br/>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label">
              First Name
            </div>
            <GeneralField
              name="assistantnamefirst"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
              optional={true}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label">
              Last Name
            </div>
            <GeneralField
              name="assistantnamelast"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
              optional={true}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label">
              Email
            </div>
            <GeneralField
              name="assistantemail"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
              optional={true}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label">
              Mobile Telephone, including area and country code
            </div>
            <GeneralField
              name="assistantphone"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
              optional={true}
            />
          </div>
          <div className="col-xs-12 sg-rsvp-copy">
            <br/>
            Cisco is committed to maintaining strong protections for our customers, products and company. We believe in building and maintaining trust, reducing risk and simply doing what is right. For more information on how Cisco collects and uses personal information, please see
            {" "}<a href="http://www.cisco.com/web/siteassets/legal/privacy_full.html#" target="_blank">
              Cisco’s Privacy Statement
            </a>.
          </div>
        </div>
      </>
    )
  }

  renderError(formikProps) {
    const { checkError } = this.state;
    if (checkError && !this.isPageOneValid(formikProps)) {
      return (
        <div className="row">
          <div className="col-xs-12 sg-rsvp-copy" style={{color: "red"}}>
            <br/>
            <br/>
            Please ensure all required fields are filled out.
          </div>
        </div>
      )
    }
  }

  renderButtons(formikProps) {
    return (
      <div className="row">
        <div className="col-xs-12 sg-rsvp-copy-submi t">
          <br/>
          <button onClick={(e)=>this.attemptSubmit(formikProps)} className="sg-button sg-submit-button">
            Submit
          </button>
        </div>
      </div>
    )
  }

  attemptSubmit(formikProps) {
    if(this.isPageOneValid(formikProps)) {
      formikProps.submitForm();
    }else {
      this.setState({
        checkError: true,
      })
    }
  }
  isPageOneValid(formikProps) {
    //TODO
    const { fields } = this.props;
    const { values } = formikProps;
    let fieldList = [
      'email',
      'name_first',
      'name_last',
      'job_title',
      'company',
      'work_phone',
      'work_address_street_1',
      'work_address_country',
      'work_address_city',
      'work_address_postcode',
    ];

    if(isCountryEori(formikProps.values.user.work_address_country)){
      fieldList.push('eorinumber');
    }
    const validFields = fieldList.filter(field => values["user"][field] && values["user"][field].length > 0);
    console.log({
      validFields, 
      fieldList
    })
    if (!(validFields.length == fieldList.length)) {
      return false;
    }
    return true
  }

  render() {
    return (
      <Formik
        initialValues={this.getInitialValues()}
        onSubmit={(values, actions) => {
          this.submitRegistration(values)
        }}
        render={(formikProps) => (
          <Form
            ref={this.formikRef}
            onSubmit={e => { e.preventDefault(); e.stopPropagation(); }}
            className="sg-rsvp-form"
          >
            {this.renderForm(formikProps)}
            {this.renderError(formikProps)}
            {this.renderButtons(formikProps)}
          </Form>
      )}/>
    )

  }
}

export default SaveTheDateForm;
