import React from "react";

export function getCountrySelectOptions() {
  return getCountryInfo().map(country => {return {label: country.name, value: country.name}});
}
export function getRegionSelectOptions() {
  return ['APAC', 'EMEA', 'India', 'Japan', 'North America'].map(x=>{return {value: x, label: x}});
}

export function theaterOptions(r) {
  return ['APJC', 'Canada',"EMEA", 'LatAm', 'US'].map(x=>{return {value: x, label: x}});
}
export function getApparelSizeOptions() {
  return [
    'Men’s S',
    'Men’s M',
    'Men’s L',
    'Men’s XL',
    'Men’s XXL',
    'Women’s XS',
    'Women’s S',
    'Women’s M',
    'Women’s L',
    'Women’s XL',
    'Women’s XXL'
  ].map(x=>{return {value: x, label: x}});
}

export function sessionTopicsOptions() {
  return [
    "Strategy & FY23 priorities",
    "Giveback activity",
    "How we execute globally against priorities",
    "Wellness",
    "Big rocks problem-solving",
    "Industry trends & perspective",
    "Career development",
    "Team building",
    "Inclusion & collaboration",
    "Leader AMA",
    "Other"
  ].map(x=>{return {value: x, label: x}});
}

export function departureDates() {
  return [
    "Wednesday, April 26",
    "Thursday, April 27"
  ].map(x=>{return{value:x, label: x}})
}

export function activitiesOptionsV2() {
  return [
    {id: 'golf',value:"Golf at Mission Hills Country Club",title:"Golf at Mission Hills Country Club", name: 'Golf', desc: (
      <>
      Join your peers for 18-holes at one of Palm Springs’ legendary courses, Mission Hills Country Club – Pete Dye Challenge Course. Exemplifying tradition and prestige, this course is one of Dye’s most enjoyable layouts and boasts all of his trademarks, including the use of railroad ties, pot bunkers, elevated tees, and hidden pin placements.
        <br/>
        <br/>
      Transportation departs the Ritz-Carlton Rancho Mirage at 7 am on Tuesday, April 25, returning to the hotel by 3 pm.
      </>
    )},
    //{name: 'Hiking', desc: "Embark on a morning hike on beautifully lined trails with picturesque views"},
    {id: 'architecturetour',title:"Modern Architecture Tour and Lunch", value: "Modern Architecture Tour and Lunch", name: 'Modern Architecture Tour and Lunch', desc: (
      <>
        Explore Palm Springs’ world-famous, mid-century modern architecture and design, led by architecture historian Michael Stern. This interactive tour celebrates the cultural and geological contexts as well as the fascinating Hollywood history that made Palm Springs’ architecture so rich and exciting. Conclude with dining in a historic building, Palm Springs’ first U.S. Post Office. 
        <br/>
        <br/>
        Transportation departs the Ritz-Carlton Ranch Mirage at 9 am on Tuesday, April 25, returning to the hotel by 3 pm.
      </>
    )},
  ];
}

export function activitiesOptions() {
  return [
    {id: 'golf', name: 'Golf at Mission Hills Country Club', desc: "See why Palm Springs is a golfer’s paradise and known as the golf capital of the world. Join your colleagues for 18-holes at one of Palm Springs’ legendary courses."},
    //{name: 'Hiking', desc: "Embark on a morning hike on beautifully lined trails with picturesque views"},
    {id: 'architecturetour', name: 'Architecture Tour', desc: "Experience Palm Springs’ world-famous mid-century modern architecture. Join a local architecture expert for a fascinating exploration of the city’s unique collection of modernist homes and buildings. Learn about a remarkable group of architects, builders, and celebrity clients who add life and awe to Palm Springs’ modernist legacy."},
    {id: 'cooking', name: 'Culinary Arts Experience', desc: "Indulge in a world-class culinary experience. Participate in a hands-on professional cooking techniques and food presentation experience hosted by a local culinary expert."},
    {id: 'undecided', name: 'Undecided at this time', desc: ""},
  ].map(x=>{return {value: x.name,id:x.id, label: x.name, desc: x.desc}});
}


export function travelMethods() {
  return [
    'Airplane',
    'Car',
    'Train'
  ].map(x=>{return {value: x, label: x}});
}

export function sessionFormatOptions() {
  return [
    "External guest speaker(s)",
    "Unstructured networking",
    "Internal (Cisco) guest speaker(s)",
    "Small-group creative activity",
    "Small group conversations on different topics",
    "Physical wellness activity",
    "Structured networking",
    "Off-site team building",
    "Other"
  ].map(x=>{return {value: x, label: x}});
}

export function arrivalDates() {
  return ['Monday, April 24', ' Tuesday, April 25','Wednesday, April 26'].map(x=>{return {value: x, label: x}});
}

export function approvedAirports() {
  return ['Airport Option 1'].map(x=>{return {value: x, label: x}});
}
export function checkinDates() {
  return [
    "Sunday, April 23, 2023",
    "Monday, April 24, 2023",
    "Tuesday, April 25, 2023",
    "Wednesday, April 26, 2023",
    "Thursday, April 27, 2023",
  ].map(x=>{return {value: x, label: x}});
}

export function employeeCheckinDates() {
  return [
    "Monday, April 24, 2023",
    "Tuesday, April 25, 2023",
    "Wednesday, April 26, 2023",
  ].map(x=>{return {value: x, label: x}});
}
export function checkoutDates() {
  return [
    "Wednesday, April 26, 2023",
    "Thursday, April 27, 2023",
  ].map(x=>{return {value: x, label: x}});
}

export function seatPreferences() {
  return [
    'Aisle',
    'Middle',
    'Window'
  ].map(x=>{return {value: x, label: x}});
}

export function dietaryRestrictions(fields) {
  const filteredSlugs = ['foodallergies']
  try {
    const question = fields['dietarypreference']
    return [].concat(question.options.filter(x=>
      x.slug != 'unassigned'
    ).filter(x=>
      filteredSlugs.indexOf(x.slug) == -1
    ).map(x=>
      {return {value: x.label, label: x.label}}
    ));
  }catch(e) {
    return []
  }
}

export function preferredRoomType(fields) {
  try {
    const question = fields['preferredroomtype']
    return question.options.filter(x=>
      x.slug != 'unassigned'
    ).map(x=>
      {return {value: x.label, label: x.label}}
    );
  }catch(e) {
    return []
  }
}

export function activities(fields) {
  try {
    const question = fields['activities']
    return question.options.filter(x=>
      x.slug != 'unassigned'
    ).map(x=>
      {return {value: x.slug, label: x.label}}
    );
  }catch(e) {
    return []
  }
}

export function travelInformation(fields) {
  try {
    const question = fields['travelplan']
    return question.options.filter(x=>
      x.slug != 'unassigned'
    ).map(x=>
      {return {value: x.label, label: x.label}}
    );
  }catch(e) {
    return []
  }
}


export function specialAccomodations(fields) {
  try {
    const question = fields['specialaccomodations']
    return [

    ].concat(question.options.filter(x=>
      x.slug != 'unassigned'
    ).map(x=>
      {return {value: x.label, label: x.label}}
    ));
  }catch(e) {
    return []
  }
}
export function getJobTypes() {
  return [
    "Business Development",
    "Customer Success Engineer (CSE)",
    "Customer Success Manager (CSM)",
    "Customer Support",
    "Education/Training",
    "Engineering",
    "Enterprise AE",
    "Enterprise SE",
    "Executive Team",
    "Facilities",
    "Finance",
    "Human Resources",
    "Invited Guest",
    "Legal",
    "Marketing",
    "Mid-Market AE",
    "Mid-Market SE",
    "Partner",
    "Product Management",
    "Professional Services",
    "Sales Development Reps (SDR’s)",
    "Sales Leadership",
    "Sales Operations",
    "SpecOps",
    "Technical Account Manager (TAM)",
    "Technical Onboarding Specialist",
  ].map(x=>{return {value: x, label: x}});
}

export function getCountryInfo() {
  return [
    {name: 'United States', code: 'US'},
    {name: 'Afghanistan', code: 'AF'},
    {name: 'Albania', code: 'AL'},
    {name: 'Algeria', code: 'DZ'},
    {name: 'American Samoa', code: 'AS'},
    {name: 'Andorra', code: 'AD'},
    {name: 'Angola', code: 'AO'},
    {name: 'Anguilla', code: 'AI'},
    {name: 'Antigua and Barbuda', code: 'AG'},
    {name: 'Argentina', code: 'AR'},
    {name: 'Armenia', code: 'AM'},
    {name: 'Aruba', code: 'AW'},
    {name: 'Australia', code: 'AU'},
    {name: 'Austria', code: 'AT'},
    {name: 'Azerbaijan', code: 'AZ'},
    {name: 'Bahamas', code: 'BS'},
    {name: 'Bahrain', code: 'BH'},
    {name: 'Bangladesh', code: 'BD'},
    {name: 'Barbados', code: 'BB'},
    {name: 'Belgium', code: 'BE'},
    {name: 'Belize', code: 'BZ'},
    {name: 'Benin', code: 'BJ'},
    {name: 'Bermuda', code: 'BM'},
    {name: 'Bhutan', code: 'BT'},
    {name: 'Bolivia', code: 'BO'},
    {name: 'Bosnia and Herzegovina', code: 'BA'},
    {name: 'Botswana', code: 'BW'},
    {name: 'Brazil', code: 'BR'},
    {name: 'Brunei Darussalam', code: 'BN'},
    {name: 'Bulgaria', code: 'BG'},
    {name: 'Burkina Faso', code: 'BF'},
    {name: 'Burundi', code: 'BI'},
    {name: 'Cambodia', code: 'KH'},
    {name: 'Cameroon', code: 'CM'},
    {name: 'Canada', code: 'CA'},
    {name: 'Cape Verde', code: 'CV'},
    {name: 'Cayman Islands', code: 'KY'},
    {name: 'Central African Republic', code: 'CF'},
    {name: 'Chad', code: 'TD'},
    {name: 'Chile', code: 'CL'},
    {name: 'China', code: 'CN'},
    {name: 'Christmas Island', code: 'CX'},
    {name: 'Cocos (Keeling) Islands', code: 'CC'},
    {name: 'Colombia', code: 'CO'},
    {name: 'Comoros', code: 'KM'},
    {name: 'Congo', code: 'CG'},
    {name: 'Cook Islands', code: 'CK'},
    {name: 'Costa Rica', code: 'CR'},
    {name: 'Croatia', code: 'HR'},
    {name: 'Cuba', code: 'CU'},
    {name: 'Cyprus', code: 'CY'},
    {name: 'Czech Republic', code: 'CZ'},
    {name: 'Denmark', code: 'DK'},
    {name: 'Djibouti', code: 'DJ'},
    {name: 'Dominica', code: 'DM'},
    {name: 'Dominican Republic', code: 'DO'},
    {name: 'Ecuador', code: 'EC'},
    {name: 'Egypt', code: 'EG'},
    {name: 'El Salvador', code: 'SV'},
    {name: 'Equatorial Guinea', code: 'GQ'},
    {name: 'Eritrea', code: 'ER'},
    {name: 'Estonia', code: 'EE'},
    {name: 'Ethiopia', code: 'ET'},
    {name: 'Falkland Islands (Malvinas)', code: 'FK'},
    {name: 'Faroe Islands', code: 'FO'},
    {name: 'Fiji', code: 'FJ'},
    {name: 'Finland', code: 'FI'},
    {name: 'France', code: 'FR'},
    {name: 'French Guiana', code: 'GF'},
    {name: 'French Polynesia', code: 'PF'},
    {name: 'Gabon', code: 'GA'},
    {name: 'Gambia', code: 'GM'},
    {name: 'Georgia (Europe)', code: 'GE'},
    {name: 'Germany', code: 'DE'},
    {name: 'Ghana', code: 'GH'},
    {name: 'Gibraltar', code: 'GI'},
    {name: 'Greece', code: 'GR'},
    {name: 'Greenland', code: 'GL'},
    {name: 'Grenada', code: 'GD'},
    {name: 'Guadeloupe', code: 'GP'},
    {name: 'Guam', code: 'GU'},
    {name: 'Guatemala', code: 'GT'},
    {name: 'Guinea', code: 'GN'},
    {name: 'Guinea-Bissau', code: 'GW'},
    {name: 'Guyana', code: 'GY'},
    {name: 'Haiti', code: 'HT'},
    {name: 'Heard Island and McDonald Islands', code: 'HM'},
    {name: 'Holy See (Vatican City State)', code: 'VA'},
    {name: 'Honduras', code: 'HN'},
    {name: 'Hong Kong', code: 'HK'},
    {name: 'Hungary', code: 'HU'},
    {name: 'Iceland', code: 'IS'},
    {name: 'India', code: 'IN'},
    {name: 'Indonesia', code: 'ID'},
    {name: 'Iran (Islamic Republic Of)', code: 'IR'},
    {name: 'Iraq', code: 'IQ'},
    {name: 'Ireland', code: 'IE'},
    {name: 'Isle of Man', code: 'IM'},
    {name: 'Israel', code: 'IL'},
    {name: 'Italy', code: 'IT'},
    {name: 'Jamaica', code: 'JM'},
    {name: 'Japan', code: 'JP'},
    {name: 'Jordan', code: 'JO'},
    {name: 'Kazakhstan', code: 'KZ'},
    {name: 'Kenya', code: 'KE'},
    {name: 'Kiribati', code: 'KI'},
    {name: 'Kuwait', code: 'KW'},
    {name: 'Kyrgyzstan', code: 'KG'},
    {name: 'Latvia', code: 'LV'},
    {name: 'Lebanon', code: 'LB'},
    {name: 'Lesotho', code: 'LS'},
    {name: 'Liberia', code: 'LR'},
    {name: 'Liechtenstein', code: 'LI'},
    {name: 'Lithuania', code: 'LT'},
    {name: 'Luxembourg', code: 'LU'},
    {name: 'Macedonia', code: 'MK'},
    {name: 'Madagascar', code: 'MG'},
    {name: 'Malawi', code: 'MW'},
    {name: 'Malaysia', code: 'MY'},
    {name: 'Maldives', code: 'MV'},
    {name: 'Mali', code: 'ML'},
    {name: 'Malta', code: 'MT'},
    {name: 'Marshall Islands', code: 'MH'},
    {name: 'Martinique', code: 'MQ'},
    {name: 'Mauritania', code: 'MR'},
    {name: 'Mauritius', code: 'MU'},
    {name: 'Mexico', code: 'MX'},
    {name: 'Micronesia (Federated States of)', code: 'FM'},
    {name: 'Moldova', code: 'MD'},
    {name: 'Monaco', code: 'MC'},
    {name: 'Mongolia', code: 'MN'},
    {name: 'Montenegro', code: 'ME'},
    {name: 'Montserrat', code: 'MS'},
    {name: 'Morocco', code: 'MA'},
    {name: 'Mozambique', code: 'MZ'},
    {name: 'Myanmar', code: 'MM'},
    {name: 'Namibia', code: 'NA'},
    {name: 'Nauru', code: 'NR'},
    {name: 'Nepal', code: 'NP'},
    {name: 'Netherlands', code: 'NL'},
    {name: 'Netherlands Antilles', code: 'AN'},
    {name: 'New Caledonia', code: 'NC'},
    {name: 'New Zealand', code: 'NZ'},
    {name: 'Nicaragua', code: 'NI'},
    {name: 'Niger', code: 'NE'},
    {name: 'Nigeria', code: 'NG'},
    {name: 'Niue', code: 'NU'},
    {name: 'Norfolk Island', code: 'NF'},
    {name: 'Northern Mariana Islands', code: 'MP'},
    {name: 'Norway', code: 'NO'},
    {name: 'Oman', code: 'OM'},
    {name: 'Pakistan', code: 'PK'},
    {name: 'Palau', code: 'PW'},
    {name: 'Panama', code: 'PA'},
    {name: 'Papua New Guinea', code: 'PG'},
    {name: 'Paraguay', code: 'PY'},
    {name: 'Peru', code: 'PE'},
    {name: 'Philippines', code: 'PH'},
    {name: 'Poland', code: 'PL'},
    {name: 'Portugal', code: 'PT'},
    {name: 'Puerto Rico', code: 'PR'},
    {name: 'Qatar', code: 'QA'},
    {name: 'Romania', code: 'RO'},
    {name: 'Rwanda', code: 'RW'},
    {name: 'Saint Lucia', code: 'LC'},
    {name: 'Saint Vincent and the Grenadines', code: 'VC'},
    {name: 'Samoa', code: 'WS'},
    {name: 'San Marino', code: 'SM'},
    {name: 'Sao Tome and Principe', code: 'ST'},
    {name: 'Saudi Arabia', code: 'SA'},
    {name: 'Senegal', code: 'SN'},
    {name: 'Serbia', code: 'CS'},
    {name: 'Seychelles', code: 'SC'},
    {name: 'Sierra Leone', code: 'SL'},
    {name: 'Singapore', code: 'SG'},
    {name: 'Slovakia', code: 'SK'},
    {name: 'Slovenia', code: 'SI'},
    {name: 'Solomon Islands', code: 'SB'},
    {name: 'Somalia', code: 'SO'},
    {name: 'South Africa', code: 'ZA'},
    {name: 'South Korea', code: 'SOK'},
    {name: 'Spain', code: 'ES'},
    {name: 'Sri Lanka', code: 'LK'},
    {name: 'Sudan', code: 'SD'},
    {name: 'Suriname', code: 'SR'},
    {name: 'Swaziland', code: 'SZ'},
    {name: 'Sweden', code: 'SE'},
    {name: 'Switzerland', code: 'CH'},
    {name: 'Syrian Arab Republic', code: 'SY'},
    {name: 'Taiwan', code: 'TW'},
    {name: 'Tajikistan', code: 'TJ'},
    {name: 'Thailand', code: 'TH'},
    {name: 'Togo', code: 'TG'},
    {name: 'Tonga', code: 'TO'},
    {name: 'Trinidad and Tobago', code: 'TT'},
    {name: 'Tunisia', code: 'TN'},
    {name: 'Turkey', code: 'TR'},
    {name: 'Turkmenistan', code: 'TM'},
    {name: 'Tuvalu', code: 'TV'},
    {name: 'Uganda', code: 'UG'},
    {name: 'Ukraine', code: 'UA'},
    {name: 'United Arab Emirates', code: 'AE'},
    {name: 'United Kingdom', code: 'GB'},

    {name: 'United Rep. of Tanzania', code: 'TZ'},
    {name: 'Uruguay', code: 'UY'},
    {name: 'Uzbekistan', code: 'UZ'},
    {name: 'Vanuatu', code: 'VU'},
    {name: 'Venezuela', code: 'VE'},
    {name: 'Vietnam', code: 'VN'},
    {name: 'Virgin Islands British', code: 'VG'},
    {name: 'Virgin Islands U.S.', code: 'VI'},
    {name: 'Western Sahara', code: 'EH'},
    {name: 'Yemen', code: 'YE'},
    {name: 'Zambia', code: 'ZM'},
    {name: 'Zimbabwe', code: 'ZW'}
  ]
}

export function isCountryEori(country) {
  const eori_countries = [
    "Austria",
    "Belgium",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czechia",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hungary",
    "Ireland",
    "Italy",
    "Latvia",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Netherlands",
    "Northern Ireland",
    "Norway",
    "Poland",
    "Portugal",
    "Romania",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
    "United Kingdom"
  ]
  return eori_countries.indexOf(country) != -1
}
export function getStateSelectOptions() {
  return getStateInfo().map(state => {return {label: state.name, value: state.name}});
}

export function getStateInfo()
{
  return [
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "American Samoa",
        "abbreviation": "AS"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Federated States Of Micronesia",
        "abbreviation": "FM"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Guam",
        "abbreviation": "GU"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Marshall Islands",
        "abbreviation": "MH"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
      "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Palau",
        "abbreviation": "PW"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
  ]
}


export function getTimezoneOptions() {
  return getTimezoneInfo().map(timezone => {return {label: timezone.text, value: timezone.value}});
}

export function getTimezoneInfo()
{
  return (
    [
      {"value":"Dateline Standard Time","abbr":"DST","offset":-12,"isdst":false,"text":"(UTC-12:00) International Date Line West","utc":"Etc/GMT+12"},
      {"value":"UTC-11","abbr":"U","offset":-11,"isdst":false,"text":"(UTC-11:00) Coordinated Universal Time-11","utc":"Etc/GMT+11"},
      {"value":"Hawaiian Standard Time","abbr":"HST","offset":-10,"isdst":false,"text":"(UTC-10:00) Hawaii","utc":"Etc/GMT+10"},
      {"value":"Alaskan Standard Time","abbr":"AKDT","offset":-8,"isdst":true,"text":"(UTC-09:00) Alaska","utc":"America/Anchorage"},
      {"value":"Pacific Standard Time","abbr":"PST","offset":-8,"isdst":false,"text":"(UTC-08:00) Pacific Time (US & Canada)","utc":"America/Los_Angeles"},
      {"value":"US Mountain Standard Time","abbr":"UMST","offset":-7,"isdst":false,"text":"(UTC-07:00) Arizona","utc":"America/Creston"},
      {"value":"Mountain Standard Time (Mexico)","abbr":"MDT","offset":-6,"isdst":true,"text":"(UTC-07:00) Chihuahua, La Paz, Mazatlan","utc":"America/Chihuahua"},
      {"value":"Mountain Standard Time","abbr":"MDT","offset":-6,"isdst":true,"text":"(UTC-07:00) Mountain Time (US & Canada)","utc":"America/Boise"},
      {"value":"Central America Standard Time","abbr":"CAST","offset":-6,"isdst":false,"text":"(UTC-06:00) Central America","utc":"America/Belize"},
      {"value":"Central Standard Time","abbr":"CDT","offset":-5,"isdst":true,"text":"(UTC-06:00) Central Time (US & Canada)","utc":"America/Chicago"},
      {"value":"Central Standard Time (Mexico)","abbr":"CDT","offset":-5,"isdst":true,"text":"(UTC-06:00) Guadalajara, Mexico City, Monterrey","utc":"America/Bahia_Banderas"},
      {"value":"Canada Central Standard Time","abbr":"CCST","offset":-6,"isdst":false,"text":"(UTC-06:00) Saskatchewan","utc":"America/Regina"},
      {"value":"SA Pacific Standard Time","abbr":"SPST","offset":-5,"isdst":false,"text":"(UTC-05:00) Bogota, Lima, Quito","utc":"America/Bogota"},
      {"value":"Eastern Standard Time","abbr":"EDT","offset":-4,"isdst":true,"text":"(UTC-05:00) Eastern Time (US & Canada)","utc":"America/Detroit"},
      {"value":"US Eastern Standard Time","abbr":"UEDT","offset":-4,"isdst":true,"text":"(UTC-05:00) Indiana (East)","utc":"America/Indiana/Marengo"},
      {"value":"Venezuela Standard Time","abbr":"VST","offset":-4.5,"isdst":false,"text":"(UTC-04:30) Caracas","utc":"America/Caracas"},
      {"value":"Paraguay Standard Time","abbr":"PYT","offset":-4,"isdst":false,"text":"(UTC-04:00) Asuncion","utc":"America/Asuncion"},
      {"value":"Atlantic Standard Time","abbr":"ADT","offset":-3,"isdst":true,"text":"(UTC-04:00) Atlantic Time (Canada)","utc":"America/Glace_Bay"},
      {"value":"Central Brazilian Standard Time","abbr":"CBST","offset":-4,"isdst":false,"text":"(UTC-04:00) Cuiaba","utc":"America/Campo_Grande"},
      {"value":"SA Western Standard Time","abbr":"SWST","offset":-4,"isdst":false,"text":"(UTC-04:00) Georgetown, La Paz, Manaus, San Juan","utc":"America/Anguilla"},
      {"value":"Pacific SA Standard Time","abbr":"PSST","offset":-4,"isdst":false,"text":"(UTC-04:00) Santiago","utc":"America/Santiago"},
      {"value":"Newfoundland Standard Time","abbr":"NDT","offset":-2.5,"isdst":true,"text":"(UTC-03:30) Newfoundland","utc":"America/St_Johns"},
      {"value":"E. South America Standard Time","abbr":"ESAST","offset":-3,"isdst":false,"text":"(UTC-03:00) Brasilia","utc":"America/Sao_Paulo"},
      {"value":"Argentina Standard Time","abbr":"AST","offset":-3,"isdst":false,"text":"(UTC-03:00) Buenos Aires","utc":"America/Argentina/La_Rioja"},
      {"value":"SA Eastern Standard Time","abbr":"SEST","offset":-3,"isdst":false,"text":"(UTC-03:00) Cayenne, Fortaleza","utc":"America/Araguaina"},
      {"value":"Greenland Standard Time","abbr":"GDT","offset":-3,"isdst":true,"text":"(UTC-03:00) Greenland","utc":"America/Godthab"},
      {"value":"Montevideo Standard Time","abbr":"MST","offset":-3,"isdst":false,"text":"(UTC-03:00) Montevideo","utc":"America/Montevideo"},
      {"value":"Bahia Standard Time","abbr":"BST","offset":-3,"isdst":false,"text":"(UTC-03:00) Salvador","utc":"America/Bahia"},
      {"value":"UTC-02","abbr":"U","offset":-2,"isdst":false,"text":"(UTC-02:00) Coordinated Universal Time-02","utc":"America/Noronha"},
      {"value":"Mid-Atlantic Standard Time","abbr":"MDT","offset":-1,"isdst":true,"text":"(UTC-02:00) Mid-Atlantic - Old", "utc":"test"},
      {"value":"Azores Standard Time","abbr":"ADT","offset":0,"isdst":true,"text":"(UTC-01:00) Azores","utc":"America/Scoresbysund"},
      {"value":"Cape Verde Standard Time","abbr":"CVST","offset":-1,"isdst":false,"text":"(UTC-01:00) Cape Verde Is.","utc":"Atlantic/Cape_Verde"},
      {"value":"Morocco Standard Time","abbr":"MDT","offset":1,"isdst":true,"text":"(UTC) Casablanca","utc":"Africa/Casablanca"},
      {"value":"UTC","abbr":"UTC","offset":0,"isdst":false,"text":"(UTC) Coordinated Universal Time","utc":"America/Danmarkshavn"},
      {"value":"GMT Standard Time","abbr":"GMT","offset":0,"isdst":false,"text":"(UTC) Edinburgh, London","utc":"Europe/Isle_of_Man"},
      {"value":"British Summer Time","abbr":"BST","offset":1,"isdst":true,"text":"(UTC+01:00) Edinburgh, London","utc":"Europe/Isle_of_Man"},
      {"value":"GMT Standard Time","abbr":"GDT","offset":1,"isdst":true,"text":"(UTC) Dublin, Lisbon","utc":"Atlantic/Canary"},
      {"value":"Greenwich Standard Time","abbr":"GST","offset":0,"isdst":false,"text":"(UTC) Monrovia, Reykjavik","utc":"Africa/Abidjan"},
      {"value":"W. Europe Standard Time","abbr":"WEDT","offset":2,"isdst":true,"text":"(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna","utc":"Arctic/Longyearbyen"},
      {"value":"Central Europe Standard Time","abbr":"CEDT","offset":2,"isdst":true,"text":"(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague","utc":"Europe/Belgrade"},
      {"value":"Romance Standard Time","abbr":"RDT","offset":2,"isdst":true,"text":"(UTC+01:00) Brussels, Copenhagen, Madrid, Paris","utc":"Africa/Ceuta"},
      {"value":"Central European Standard Time","abbr":"CEDT","offset":2,"isdst":true,"text":"(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb","utc":"Europe/Sarajevo"},
      {"value":"W. Central Africa Standard Time","abbr":"WCAST","offset":1,"isdst":false,"text":"(UTC+01:00) West Central Africa","utc":"Africa/Algiers"},
      {"value":"Namibia Standard Time","abbr":"NST","offset":1,"isdst":false,"text":"(UTC+01:00) Windhoek","utc":"Africa/Windhoek"},
      {"value":"GTB Standard Time","abbr":"GDT","offset":3,"isdst":true,"text":"(UTC+02:00) Athens, Bucharest","utc":"Asia/Nicosia"},
      {"value":"Middle East Standard Time","abbr":"MEDT","offset":3,"isdst":true,"text":"(UTC+02:00) Beirut","utc":"Asia/Beirut"},
      {"value":"Egypt Standard Time","abbr":"EST","offset":2,"isdst":false,"text":"(UTC+02:00) Cairo","utc":"Africa/Cairo"},
      {"value":"Syria Standard Time","abbr":"SDT","offset":3,"isdst":true,"text":"(UTC+02:00) Damascus","utc":"Asia/Damascus"},
      {"value":"E. Europe Standard Time","abbr":"EEDT","offset":3,"isdst":true,"text":"(UTC+02:00) E. Europe","utc":"Asia/Nicosia"},
      {"value":"South Africa Standard Time","abbr":"SAST","offset":2,"isdst":false,"text":"(UTC+02:00) Harare, Pretoria","utc":"Africa/Blantyre"},
      {"value":"FLE Standard Time","abbr":"FDT","offset":3,"isdst":true,"text":"(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius","utc":"Europe/Helsinki"},
      {"value":"Turkey Standard Time","abbr":"TDT","offset":3,"isdst":false,"text":"(UTC+03:00) Istanbul","utc":"Europe/Istanbul"},
      {"value":"Israel Standard Time","abbr":"JDT","offset":3,"isdst":true,"text":"(UTC+02:00) Jerusalem","utc":"Asia/Jerusalem"},
      {"value":"Libya Standard Time","abbr":"LST","offset":2,"isdst":false,"text":"(UTC+02:00) Tripoli","utc":"Africa/Tripoli"},
      {"value":"Jordan Standard Time","abbr":"JST","offset":3,"isdst":false,"text":"(UTC+03:00) Amman","utc":"Asia/Amman"},
      {"value":"Arabic Standard Time","abbr":"AST","offset":3,"isdst":false,"text":"(UTC+03:00) Baghdad","utc":"Asia/Baghdad"},
      {"value":"Kaliningrad Standard Time","abbr":"KST","offset":3,"isdst":false,"text":"(UTC+02:00) Kaliningrad","utc":"Europe/Kaliningrad"},
      {"value":"Arab Standard Time","abbr":"AST","offset":3,"isdst":false,"text":"(UTC+03:00) Kuwait, Riyadh","utc":"Asia/Aden"},
      {"value":"E. Africa Standard Time","abbr":"EAST","offset":3,"isdst":false,"text":"(UTC+03:00) Nairobi","utc":"Africa/Addis_Ababa"},
      {"value":"Moscow Standard Time","abbr":"MSK","offset":3,"isdst":false,"text":"(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk","utc":"Europe/Kirov"},
      {"value":"Samara Time","abbr":"SAMT","offset":4,"isdst":false,"text":"(UTC+04:00) Samara, Ulyanovsk, Saratov","utc":"Europe/Astrakhan"},
      {"value":"Iran Standard Time","abbr":"IDT","offset":4.5,"isdst":true,"text":"(UTC+03:30) Tehran","utc":"Asia/Tehran"},
      {"value":"Arabian Standard Time","abbr":"AST","offset":4,"isdst":false,"text":"(UTC+04:00) Abu Dhabi, Muscat","utc":"Asia/Dubai"},
      {"value":"Azerbaijan Standard Time","abbr":"ADT","offset":5,"isdst":true,"text":"(UTC+04:00) Baku","utc":"Asia/Baku"},
      {"value":"Mauritius Standard Time","abbr":"MST","offset":4,"isdst":false,"text":"(UTC+04:00) Port Louis","utc":"Indian/Mahe"},
      {"value":"Georgian Standard Time","abbr":"GET","offset":4,"isdst":false,"text":"(UTC+04:00) Tbilisi","utc":"Asia/Tbilisi"},
      {"value":"Caucasus Standard Time","abbr":"CST","offset":4,"isdst":false,"text":"(UTC+04:00) Yerevan","utc":"Asia/Yerevan"},
      {"value":"Afghanistan Standard Time","abbr":"AST","offset":4.5,"isdst":false,"text":"(UTC+04:30) Kabul","utc":"Asia/Kabul"},
      {"value":"West Asia Standard Time","abbr":"WAST","offset":5,"isdst":false,"text":"(UTC+05:00) Ashgabat, Tashkent","utc":"Antarctica/Mawson"},
      {"value":"Yekaterinburg Time","abbr":"YEKT","offset":5,"isdst":false,"text":"(UTC+05:00) Yekaterinburg","utc":"Asia/Yekaterinburg"},
      {"value":"Pakistan Standard Time","abbr":"PKT","offset":5,"isdst":false,"text":"(UTC+05:00) Islamabad, Karachi","utc":"Asia/Karachi"},
      {"value":"India Standard Time","abbr":"IST","offset":5.5,"isdst":false,"text":"(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi","utc":"Asia/Kolkata"},
      {"value":"Sri Lanka Standard Time","abbr":"SLST","offset":5.5,"isdst":false,"text":"(UTC+05:30) Sri Jayawardenepura","utc":"Asia/Colombo"},
      {"value":"Nepal Standard Time","abbr":"NST","offset":5.75,"isdst":false,"text":"(UTC+05:45) Kathmandu","utc":"Asia/Kathmandu"},
      {"value":"Central Asia Standard Time","abbr":"CAST","offset":6,"isdst":false,"text":"(UTC+06:00) Nur-Sultan (Astana)","utc":"Antarctica/Vostok"},
      {"value":"Bangladesh Standard Time","abbr":"BST","offset":6,"isdst":false,"text":"(UTC+06:00) Dhaka","utc":"Asia/Dhaka"},
      {"value":"Myanmar Standard Time","abbr":"MST","offset":6.5,"isdst":false,"text":"(UTC+06:30) Yangon (Rangoon)","utc":"Asia/Rangoon"},
      {"value":"SE Asia Standard Time","abbr":"SAST","offset":7,"isdst":false,"text":"(UTC+07:00) Bangkok, Hanoi, Jakarta","utc":"Antarctica/Davis"},
      {"value":"N. Central Asia Standard Time","abbr":"NCAST","offset":7,"isdst":false,"text":"(UTC+07:00) Novosibirsk","utc":"Asia/Novokuznetsk"},
      {"value":"China Standard Time","abbr":"CST","offset":8,"isdst":false,"text":"(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi","utc":"Asia/Hong_Kong"},
      {"value":"North Asia Standard Time","abbr":"NAST","offset":8,"isdst":false,"text":"(UTC+08:00) Krasnoyarsk","utc":"Asia/Krasnoyarsk"},
      {"value":"Singapore Standard Time","abbr":"MPST","offset":8,"isdst":false,"text":"(UTC+08:00) Kuala Lumpur, Singapore","utc":"Asia/Brunei"},
      {"value":"W. Australia Standard Time","abbr":"WAST","offset":8,"isdst":false,"text":"(UTC+08:00) Perth","utc":"Antarctica/Casey"},
      {"value":"Taipei Standard Time","abbr":"TST","offset":8,"isdst":false,"text":"(UTC+08:00) Taipei","utc":"Asia/Taipei"},
      {"value":"Ulaanbaatar Standard Time","abbr":"UST","offset":8,"isdst":false,"text":"(UTC+08:00) Ulaanbaatar","utc":"Asia/Choibalsan"},
      {"value":"North Asia East Standard Time","abbr":"NAEST","offset":8,"isdst":false,"text":"(UTC+08:00) Irkutsk","utc":"Asia/Irkutsk"},
      {"value":"Japan Standard Time","abbr":"JST","offset":9,"isdst":false,"text":"(UTC+09:00) Osaka, Sapporo, Tokyo","utc":"Asia/Dili"},
      {"value":"Korea Standard Time","abbr":"KST","offset":9,"isdst":false,"text":"(UTC+09:00) Seoul","utc":"Asia/Pyongyang"},
      {"value":"Cen. Australia Standard Time","abbr":"CAST","offset":9.5,"isdst":false,"text":"(UTC+09:30) Adelaide","utc":"Australia/Adelaide"},
      {"value":"AUS Central Standard Time","abbr":"ACST","offset":9.5,"isdst":false,"text":"(UTC+09:30) Darwin","utc":"Australia/Darwin"},
      {"value":"E. Australia Standard Time","abbr":"EAST","offset":10,"isdst":false,"text":"(UTC+10:00) Brisbane","utc":"Australia/Brisbane"},
      {"value":"AUS Eastern Standard Time","abbr":"AEST","offset":10,"isdst":false,"text":"(UTC+10:00) Canberra, Melbourne, Sydney","utc":"Australia/Melbourne"},
      {"value":"West Pacific Standard Time","abbr":"WPST","offset":10,"isdst":false,"text":"(UTC+10:00) Guam, Port Moresby","utc":"Antarctica/DumontDUrville"},
      {"value":"Tasmania Standard Time","abbr":"TST","offset":10,"isdst":false,"text":"(UTC+10:00) Hobart","utc":"Australia/Currie"},
      {"value":"Yakutsk Standard Time","abbr":"YST","offset":9,"isdst":false,"text":"(UTC+09:00) Yakutsk","utc":"Asia/Chita"},
      {"value":"Central Pacific Standard Time","abbr":"CPST","offset":11,"isdst":false,"text":"(UTC+11:00) Solomon Is., New Caledonia","utc":"Antarctica/Macquarie"},
      {"value":"Vladivostok Standard Time","abbr":"VST","offset":11,"isdst":false,"text":"(UTC+11:00) Vladivostok","utc":"Asia/Sakhalin"},
      {"value":"New Zealand Standard Time","abbr":"NZST","offset":12,"isdst":false,"text":"(UTC+12:00) Auckland, Wellington","utc":"Antarctica/McMurdo"},
      {"value":"UTC+12","abbr":"U","offset":12,"isdst":false,"text":"(UTC+12:00) Coordinated Universal Time+12","utc":"Etc/GMT-12"},
      {"value":"Fiji Standard Time","abbr":"FST","offset":12,"isdst":false,"text":"(UTC+12:00) Fiji","utc":"Pacific/Fiji"},
      {"value":"Magadan Standard Time","abbr":"MST","offset":12,"isdst":false,"text":"(UTC+12:00) Magadan","utc":"Asia/Anadyr"},
      {"value":"Kamchatka Standard Time","abbr":"KDT","offset":13,"isdst":true,"text":"(UTC+12:00) Petropavlovsk-Kamchatsky - Old","utc":"Asia/Kamchatka"},
      {"value":"Tonga Standard Time","abbr":"TST","offset":13,"isdst":false,"text":"(UTC+13:00) Nuku'alofa","utc":"Etc/GMT-13"},
      {"value":"Samoa Standard Time","abbr":"SST","offset":13,"isdst":false,"text":"(UTC+13:00) Samoa","utc":"Pacific/Apia"}
    ]
  )
}
