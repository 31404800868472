import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

class SpeakerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalDescription: {description:""},
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(speaker) {
    this.setState({
      modalOpen: true,
      modalSpeaker: speaker,

    });
  }

  closeModal() {
    this.setState({
      modalOpen: false,
      modalSpeaker: null,

    });
  }

  getSpeakers() {
    const { speakers } =this.props;

    let orderedSpeakers = speakers.filter(x=>x.data && (x.data.show_on_speaker_page == "true" || x.data.show_on_speaker_page==true)).map(x=>{
      if(x.sort_order == 0){
        x.sort_order == 10000
      }
      return x
    }).sort((a,b)=>a.sort_order - b.sort_order)
    return orderedSpeakers;
  }

  getSpeakerClickProps(speaker) {
    const { click_id } = this.props;
    return {
      "data-track-id": click_id ? click_id : "dashboard-click",
      "data-track-info":`Speaker: ${speaker.name_first} ${speaker.name_last}`,
    }
  }

  renderSpeakers() {
    const speakers = this.getSpeakers();
    const renderedSpeakers = [];
    speakers.forEach(speaker => {
      renderedSpeakers.push(
        <div {...this.getSpeakerClickProps(speaker)} className="col-xs-12 col-sm-6 sg-landing-page-speaker-card-col">
          <div {...this.getSpeakerClickProps(speaker)} className=" sg-landing-page-speaker-card" onClick={(e) => this.openModal(speaker)}>
            <div {...this.getSpeakerClickProps(speaker)} className="sg-landing-page-speaker-image">
              <img {...this.getSpeakerClickProps(speaker)} src={speaker.photo_url} />
            </div>
            <div {...this.getSpeakerClickProps(speaker)} className="sg-landing-page-speaker-content">
              <div {...this.getSpeakerClickProps(speaker)} data-track-id="dashboard-click" data-track-info={`speaker ${speaker.name}`} className="sg-landing-page-speaker-name" onClick={(e) => this.openModal(speaker)}>
                {speaker.name_first} {speaker.name_last}
              </div>
              <div {...this.getSpeakerClickProps(speaker)} className="sg-landing-page-speaker-title">
                {speaker.job_title}
              </div>
            </div>
          </div>
        </div>
      );
    });
    return renderedSpeakers;
  }

  renderModal() {
    const speaker = (this.state.modalSpeaker||{})
    console.log('render modal');
    return (
      <div className="speaker-modal-inner">
        <div className="sg-speaker-modal-header">
          <div className="sg-landing-page-speaker-header-image">
            <img src={speaker.photo_url} />
          </div>
          <div className="sg-landing-page-speaker-header-details">
            <div className="sg-landing-page-speaker-header-details-inner">
              <div className="sg-landing-page-speaker-header-name">
                {speaker.name_first} {speaker.name_last}
              </div>
              <div className="sg-landing-page-speaker-header-title">
                {speaker.job_title}
              </div>
            </div>
          </div>
        </div>
        <div className="sg-speaker-modal-divider"/>
        <div className="speaker-modal-inner-description" dangerouslySetInnerHTML={{__html: (speaker.bio)}} />
      </div>
    );
  }

  render() {
    const { modalOpen } = this.state;
    return (
      <>
        <div className="sg-landing-page-speakers sg-landing-page-speakers-v2">
          {this.renderSpeakers()}
        </div>

        <Modal
          className="sg-channel-survey-modal white-background disable-scrollbars"
          overlayClassName="sg-channel-survey-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.closeModal}
          contentLabel=""
        >
          <div className="speaker-modal-close-button" onClick={this.closeModal}>
            <div className="speaker-modal-close-button-inner">
              &#10005;
            </div>
          </div>
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default SpeakerModal;
