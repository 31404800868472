import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
import SaveTheDateForm from "./forms/SaveTheDateForm"
class SaveTheDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      page: 0,
      attendee: null,
      emailValue: "",
      success: false,
    }
    this.attemptEmailCheck = this.attemptEmailCheck.bind(this);
    this.onAttendeeRSVP = this.onAttendeeRSVP.bind(this);
  }


  attemptEmailCheck(e) {
    e.preventDefault()
    const { emailValue } = this.state;
    fetchAPI(`/save_the_date/email_verification`,(json)=>{
      if(json && json.error == null){
        this.setState({
          page: 1,
          attendee: json.attendee,
        });
      }else if(json && json.error) {
        this.setState({
          error: json.error
        })
      }else {
        this.setState({
          error: "An error occurred. Please try again in a moment."
        })
      }
    },{
      method: 'POST',
      body: {
        email: emailValue||""
      }
    })
  }

  getAttendeeAssistantEmail() {
    const { attendee } = this.state;
    return (attendee||{registration_answers:{}}).registration_answers.assistantemail;
  }

  renderConfirmation(){
    const { attendee } = this.state;
    return (
      <div className="sg-rsvp-copy-subtitle">
         <div className="sg-rsvp-copy-title"> 
         Thank you for registering to join Cisco at the Olympic Games Paris 2024.
          <br/>
          <br/>

         </div>
         We are delighted you will join us and look forward to seeing you in Paris. We have sent a confirmation email to {(attendee||{}).email}.
        <br/>
        <br/>
        If you have any questions, please contact us.
        <br/>
        <br/>
        <div className="text-left">
          <a target="_blank" href="mailto:p24cxoevent@cisco.com">
            <button type="button" style={{paddingTop: "10px"}} className="sg-button sg-submit-button">
              Contact Us
            </button>
          </a>
        </div>
      </div>
    )
  }

  renderEmailForm(){
    const { emailValue, error } = this.state;
    return (
      <form src="#" onSubmit={this.declineEmail}>
        <div>
          <div className="row" >
            <div className="col-xs-12 sg-rsvp-copy-title">
            We look forward to welcoming you to the Olympic&nbsp;Games&nbsp;Paris&nbsp;2024.
            <br/>
            <br/>
            </div>
            <div className="col-xs-12 sg-rsvp-copy-subtitle">
            As this is an invitation-only event, please ensure the email entered below matches the email on your official invitation.
            <br/>
            </div>
          </div>
        </div>
        <div>
          <br/>
          <label className="sg-rsvp-copy-field-label">Enter Email Address*</label>
          <input
            name="email"
            value={emailValue}
            onChange={(e) => this.setState({
              emailValue: e.target.value
            })}
            className="dark-form-control "
          />
        </div>
        {error ? (
          <b className="att-proam-registration-email-validation-error">
            <br/>
            {error}
          </b>
        ):""}
        <div className="att-proam-registration-email-validation-submit">
          <br/>
          <br/>
          <button
            type="button"
            onClick={this.attemptEmailCheck}
            className="sg-button sg-submit-button"
          >
            Continue
          </button>
        </div>
      </form>
    );
  }

  renderForm() {
    const { attendee } = this.state;
    return (
      <SaveTheDateForm
        submitCallback={this.onAttendeeRSVP}
        attendee={attendee}
      />
    )
  }

  onAttendeeRSVP(json) {
    this.setState({
      page: 2
    })
  }


  render() {
    switch(this.state.page) {
      case 0:
        return this.renderEmailForm()
      case 1:
        return this.renderForm()
      case 2:
        return this.renderConfirmation();
    }
  }
}

export default SaveTheDate;
