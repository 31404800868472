import Cookies from "universal-cookie";

export function submitRegistration(values, state, component) {
  const cookies = new Cookies();
  const token = cookies.get("X-CSRF-Token");
  var form_data = new FormData();
  component.setState({
    loading: true,
    savedSuccessfully: false
  })

  const new_values = JSON.parse(JSON.stringify(values));
  const isProfile = values.user.update_profile

  //Set The Three Values
  fetch(isProfile ? '/registration/update_profile' : `/registration`, {
    method: `POST`,
    redirect: "manual",
    body: JSON.stringify(new_values),
    headers: {
      "X-CSRF-Token": token,
      "Content-Type": 'application/json'
    }
  }).then(response => {
    try {
      return response.json();
    }
    catch(e) {
      component.setState({
        loading: false,
        error: "An error occurred. Please try again later."
      })
    }
  })
  .then(json => {
    if (json.error == null) {

      if(isProfile) {
        component.setState({
          fullPageError: null,
          loading: false,
          savedSuccessfully: true
        })
        setTimeout(()=> {
          console.log("Callback")
          component.setState({
            loading: false,
            savedSuccessfully: false
          })
        }, 4000)
      }else {
        window.scrollTo(0,0);
        component.setState({
          page: 4,
          loading: false,
        })
      }


    } else {
      component.setState({
        fullPageError: json.error,
        loading: false,
      })
    }
  });
}


export function isGcabMember(attendee) {
  return (attendee||{})['registration_answers']['gcabmember'] == "True";
}