import React from "react";
import   { formatToTimeZone } from 'date-fns-timezone';
import { addMinutes } from 'date-fns'
class KbygPersonalAgendaSection extends React.Component {

  
  isGcabMember() {
    const { attendee } = this.props;
    return attendee['registration_answers']['gcabmember'] == "True";
  }

  renderGroundTransportation() {
    const { attendee } = this.props;
    const {
      requiretransportfromhotel,
      requiretransportfromairport,
      arrivaldate,
      arrivalairline,
      arrivalflightnumber,
      arrivalorigin,
      departuredate,
      arrivaltime,
      departuretime,
      departureairline,
      departureflightnumber
    } = attendee['registration_answers']

    if((requiretransportfromhotel && requiretransportfromhotel == 'True' )||(requiretransportfromairport && requiretransportfromairport == 'True')) {
      return (
        <div className="sg-kbyg-overview-section-block">
          <div className="sg-kbyg-large-copy font-bold">
            Ground Transportation
          </div>
          <div className="sg-kbyg-copy font-light">
            Cisco will provide complimentary ground transportation upon arrival to and departure from Palm Springs International Airport (PSP).
            <br/>
            <br/>
            You have provided the following flight information:
      
            {(requiretransportfromairport == "True")? (
                <>
                  <br/>
                  <br/>
                  Arrival Date: {arrivaldate}
                  <br/>
                  Flight Arrival Time: {arrivaltime}
                  <br/>
                  Flight Origin: {arrivalorigin}
                  <br/>
                  Airline Carrier / FBO Name: {arrivalairline}
                  <br/>
                  Flight Number / Tail Number: {arrivalflightnumber}
                  <br/>
                  <br/>
                  <b>
                    You will be met by a transportation coordinator inside the terminal holding a Cisco placard. The coordinator will escort you to baggage claim to collect luggage, as needed.
                    <br/>
                    <br/>
                    If you do not connect with the coordinator, please call our representative at +1619.843.6015.
                  </b>
                </>
            ):""}
            {(requiretransportfromhotel == "True")? (
                <>
                  <br/>
                  <br/>
                  Departure Date: {departuredate}
                  <br/>
                  Flight Departure Time: {departuretime}
                  <br/>
                  Airline Carrier / FBO Name: {departureairline}
                  <br/>
                  Flight Number / Tail Number: {departureflightnumber}
                  <br/>
                  <br/>
                  <b>Exact time to depart the Ritz-Carlton will be provided onsite if Cisco ground transportation has been requested.</b>
                </>
            ):""}

 
          </div>
        </div>  
      )
    }else {
      return (
        <div className="sg-kbyg-overview-section-block">
          <div className="sg-kbyg-large-copy font-bold">
            Ground Transportation
          </div>

          <div className="sg-kbyg-copy font-light">
            You elected not to receive complimentary ground transportation to/from the hotel. If you changed your plans, please contact us. We’d be happy to assist you.
            <br/>
            <br/>
            Additionally, there are Taxi service or Uber and Lyft rideshare transportation options from Palm Spring International Airport that take approximately 30 minutes, depending on traffic conditions.
          </div>
        </div>
      )
    }

  }
  renderHotelAccomodations() {
    const { attendee, features } = this.props;
    const { gcaphotelarrangesdoesnotpay, hotelcheckindate, hotelcheckoutdate, hotelspecialrequests, ciscosecuresreservation, dietarypreferenceother} = attendee['registration_answers']

    const gcabMember = this.isGcabMember()
    return (
      <>
        <div className="sg-kbyg-overview-section-block">
          <div className="sg-kbyg-copy font-light">
            Please see the Event Concierge for final details and information regarding your onsite experience.
          </div>
        </div>  
        {ciscosecuresreservation == "True" || (gcabMember && gcaphotelarrangesdoesnotpay == "True") ?
          (
          <div className="sg-kbyg-overview-section-block">
            <div className="sg-kbyg-large-copy font-bold">
              Hotel Accommodations
            </div>
            <div className="sg-kbyg-copy font-light">
              Your hotel reservation details:
              <br/>
              <br/>
              Check In Date: {hotelcheckindate}
              <br/>
              Check Out Date: {hotelcheckoutdate}
              {hotelspecialrequests ? (
                <>
                  <br/>
                  Special Requests: {hotelspecialrequests}
                </>
              ):""}
            </div>
          </div> 
          )
          :
          ""
        }
      </>
    )
  }

  renderGroundTransportation() {
    const { attendee } = this.props;
    const {
      requiretransportfromhotel,
      requiretransportfromairport,
      arrivaldate,
      arrivalairline,
      arrivalflightnumber,
      arrivalorigin,
      departuredate,
      arrivaltime,
      departuretime,
      departureairline,
      departureflightnumber
    } = attendee['registration_answers']

    if((requiretransportfromhotel && requiretransportfromhotel == 'True' )||(requiretransportfromairport && requiretransportfromairport == 'True')) {
      return (
        <div className="sg-kbyg-overview-section-block">
          <div className="sg-kbyg-large-copy font-bold">
            Ground Transportation
          </div>
          <div className="sg-kbyg-copy font-light">
            Cisco will provide complimentary ground transportation upon arrival to and departure from Palm Springs International Airport (PSP).
            <br/>
            <br/>
            You have provided the following flight information:
      
            {(requiretransportfromairport == "True")? (
                <>
                  <br/>
                  <br/>
                  Arrival Date: {arrivaldate}
                  <br/>
                  Flight Arrival Time: {arrivaltime}
                  <br/>
                  Flight Origin: {arrivalorigin}
                  <br/>
                  Airline Carrier / FBO Name: {arrivalairline}
                  <br/>
                  Flight Number / Tail Number: {arrivalflightnumber}
                  <br/>
                  <br/>
                  <b>
                    You will be met by a transportation coordinator inside the terminal holding a Cisco placard. The coordinator will escort you to baggage claim to collect luggage, as needed.
                    <br/>
                    <br/>
                    If you do not connect with the coordinator, please call our representative at +1619.843.6015.
                  </b>
                </>
            ):""}
            {(requiretransportfromhotel == "True")? (
                <>
                  <br/>
                  <br/>
                  Departure Date: {departuredate}
                  <br/>
                  Flight Departure Time: {departuretime}
                  <br/>
                  Airline Carrier / FBO Name: {departureairline}
                  <br/>
                  Flight Number / Tail Number: {departureflightnumber}
                  <br/>
                  <br/>
                  <b>Exact time to depart the Ritz-Carlton will be provided onsite if Cisco ground transportation has been requested.</b>
                </>
            ):""}

 
          </div>
        </div>  
      )
    }

  }

  renderSchedule() {
    const dates = this.getDates();
    return dates.map(date=>this.renderDate(date))
  }

  getActivities() {
    const { sessions, attendee } = this.props;
    const activities = sessions.filter(x=>
      x.session_type_name == 'Activity'
    )
    return activities
  }

  registrationAnswerToSlug(fields,id, answer) {
    try {
      const question = fields[id]
      return question.options.find(x=> x.label == answer).slug
    }catch(e) {
      console.log(e);
      return null
    }
  }
  
  getFilteredSessions() {
    const { sessions, attendee } = this.props;
    let filteredSessions = sessions
    if(attendee) {
      filteredSessions = filteredSessions.filter(x=>x.session_type_name=="Session" || x.session_type_name=="Meal" ||
      (
        x.session_type_name == "Activity" && (x.session_tags||[]).indexOf("Global Activity") > -1
      )
      ).concat(this.getUserActivities())
    }
    filteredSessions = filteredSessions.sort((a,b)=>{
      const dateA = new Date(a.date_and_time)
      const dateB = new Date(b.date_and_time)
      return dateB < dateA ? 1 : -1
    })
    return filteredSessions
  }

  getUserActivities() {
    const { attendee, fields } = this.props;
    if(attendee){
      const activities = this.getActivities();
      const chosenActivity = attendee['registration_answers']['selectedactivity']
      console.log({chosenActivity})
      const chosenActivitySlug =this.registrationAnswerToSlug(fields, 'selectedactivity',chosenActivity);
      console.log({chosenActivitySlug})
      return activities.filter(activity=>{
        return (activity.session_tags||[]).filter(tag =>
          [chosenActivitySlug].map(x=>`activity-${x}`).indexOf(tag) != -1
        ).length > 0
      })
    }else {
      return []
    }
  }

  getDates() {
    return this.getFilteredSessions().filter(x=>x.date_and_time).map(x=>this.getSessionDate(x)).sort().filter((x,i,t)=>t.indexOf(x) == i)
  }

  renderDate(date) {
    const sessions = this.getFilteredSessions()
    const currentDateSessions = sessions.filter(session=> this.getSessionDate(session) == date)
    return (
      <div className={`sg-landing-page-session-agenda-date-container sg-landing-page-kbyg-session-agenda`}>
        <div className="sg-landing-page-session-agenda-date-container-header open">
          <div className="sg-landing-page-session-agenda-date-container-header-title">
            {formatToTimeZone(new Date(date), "dddd, MMMM DD", { timeZone: 'UTC' })}
          </div>
        </div>
        <div className="sg-landing-page-session-agenda-date-container-body">
          {currentDateSessions.map(x=>this.renderSession(x))}
        </div>
      </div>
    )
  }


  getSessionDate(session) {
    if(session.date_and_time) {
      return formatToTimeZone(new Date(session.date_and_time), "YYYY-MM-DD", { timeZone: 'America/Los_Angeles' })
    }
  }

  renderSession(session) {
    return (
      <div className="sg-landing-page-session-agenda-session-container">
        <div className="sg-landing-page-session-agenda-session-date">
          {this.renderDateAndTime(session)}
        </div>
        <div className={`sg-landing-page-session-agenda-session-title`}>
          {session.title}
        </div>
        {(session.speakers||[]).length > 0 ? (
          <div className="sg-landing-page-session-agenda-session-speakers">
            {session.speakers.map(x=>this.renderSpeaker(x))}
          </div>
        ):""}
        <div className="sg-landing-page-session-agenda-session-session-abstract" dangerouslySetInnerHTML={{__html:session.description||""}}>

        </div>
      </div>
    )
  }

  renderSpeaker(speaker) {
    return (
      <>
        <b>{speaker.name_first} {speaker.name_last}</b>, {speaker.job_title}
        <br/>
      </>
    )
  }

  renderDateAndTime(session) {
    if(session.date_and_time) {
      const start_time = new Date(session.date_and_time_utc)
      const end_time = addMinutes(start_time, session.length_minutes||0)
      if(start_time != end_time && session.length_minutes && session.length_minutes > 0 ) {
        return (
          `${formatToTimeZone(new Date(start_time), "hh:mm aa", { timeZone: 'America/Los_Angeles' })} - ${formatToTimeZone(new Date(end_time), "hh:mm aa", { timeZone: 'America/Los_Angeles' })}`
        )
      }else {
        return `${formatToTimeZone(new Date(start_time), "hh:mm aa", { timeZone: 'America/Los_Angeles' })}`
      }
    }
  }


  render() {
    return (
      <>
      
        {this.renderHotelAccomodations()}
        {this.renderGroundTransportation()}
        {this.renderSchedule()}
      </>
    )
  }
}

export default KbygPersonalAgendaSection;
