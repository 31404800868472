import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { subHours, format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';

class LandingSessionAgenda extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  getActivities() {
    const { sessions, attendee } = this.props;
    const activities = sessions.filter(x=>
      x.session_type_name == 'Activity'
    )
    return activities
  }

  getUserActivities() {
    const { attendee, fields } = this.props;
    const activities = this.getActivities();
    const chosenActivity = attendee['registration_answers']['selectedactivity']
    const chosenActivitySlug =this.registrationAnswerToSlug(fields, 'selectedactivity',chosenActivity);
    console.log({chosenActivitySlug})
    return activities.filter(activity=>{
      return (activity.session_tags||[]).filter(tag =>
        [chosenActivitySlug].map(x=>`activity-${x}`).indexOf(tag) != -1
      ).length > 0
    })
  }


  registrationAnswerToSlug(fields,id, answer) {
    try {
      const question = fields[id]
      return question.options.find(x=> x.label == answer).slug
    }catch(e) {
      console.log(e);
      return null
    }
  }

  renderActivity(activity) {
    return (
      <div className="col-xs-12">
        <div className="sg-landing-page-activity-container">
          <div className="sg-landing-page-activity-image-container">
            <img src={activity.thumbnail_url}/>
          </div>
          <div className="sg-landing-page-activity-copy-container">
            <div className="sg-landing-page-activity-title">
              {activity.title}
            </div>
            <div className="sg-landing-page-activity-time">
              {this.renderDateAndTime(activity)}
            </div>
            <div className="sg-landing-page-activity-description" dangerouslySetInnerHTML={{__html: activity.description}}/>
          </div>
        </div>
      </div>
    )
  }


  renderActivity(activity) {
    return (
      <div className="col-xs-12">

        <div className="sg-landing-page-activity-container">
          <div className="sg-landing-page-activity-image-container">
            <img src={activity.thumbnail_url}/>
          </div>
          <div className="sg-landing-page-activity-copy-container">
            <div className="sg-landing-page-activity-title">
              {activity.title}
            </div>
            <div className="sg-landing-page-activity-time">
              {this.renderDateAndTime(activity)}
            </div>
            <div className="sg-landing-page-activity-description" dangerouslySetInnerHTML={{__html: activity.description}}/>
          </div>
        </div>
      </div>
    )
  }

  renderDateAndTime(activity) {
    if(activity.date_and_time) {
      const start_time = new Date(activity.date_and_time_utc)
      const end_time = addMinutes(start_time, activity.length_minutes||0)
      if(start_time != end_time && activity.length_minutes && activity.length_minutes > 0 ) {
        return (
          `${formatToTimeZone(new Date(start_time), "MMM DD | hh:mm", { timeZone: 'America/Los_Angeles' })} - ${formatToTimeZone(new Date(end_time), "HH:mm aa", { timeZone: 'America/Los_Angeles' })}`
        )
      }else {
        return `${formatToTimeZone(new Date(start_time), "MMM DD | hh:mm aa", { timeZone: 'America/Los_Angeles' })}`
      }
    }
  }

  render() {
    const { modalOpen } = this.state;
    return this.getUserActivities().map(activity=>this.renderActivity(activity))
  }
}

export default LandingSessionAgenda;
