import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { LineChart } from "react-chartkick";
class RegisteredOverTimeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    }
    this.toggleModal = this.toggleModal.bind(this);
  }


  columnChartDataSet() {
    return {
        borderColor:"rgba(31, 189, 235, 0.5)",
        backgroundColor: "rgba(31, 189, 235, 0.5)",
        lineTension: 0,
        fill: true,
      }
  }


  getOverTime() {
    const { participants } = this.props;
    const startDate = new Date("01-14-2024");
    const currentDate = new Date();
    let iDate = startDate; //Iterator Date
    const dateObject = {}
    while(iDate < currentDate) {
      iDate.setDate(iDate.getDate() + 1);
      dateObject[startDate] = participants.filter(x=> new Date(x.registered_at) <= iDate).length
    }
    return dateObject
  }



  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen
    })
  }

  renderModalInner() {
    return (
      <div className="sg-admin-panel-modal-inner">
        <h1 className="sg-platform-report-graph-title"> Registrants over time</h1>
        <LineChart dataset={this.columnChartDataSet()}  data={this.getOverTime()} />
      </div>
    )
  }

  render() {
    const { modalOpen } = this.state;
    const { children } = this.props;
    return (
      <>
        <span onClick={this.toggleModal}>
          {children}
        </span>

        <Modal
          className="sg-admin-modal white-background disable-scrollbars"
          overlayClassName="sg-channel-survey-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          <div className="speaker-modal-close-button" onClick={this.toggleModal}>
            <div className="speaker-modal-close-button-inner">
              &#10005;
            </div>
          </div>
          {this.renderModalInner()}
        </Modal>
      </>
    );
  }
}

export default RegisteredOverTimeModal;
